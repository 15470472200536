import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryError, handleQueryErrorAndSuccess} from "../../helpers/utils/RtkQueryUtils";

export const attestationApi = strApi.injectEndpoints({

    endpoints: (build) => ({

        uploadAttestation: build.mutation({
            query: ({contactId, formData, productId}) => {
                return {
                    url: `/fincen-file/${contactId}/productId/${productId}`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: formData
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Uploaded", "File");
            },
        }),

        findLatestAttestationByContactId: build.query({
            query: (contactId) => {
                return {
                    url: `/fincen-attestations/contactId/${contactId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled);
            },
        }),

    })
})

export const {
    useUploadAttestationMutation,
    useLazyFindLatestAttestationByContactIdQuery
} = attestationApi