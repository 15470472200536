import React, {ChangeEvent, FC, useState} from "react";
import {CreateOrderTabProps} from "../OrderDetailsTab";
import ButtonTray from "../../../../components/common/form/ButtonTray";
import {createOptionListForSelectTag, isNullOrEmpty} from "../../../../helpers/utils/Utils";
import SelectField from "../../../../components/common/form/SelectField";
import InputField from "../../../../components/common/form/InputField";
import {useGetAllStatesByCountryIdQuery, useGetAllStatesQuery} from "../../../data/states/statesApi";
import {useGetAllCountyByStateIdQuery} from "../../../data/counties/countiesApi";
import {skipToken} from "@reduxjs/toolkit/query";
import FincenPartyForm from "./FincenPartyForm";
import FincenPartyListSection from "./FincenPartyListSection";


const FincenDetailTab: FC<CreateOrderTabProps> = ({
                                                      createOrderData,
                                                      setCreateOrderData,
                                                      setTab
                                                  }) => {

    const [unSavedPersonInfo, setUnsavedPersonInfo] = useState(false);
    const allStatesApiResult = useGetAllStatesByCountryIdQuery("236b617cc-105e-4f73-a91c-e2162beac864");

    const {
        data: countyByStateId,
        isLoading: countyByStateIdLoading,
        isFetching: countyByStateIdFetching
    } = useGetAllCountyByStateIdQuery(createOrderData?.state?.value ?? skipToken);

    const isNextButtonEnabled = () => {
        return !(!createOrderData?.state ||
            !createOrderData?.countyId ||
            !createOrderData?.situsAddress ||
            !createOrderData.parties ||
            createOrderData.parties.length === 0 ||
            unSavedPersonInfo);
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: any }) => {
        const {name, value} = "target" in e ? e.target : e;

        setCreateOrderData({
            ...createOrderData,
            [name]: value,
            // If state is de-selected it should empty the countyName field too
            ...(name === "state" ? {"countyId": null} : {})
        })
    }

    return (
        <>
            <div className="col-span-12 mt-4 p-4 rounded-sm border border-surface-6 relative">
                <label className="absolute bg-surface-2 -top-3.5 pr-1 text-text-1">Package Info</label>

                <SelectField
                    label="State"
                    value={createOrderData?.state}
                    placeholder="Select State..."
                    options={createOptionListForSelectTag(allStatesApiResult?.data, "name", "id")}
                    onChange={(selectedOption) => {
                        handleOnChange({name: "state", value: selectedOption})
                    }}
                    required={true}
                />

                <SelectField
                    label="County"
                    value={createOrderData?.countyId}
                    placeholder="Select County..."
                    options={createOptionListForSelectTag(countyByStateId, "name", "id")}
                    onChange={(selectedOption) => {
                        handleOnChange({name: "countyId", value: selectedOption})
                    }}
                    required={true}
                    isLoading={isNullOrEmpty(createOrderData?.state) || countyByStateIdLoading || countyByStateIdFetching}
                />

                <InputField label='Situs Address'
                            value={createOrderData?.situsAddress}
                            name={"situsAddress"}
                            onChange={handleOnChange}
                />

                <InputField label='Legal Description'
                            name={"legalDescription"}
                            value={createOrderData?.legalDescription}
                            onChange={handleOnChange}
                />

                <InputField
                    label="Estimated Closing Date"
                    value={createOrderData?.expectedClosingDate}
                    type="date"
                    onChange={handleOnChange}
                    name={"expectedClosingDate"}
                />

                <InputField label='Estimated Sale Price'
                            value={createOrderData?.expectedSalesPrice}
                            type='number'
                            name={"expectedSalesPrice"}
                            onChange={handleOnChange}
                />

            </div>

            <FincenPartyForm title={"Buyer"}
                             submitBtnText={"Add"}
                             setUnsavedPersonInfo={setUnsavedPersonInfo}
                             setCreateOrderData={setCreateOrderData}
            />

            <FincenPartyListSection type={"Buyer"} setCreateOrderData={setCreateOrderData}
                                    createOrderData={createOrderData} setUnsavedPersonInfo={setUnsavedPersonInfo}/>

            <FincenPartyForm title={"Seller"}
                             submitBtnText={"Add"}
                             setUnsavedPersonInfo={setUnsavedPersonInfo}
                             setCreateOrderData={setCreateOrderData}
            />

            <FincenPartyListSection type={"Seller"} setCreateOrderData={setCreateOrderData}
                                    createOrderData={createOrderData} setUnsavedPersonInfo={setUnsavedPersonInfo}/>

            <ButtonTray
                buttons={[
                    {
                        buttonProps: {
                            btnText: "Previous",
                            onClick: () => setTab(1),
                            type: "close",
                        },
                        buttonType: "button"
                    },
                    {
                        buttonProps: {
                            btnText: "Next",
                            type: (isNextButtonEnabled()) ? "submit" : "button",
                            onClick: () => {
                                setTab(3);
                            },
                            isDisabled: (!isNextButtonEnabled()),
                            title: (!isNextButtonEnabled()) ? "Unsaved Changes or Required fields missing" : ""
                        },
                        buttonType: "button",

                    }
                ]}
                align={"end"}
                gap={2}
            />

        </>
    )
}

export default FincenDetailTab;
