import {useNavigate, useParams} from "react-router-dom";
import {skipToken} from "@reduxjs/toolkit/query";
import {
    useCreatePlatformPricingMutation,
    useGetPlatformPricingByIdQuery,
    useUpdatePlatformPricingMutation
} from "./platformPricingApi";
import Breadcrumb, {Crumb} from "../../../components/common/Breadcrumbs";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import Form from "../../../components/common/form/Form";
import TextAreaField from "../../../components/common/form/TextAreaField";
import {jwtDecode} from "../../../helpers/utils/AccessControlUtils";
import SelectField from "../../../components/common/form/SelectField";
import {createOptionListForSelectTag, isNullOrEmpty} from "../../../helpers/utils/Utils";
import {useGetAllTenantsQuery} from "../../user/customer/tenantApi";
import {useGetEnumsQuery} from "../../../app/globalApi";
import InputField from "../../../components/common/form/InputField";
import {useGetAllStatesByCountryIdQuery, useGetAllStatesQuery} from "../../data/states/statesApi";
import {useGetAllCountyByStateAbbrQuery} from "../../data/counties/countiesApi";
import {useSearchMetroAreasQuery} from "../../data/metroAreas/metroAreasApi";
import {useSearchCustomersQuery} from "../../user/customer/customerApi";
import {useGetBranchesForCustomerQuery} from "../../user/customer/branchApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {Routes as AppRoutes} from "../../../routes";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface props {
    crumbs: Crumb[],
    title: string,
    btnText: string,
    isEditable?: boolean
}

const PlatformPricingform: FC<props> = ({crumbs, title, btnText, isEditable = true}) => {

    const navigate = useNavigate();
    let {platFormId} = useParams();

    const [createPlatform, createPlatformApiResponse] = useCreatePlatformPricingMutation();
    const [updatePlatform, updatePlatformApiResponse] = useUpdatePlatformPricingMutation();
    const platformByIdApi = useGetPlatformPricingByIdQuery(platFormId ?? skipToken);
    const [pricingData, setPricingData] = useState<any>({});
    const tenantApiResponse = useGetAllTenantsQuery("");
    const stateApiResponse = useGetAllStatesByCountryIdQuery("236b617cc-105e-4f73-a91c-e2162beac864");;
    const {data: enums} = useGetEnumsQuery("");
    const {
        data: countyByStateAbbr,
        isLoading: countyByStateAbbrLoading,
        isFetching: countyByStateAbbrFetching
    } = useGetAllCountyByStateAbbrQuery(
        stateApiResponse?.data?.find((el: any) => el.id === pricingData?.state?.value)?.abbreviation ?? skipToken
    );

    const {
        data: metroAreaByStateAbbr,
        isLoading: metroAreaByStateAbbrLoading,
        isFetching: metroAreaByStateAbbrFetching
    } = useSearchMetroAreasQuery({
        pageSize: 10000,
        stateName: stateApiResponse?.data?.find((el: any) => el.id === pricingData?.state?.value)?.name
    }, {
        skip: isNullOrEmpty(pricingData?.state?.value) || !stateApiResponse?.data // Skip if no stateId or statesApiResult data is available
    });

    const {
        data: customerByTenant
    } = useSearchCustomersQuery({
        pageSize: 10000,
        tenantId: pricingData?.tenantId?.value,
        customerLevel: "CUSTOMER"
    }, {
        skip: jwtDecode()?.customerLevel === "SYS" ? isNullOrEmpty(pricingData?.tenantId?.value) : isNullOrEmpty(jwtDecode()?.tenantId) // Skip if no stateId or statesApiResult data is available
    });

    const branchesForCustomerApi = useGetBranchesForCustomerQuery({
        pageSize: 100,
        customerId: pricingData?.customerId?.value
    }, {skip: isNullOrEmpty(pricingData?.customerId)});

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | {
        name: string;
        value: any
    } | ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = "target" in e ? e.target : e;

        setPricingData((prevData: any) => {
            let updatedData = {...prevData, [name]: value};

            // Clear county and metroArea if state is cleared
            if (name === "state") {
                updatedData = {...updatedData, county: null, metroArea: null};
            }

            // Clear customerId and branchId if tenantId is cleared
            if (name === "tenantId") {
                updatedData = {...updatedData, customerId: null, branchId: null};
            }

            // Clear branchId if tenantId is cleared
            if (name === "customerId") {
                updatedData = {...updatedData, branchId: null};
            }

            return updatedData;
        });
    };

    useEffect(() => {
        let obj = platformByIdApi?.data;
        if (obj) {
            setPricingData(
                {
                    ...obj,
                    state: obj?.state ? {
                        value: obj?.state?.id,
                        label: obj?.state?.name
                    } : null,
                    county: obj?.county ? {
                        value: obj?.county?.id,
                        label: obj?.county?.name
                    } : null,
                    metroArea: obj?.metroArea ? {
                        value: obj?.metroArea?.id,
                        label: obj?.metroArea?.name
                    } : null,
                    tenantId: (obj?.tenant && jwtDecode()?.customerLevel === "SYS") ? {
                        value: obj?.tenant?.id,
                        label: obj?.tenant?.name,
                    } : jwtDecode()?.tenantId,
                    productType: obj?.productType ? {
                        value: obj?.productType,
                        label: getEnumDisplayNameByConstant(enums?.["ProductType"], obj?.productType)
                    } : null,
                    customerId: obj?.customer ? {
                        value: obj?.customer?.id,
                        label: obj?.customer?.customerName
                    } : null,
                    branchId: obj?.branch ? {
                        value: obj?.branch?.id,
                        label: obj?.branch?.name
                    } : null
                }
            )
        }

    }, [platformByIdApi?.data]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const commonObj = {
            ...pricingData,
            customerId: pricingData?.customerId?.value,
            branchId: pricingData?.branchId?.value,
            productType: pricingData?.productType?.value,
            state: pricingData?.state?.value ? stateApiResponse?.data.filter((el: any) => el.id === pricingData?.state?.value)[0] : null,
            county: pricingData?.county?.value ? countyByStateAbbr?.filter((el: any) => el.id === pricingData?.county?.value)[0] : null,
            metroArea: pricingData?.metroArea?.value ? metroAreaByStateAbbr.filter((el: any) => el.id === pricingData?.metroArea?.value)[0] : null,
            tenantId: jwtDecode()?.customerLevel === "SYS" ? pricingData?.tenantId?.value : jwtDecode()?.tenantId
        };

        const createOrUpdateUser = async () => {
            if (btnText === "Create") {
                await createPlatform(commonObj).unwrap();
            } else {
                await updatePlatform({
                    id: platFormId,
                    requestBody: commonObj,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateUser, () => {
            setPricingData({});
            navigate(AppRoutes.PlatformPricing.path);
        });
    }

    return (
        <>
            <div className="px-4 py-2">

                <div className={"justify-between sm:block md:flex lg:flex"}>
                    <Breadcrumb crumbs={crumbs}/>
                    <AuditableInfoButton obj={platformByIdApi?.data}/>
                </div>

                <div className="flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">{title}</h3>
                        <Form onSubmit={handleSubmit} fields={<>
                            <div className="grid grid-cols-12">
                                <TextAreaField
                                    name="description"
                                    label="Description"
                                    onChange={handleOnChange}
                                    value={pricingData.description || ""}
                                    placeholder="Enter Description..."
                                    rows={2}
                                />

                                <SelectField
                                    placeholder='Select Product Type...'
                                    value={pricingData?.productType || null}
                                    options={createOptionListForSelectTag(enums?.["ProductType"], "displayName", "constant")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "productType", value: selectedOption})
                                    }}
                                    label={"Product Type"}
                                    required={true}
                                />


                                {jwtDecode()?.customerLevel === "SYS" && <SelectField
                                    placeholder='Select Tenant...'
                                    value={pricingData?.tenantId || null}
                                    options={createOptionListForSelectTag(tenantApiResponse?.data, "name", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "tenantId", value: selectedOption})
                                    }}
                                    isLoading={tenantApiResponse.isLoading}
                                    label={"Tenant"}
                                />}

                                <SelectField
                                    placeholder='Select Customer...'
                                    value={pricingData?.customerId || null}
                                    options={createOptionListForSelectTag(customerByTenant?.data, "customerName", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "customerId", value: selectedOption})
                                    }}
                                    isDisabled={!pricingData?.tenantId}
                                    label={"Customer"}
                                />

                                <SelectField
                                    placeholder='Select Branch...'
                                    options={createOptionListForSelectTag(branchesForCustomerApi?.data?.data, "name", "id","","displayLabel")}
                                    value={pricingData?.branchId || null}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "branchId", value: selectedOption})
                                    }}
                                    label={"Branch"}
                                    isDisabled={!pricingData?.customerId}
                                />

                                <SelectField
                                    placeholder='Select State...'
                                    value={pricingData?.state || null}
                                    options={createOptionListForSelectTag(stateApiResponse?.data, "name", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "state", value: selectedOption})
                                    }}
                                    isLoading={stateApiResponse.isLoading}
                                    label={"State"}
                                />

                                <SelectField
                                    label="County"
                                    value={pricingData?.county}
                                    placeholder="Select County..."
                                    options={createOptionListForSelectTag(countyByStateAbbr, "name", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "county", value: selectedOption})
                                    }}
                                    isLoading={countyByStateAbbrLoading || countyByStateAbbrFetching}
                                    isDisabled={pricingData?.metroArea || !pricingData?.state}
                                />

                                <SelectField
                                    label="Metro Area"
                                    value={pricingData?.metroArea}
                                    placeholder="Select Metro Area..."
                                    options={createOptionListForSelectTag(metroAreaByStateAbbr, "name", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "metroArea", value: selectedOption})
                                    }}
                                    isLoading={metroAreaByStateAbbrLoading || metroAreaByStateAbbrFetching}
                                    isDisabled={pricingData?.county || stateApiResponse.isFetching || stateApiResponse.isLoading || !pricingData?.state}
                                />

                                <InputField
                                    name={"displayPrice"}
                                    label="Display Price"
                                    onChange={handleOnChange}
                                    value={pricingData?.displayPrice || ""}
                                    type="number"
                                    placeholder="Enter Display Price..."
                                    required={true}
                                />

                                <InputField
                                    name={"displayPricePerParcel"}
                                    label="Display Parcel"
                                    onChange={handleOnChange}
                                    value={pricingData?.displayPricePerParcel || ""}
                                    type="number"
                                    placeholder="Enter Display Parcel..."
                                    required={true}
                                />

                                <InputField
                                    name={"price"}
                                    label="Price"
                                    onChange={handleOnChange}
                                    value={pricingData?.price || ""}
                                    type="number"
                                    placeholder="Enter Price..."
                                    required={true}
                                />

                                <InputField
                                    name={"pricePerParcel"}
                                    label="Parcel"
                                    onChange={handleOnChange}
                                    value={pricingData?.pricePerParcel || ""}
                                    type="number"
                                    placeholder="Enter Parcel..."
                                    required={true}
                                />

                            </div>

                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Cancel",
                                            onClick: () => navigate(AppRoutes.PlatformPricing.path),
                                            type: "close"
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Submit",
                                            type: "submit",
                                            isLoading: (createPlatformApiResponse.isLoading || updatePlatformApiResponse.isLoading),
                                            isVisible: isEditable
                                        },
                                        buttonType: "button"
                                    }
                                ]}
                                align="end"
                                gap={2}
                            />
                        </>}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PlatformPricingform;