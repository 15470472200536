import React from 'react';
import Select from 'react-select';
import {selectStyle} from "../../../helpers/utils/StyleUtils";

interface Option {
    value: any;
    label: string;
}

interface SelectFieldProps {
    label: string;
    required?: boolean;
    value?: Option | null | any;
    options: Option[];
    onChange: (selectedOption: Option | null) => void;
    placeholder?: string;
    className?: string;
    isLoading?: boolean;
    labelPosition?: 'left' | 'right' | 'top';
    isMulti?: boolean;
    isDisabled?:boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({
                                                     label,
                                                     required = false,
                                                     value,
                                                     options,
                                                     onChange,
                                                     placeholder = 'Select...',
                                                     className = 'Select...',
                                                     isLoading = false,
                                                     labelPosition = "",
                                                     isMulti = false,
                                                     isDisabled = false,
                                                 }) => {

    const renderLabel = () => (
        <div className="text-text-1">{required ? `*${label}` : label}</div>
    );

    return (
        <>
            {labelPosition !== "top" ?
                <div className="col-span-12 md:grid md:grid-cols-12 gap-3">
                    <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                        {renderLabel()}
                    </div>
                    <div className="col-span-9 mt-3">
                        <Select
                            onChange={onChange}
                            value={value}
                            isClearable={true}
                            options={options}
                            styles={selectStyle}
                            placeholder={placeholder}
                            isDisabled={isLoading || isDisabled}
                            required={required}
                            isMulti={isMulti}
                            className={className}
                        />
                    </div>
                </div>
                :
                <div className="col-span-4 mt-2">
                    {renderLabel()}
                    <Select
                        isDisabled={isLoading || isDisabled}
                        styles={selectStyle}
                        onChange={onChange}
                        value={value}
                        isClearable={true}
                        options={options}
                        placeholder={placeholder}
                        required={required}
                        isMulti={isMulti}
                    />
                </div>
            }
        </>
    );
};

export default SelectField;
