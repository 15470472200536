// //data table
import {
    useSortBy,
    useTable,
} from "react-table"
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import "./DataTable.css"
import {useLocation, useNavigate} from "react-router-dom";
import moment from "moment";
import {
    bindDependents,
    getHeaderByName,
    getFinalizedColumns,
    getUnformattedPhoneNumber,
    getValueOrDefault,
    objectToQueryParams, shouldHideFromUser, applyDefaultsToObject, getDataTablePropsDefault, queryParamsToObject
} from "./DataTableUtils";
import SkeletonLoader from "../SkeletonLoader";
import Pagination from "../pagination/Pagination";
import UserSettingsModal from "../userSetting/UserSettingsModal";
import {useFindUserSettingsByGroupNameQuery} from "../userSetting/userSettingsApi";
import {
    computePageControl,
} from "../userSetting/UserSettingUtils";
import {LazyQueryTrigger} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {FaCog} from "react-icons/fa";
import {
    DataItem,
    DataPage,
    DataTableColumn,
    DataTableProps,
    FilterOptions,
    getDefaultColumnVisibility
} from "./DataTableTypes";
import Button from "../form/Button";
import ModalButton from "../form/ModalButton";
import IconButton from "../form/IconButton";
import {FaMagnifyingGlass} from "react-icons/fa6";
import {hasPermission, jwtDecode} from "../../../helpers/utils/AccessControlUtils";

const updateFilterQuery = (filterKey: string, value: any, filterQuery: any, setFilterQuery: Function) => {
    const isCheckbox = value?.target?.type == 'checkbox';
    const isDate = value?.target?.type == undefined && (filterKey === 'createdDateTo' || filterKey === 'createdDateFrom' || filterKey === 'paidDateFrom' || filterKey === 'paidDateTo' || filterKey === 'eventDateFrom' || filterKey === 'eventDateTo' || filterKey === 'deliveredDateFrom' || filterKey === 'deliveredDateTo' || filterKey === 'creationDateFrom' || filterKey === 'creationDateTo' || filterKey === 'lastCheckedDateFrom' || filterKey === 'lastCheckedDateTo' || filterKey === 'productDeliveredDateFrom' || filterKey === 'productDeliveredDateTo' || filterKey === 'transmitDateFrom' || filterKey === 'transmitDateTo');
    const isPhoneNo = value?.target?.placeholder === 'Enter Phone...';
    const date = moment(value).format("yyyy-MM-DD HH:mm:ss").toUpperCase()

    value =
        // Checkbox
        (isCheckbox ? value !== undefined && value?.target?.checked : null) ??
        // Date
        (isDate ? date : null) ??
        //unformatted Phone
        (isPhoneNo ? getUnformattedPhoneNumber(value?.target?.value) : null) ??
        // Anything else that has a native onChange
        value?.target?.value ??
        // Raw values sent over
        value;

    let tempQuery: any = {...filterQuery};
    if (value === undefined || value === '' || (Array.isArray(value) && !value.length)) {
        delete tempQuery[filterKey];
    } else {
        if (value == "INVALID DATE") {
            delete tempQuery[filterKey];
        } else {
            tempQuery[filterKey] = value;
        }
    }

    setFilterQuery(tempQuery);
};

export const DataTable: React.FC<DataTableProps> = (props: DataTableProps) => {
    const defaultPageSize = useMemo(() => 15, []);

    const location: any = useLocation();
    const navigate: any = useNavigate();

    props = applyDefaultsToObject(props, getDataTablePropsDefault);
    let appliedFilter = queryParamsToObject(location.search);

    const defaultSort = {
        isSorted: !!props.defaultSortOptions,
        sortColumn: props.defaultSortOptions?.accessor,
        sortDescending: props.defaultSortOptions?.direction === 'desc'
    };

    const searchParams = new URLSearchParams(location.search);

    const pageNum: number = parseInt(getValueOrDefault(searchParams.get('pageNumber'), '1'));

    const [defaultFilter, setDefaultFilter] = useState(() => {
        return Object.keys(appliedFilter).length > 0 ? appliedFilter : props.defaultFilter || {};
    });
    // This is the query we are building via the filter components. This should always match the current state of the filter components.
    const [pendingQuery, setPendingQuery] = useState(defaultFilter);

    // This is the query used to run the fetchAction.
    // It should only be set as a result of applyFilter being true, and it will trigger the fetchActionQuery to run.
    const [filterQuery, setFilterQuery] = useState(pendingQuery);

    // The query that fetches the data. Runs every time the filterQuery changes.
    const fetchActionQuery = props.actions.fetchAction(filterQuery || skipToken);

    const userSettingQuery = useFindUserSettingsByGroupNameQuery(hasPermission(["UPRO-R"]) && (props.userSettingGroup || skipToken));

    const customerLevel: string = jwtDecode()?.customerLevel;

    let page: DataPage = getValueOrDefault(fetchActionQuery?.data, null);
    let data: DataItem[] = getValueOrDefault(page?.data || fetchActionQuery?.data, []);

    const displayText = getValueOrDefault(page?.displayingText, 'No data to display');
    const paginationDisplayText = getValueOrDefault(page?.displayingText, 'items');

    const [sort, setSort] = useState(defaultSort);

    const [pageNumber, setPageNumber] = useState(pageNum ? pageNum - 1 : 0);
    const [pageSize, setPageSize] = useState(defaultPageSize);

    const [pageCount, setPageCount] = useState(getValueOrDefault(page?.totalPages, 1));

    const refFilterQuery = useRef(pendingQuery);

    const [applyFilter, setApplyFilter] = useState(false);
    const [applyExport, setApplyExport] = useState(false);
    const [applyReset, setApplyReset] = useState(false);

    const [filterFormExpanded, setFilterFormExpanded] = useState(!!props.defaultFilterFormExpanded);
    const [filterFormKey, setFilterFormKey] = useState(1);

    useEffect(() => {
        refFilterQuery.current = pendingQuery;
    }, [pendingQuery]);

    useEffect(() => {
        setPageCount(getValueOrDefault(page?.totalPages, 1))
    }, [fetchActionQuery?.data]);

    const applyDefaultValues = () => {

        resetFilterComponents();

        let obj = {
            // @ts-ignore
            sort: defaultFilter?.sort ? defaultFilter?.sort : "creationDate",
            // @ts-ignore
            sortOrder: defaultFilter?.sortOrder ? defaultFilter?.sortOrder : "asc"
        }
        setPendingQuery(obj);
        setSort(defaultSort);

        setApplyFilter(true);
    }

    const resetFilterComponents = () => {
        for (const column of props.columns) {
            for (let filterOptions of column.filterOptions ?? []) {

                setFilterFormKey(filterFormKey + 1);

                if (filterOptions?.onDependencyUpdated && filterOptions.filterComponent.props.options) {
                    filterOptions.filterComponent = React.cloneElement(filterOptions.filterComponent, {
                        ...filterOptions.filterComponent.props,
                        options: [],
                    });
                }
            }
        }
    };


    const fetchPageData = () => {


        const sortOrder = sort.sortDescending ? "DESCENDING" : "ASCENDING";

        let query = {...pendingQuery} as any;

        if (pageSize && pageSize !== defaultPageSize) {
            query.pageSize = pageSize;
        }

        if (sort.sortColumn) {
            query.sort = sort.sortColumn;

            if (sortOrder) {
                query.sortOrder = sortOrder;
            }
        }

        if (pageNumber !== 0) {
            query.pageNumber = pageNumber;
        }

        setFilterQuery(query);

        if ((props.appendFilterToUrl === undefined) || props.appendFilterToUrl) {
            navigate({
                pathname: location.pathname,
                search: objectToQueryParams(query),
                state: {detail: objectToQueryParams(query)}
            });
        }
    };

    const exportData = (exportAction: LazyQueryTrigger<any>) => {

        let sortOrder = sort.sortDescending ? "ASCENDING" : "DESCENDING";

        let query = {
            ...filterQuery,
            pageNumber: pageNumber,
            pageSize: 10000,
            sort: sort.sortColumn,
            sortOrder: sortOrder,
        }

        exportAction(query);
    }

    useEffect(() => {
        fetchPageData();
    }, [pageNumber, pageSize, sort]);

    useEffect(() => {
        if (!applyFilter) {
            return;
        }

        fetchPageData();

        setApplyFilter(false);
    }, [applyFilter]);

    useEffect(() => {
        if (!applyExport) {
            return;
        }

        if (!props.actions?.exportAction) {
            return;
        }

        setFilterQuery(pendingQuery);
        exportData(props.actions.exportAction);

        setApplyExport(false);
    }, [applyExport]);

    const isLoading = fetchActionQuery?.isLoading;

    const initializeDefaultValues = (column: DataTableColumn) => {
        if (!column.filterOptions) {
            column.filterOptions = [];
        }

        if (!column.visibility) {
            column.visibility = getDefaultColumnVisibility();
        }


        if (column.canSort === undefined) {
            column.canSort = true;
        }
    };

    for (let column of props.columns) {
        initializeDefaultValues(column);
    }

    let tableColumns = useMemo(() => isLoading ? props.columns.map((column) =>
                ({...column, Cell: <SkeletonLoader count={1}/>})) :
            getFinalizedColumns(props.columns, userSettingQuery?.data),
        [isLoading, props.columns]
    );

    const tableData = useMemo(
        () => (isLoading ?
                Array<any>(!pageSize || pageSize === 0 ? defaultPageSize : pageSize).fill({}) :
                data
        ),
        [isLoading, data]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
            columns: tableColumns || [],
            data: tableData || [],
            manualSortBy: true,
            disableMultiSort: true,
            defaultCanSort: true,
            autoResetSortBy: false,
            manualPagination: true,
            pageCount: pageCount,
        } as any,
        useSortBy,
        // usePagination, // TODO: Might need to re-enable and re-work some of this to enable multi-page row selection. I don't anticipate needing this.
        // useRowSelect
    );

    const handleSort = useCallback(
        (column: any) => {
            if (!column.canSort) {
                return;
            }

            if (column.isSorted && !column.isSortedDesc) {
                column.clearSortBy();
            } else {
                column.toggleSortBy(!column.isSortedDesc, false);
            }

            setSort({
                sortColumn: column.overrideSortColumn ? column.overrideSortColumn : column.id,
                isSorted: column.isSorted,
                sortDescending: column.isSortedDesc
            });
        }, []);

    useEffect(() => {
        if (!applyReset) {
            return;
        }

        applyDefaultValues();
        setApplyReset(false);
    }, [applyReset]);


    const applySettingsToHeaders = (column: DataTableColumn) => {
        let header = getHeaderByName(headerGroups, column.label);

        if (!header?.isVisible || !column.visibility) {
            return;
        }

        if (!column?.canSort) {
            header.canSort = column.canSort;
        }

        if (shouldHideFromUser(column?.visibility?.disallowedCustomerLevels, customerLevel)) {
            header.toggleHidden(true);
            return;
        }


        if (!column?.visibility?.canColumnShow && column.visibility?.canColumnShow !== undefined) {
            header.toggleHidden(true);
            return;
        }

        const setting = userSettingQuery?.data?.find((item: any) => item.key === column.label);

        // If we have a setting that says to hide it, hide it.
        // If we have no setting, and it's hidden by default, hide it.
        const hide = (!setting && !column.visibility?.showByDefault && column.visibility?.canColumnShow !== undefined) || (setting && setting.value === 'hide');
        if (hide) {
            header.toggleHidden(true);
        }
    };

    const applyFilterOptionBinding = (column: DataTableColumn) => {
        bindDependents(column, tableColumns, refFilterQuery, setPendingQuery);
    }

    for (let column of props.columns) {
        applySettingsToHeaders(column);
        applyFilterOptionBinding(column);
    }

    const tableColumnsToShow = props.columns.filter((c: DataTableColumn) => !(c &&
        (shouldHideFromUser(c.visibility?.disallowedCustomerLevels, customerLevel))));

    const filterFieldsToShow = Array.isArray(tableColumnsToShow) && tableColumnsToShow
        .filter((c: DataTableColumn) => c && c.filterOptions && Object.keys(c.filterOptions).length) || [];


    const [selectedRows, setSelectedRows] = useState(new Set());

    const handleRowClick = (row: any) => {
        const {
            enableRowSelection,
            enableMultiRowSelection,
            rowIdAccessor,
            onSelectionChange,
            rowSelectionPredicate,
            defaultSelectedIds
        } = props.rowSelectionOptions || {};

        // Check if row is selectable
        const isRowSelectable = !rowSelectionPredicate || rowSelectionPredicate(row);

        if (!enableRowSelection || !isRowSelectable) {
            return;
        }


        const newSelectedRows = new Set(selectedRows);
        const rowId = row.original[rowIdAccessor || 'id']; // Default to 'id' if rowIdAccessor is not provided

        if (enableMultiRowSelection) {
            if (newSelectedRows.has(rowId)) {
                newSelectedRows.delete(rowId);
            } else {
                newSelectedRows.add(rowId);
            }
        } else {
            newSelectedRows.clear();
            newSelectedRows.add(rowId);
        }

        setSelectedRows(newSelectedRows);

        // Call the onSelectionChange handler with all selected rows and the target row.
        if (onSelectionChange) {
            onSelectionChange(newSelectedRows, row);
        }
    };

    const getRowStyle = (row: any) => {
        const {
            enableRowSelection,
            rowSelectedStyle,
            rowUnselectableStyle,
            rowIdAccessor,
            rowSelectionPredicate,
            defaultSelectedIds
        } = props.rowSelectionOptions || {};

        if (!enableRowSelection) {
            return ''; // No specific styling if row selection is disabled
        }

        const rowId = row.original[rowIdAccessor || 'id']; // Default to 'id' if rowIdAccessor is not provided

        // Check if row is selectable
        const isRowSelectable = !rowSelectionPredicate || rowSelectionPredicate(row);

        if (!isRowSelectable) {
            return rowUnselectableStyle || 'bg-surface-2 cursor-not-allowed'; // Apply unselectable style if row is not selectable
        }

        if (defaultSelectedIds) {
            defaultSelectedIds.forEach((el: any) => {
                selectedRows.add(el);
            });
        }

        if (selectedRows.has(rowId)) {
            return rowSelectedStyle || 'bg-highlight-0 cursor-pointer'; // Apply selected style
        } else {
            return 'bg-surface-2 cursor-pointer'; // Default unselected style
        }
    };

    const handleCheckboxChange = (row: any) => {
        if (props?.rowSelectionOptions?.enableRowSelection) {
            handleRowClick(row);
        }
    };


    const debugLogging = <div className='grid grid-cols-3 w-full gap-4 pb-4'>
        <span className='col-span-1 text-text-1'>{`ApplyExport: ${JSON.stringify(applyExport, null, 4)}`}</span>
        <span className='col-span-1 text-text-1'>{`ApplyReset: ${JSON.stringify(applyReset, null, 4)}`}</span>
        <span className='col-span-1 text-text-1'>{`ApplyFilter: ${JSON.stringify(applyFilter, null, 4)}`}</span>
        <span className='col-span-1 text-text-1'>{`PendingQuery: ${JSON.stringify(pendingQuery, null, 4)}`}</span>
        <span className='col-span-1 text-text-1'>{`FilterQuery: ${JSON.stringify(filterQuery, null, 4)}`}</span>
        <span
            className='col-span-1 text-text-1'>{`refFilterQuery: ${JSON.stringify(refFilterQuery.current, null, 4)}`}</span>
        <span className='col-span-1 text-text-1'>{`Loading: ${!!isLoading}`}</span>
        <span className='col-span-1 text-text-1'>{`FilterField Count: ${filterFieldsToShow?.length}`}</span>
    </div>;

    return (
        <div className='grid'>
            {/*{debugLogging}*/}
            <>
                <div className='flex justify-between mt-2 mb-2'>
                    {(filterFieldsToShow?.length > 0 || props.buttonOptions?.extraButtons || props.buttonOptions?.addButton) &&
                        <>
                            <div/>
                            <div className='inline-flex gap-2'>

                                {props.buttonOptions?.extraButtons?.map((button, index) => (
                                    hasPermission(button.permissions || []) && (
                                        <div key={index}>
                                            {button.button}
                                        </div>
                                    )
                                ))}

                                {filterFieldsToShow?.length > 0 &&
                                    <IconButton
                                        className={"h-7"}
                                        icon={<FaMagnifyingGlass/>}
                                        hasBackground={true}
                                        onClick={() => {
                                            setFilterFormExpanded(!filterFormExpanded)
                                        }}
                                        title={"Search"}
                                    />
                                }
                                {props?.buttonOptions && hasPermission(props?.buttonOptions?.addButton?.permissions || []) && props.buttonOptions?.addButton?.button}
                            </div>
                        </>
                    }
                </div>


                {!!props.renderOptions?.skipFilterRender || !filterFormExpanded ||
                    <div className="pb-2 my-2 border-2 border-highlight-2 rounded-sm" id="grid">
                        <div className='md:grid md:grid-cols-12 gap-2 p-4' key={filterFormKey}>
                            {filterFieldsToShow.map((filterField: DataTableColumn) => filterField?.filterOptions?.length &&
                                <div
                                    className={filterFieldsToShow?.length > 2 ? 'col-span-4 justify-between flex' : 'col-span-6 justify-between flex'}>
                                    {
                                        filterField.filterOptions?.map((filterOptions: FilterOptions) => filterOptions && Object.keys(filterOptions).length &&
                                            <div className="w-11/12 sm:mt-2">
                                                <label className='text-text-1'>
                                                    {!!filterOptions?.hideLabel ? '' : (filterOptions.overrideFilterLabel || filterField.label)}
                                                </label>
                                                <div className={"mt-1"}>
                                                    {
                                                        !filterOptions?.dependentColumns ? React.cloneElement(filterOptions?.filterComponent, {
                                                            onChange: (event: any) => updateFilterQuery(filterOptions.overrideFilterAccessor || filterField.accessor,
                                                                event,
                                                                refFilterQuery.current,
                                                                setPendingQuery),
                                                            onKeyDown: (event: any) => {
                                                                if (event.key === 'Enter') {
                                                                    event.preventDefault();
                                                                    setApplyFilter(true);

                                                                    return false;
                                                                }
                                                            }
                                                        }) : React.cloneElement(filterOptions?.filterComponent, {
                                                            onKeyDown: (event: any) => {
                                                                if (event.key === 'Enter') {
                                                                    event.preventDefault();
                                                                    setApplyFilter(true);

                                                                    return false;
                                                                }
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                            }
                        </div>

                        {/*Filter Button Row*/}
                        {!!props.renderOptions?.skipButtonSectionRender ||
                            <div className='form-row inline-flex gap-4 px-4 pt-4'>
                                {/*Clear Button*/}
                                <Button isLoading={isLoading}
                                        btnText='Clear'
                                        type={'cancel'}
                                        onClick={() => setApplyReset(true)}/>

                                {/*TODO: Would be nice if all of our "submit" actions, like delete/export/apply, were in a <ButtonWithMenu/>*/}
                                {/*Export Button*/}
                                {props.actions?.exportAction && props.basePermission && hasPermission([props.basePermission + "-B", props.basePermission + "-R"], true) &&
                                    <Button isLoading={isLoading || applyExport}
                                            btnText='Export'
                                            type={'confirm'}
                                            onClick={() => setApplyExport(true)}/>
                                }

                                {/*Apply Button*/}
                                <Button isLoading={isLoading}
                                        btnText='Apply'
                                        type={'confirm'}
                                        onClick={() => setApplyFilter(true)}/>
                            </div>
                        }
                    </div>
                }
            </>

            {!!props.renderOptions?.skipTableRender ||
                <>
                    {!!props.renderOptions?.skipPaginationRender ||
                        userSettingQuery?.data && computePageControl(userSettingQuery?.data, 'TOP') &&
                        <Pagination
                            displayText={paginationDisplayText}
                            pageCount={pageCount}
                            setPageNumber={setPageNumber}
                            pageNumber={pageNumber}
                            setExternalPageSize={setPageSize}
                            groupName={props.userSettingGroup || ''}
                        />
                    }

                    {!!props.renderOptions?.skipTableRender ||
                        <div className={"table_wrapper"}>
                            <div className={"table-responsive"}>
                                <table {...getTableProps()} className={`bg-surface-2 text-sm text-text-1 w-full`}>
                                    <thead>
                                    {
                                        headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {props?.rowSelectionOptions?.enableMultiRowSelection && (
                                                    <th/>
                                                )}
                                                {
                                                    headerGroup.headers.map((header: any) => (
                                                        <th {...header.getHeaderProps(header.getSortByToggleProps())}
                                                            onClick={() => handleSort(header)}
                                                            className={`text-text-2 text-left`}>

                                                            {header.render('label')}

                                                            {/* Add a sort direction indicator */}
                                                            <span className="noselect">&ensp;
                                                                <i
                                                                    className={`text-highlight-3 cursor fas ${(header?.canSort ? header?.isSorted ? header?.isSortedDesc ? 'fa-sort-down' : 'fa-sort-up' : 'fa-sort' : '')}`}
                                                                />
                                                            </span>
                                                        </th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                    </thead>

                                    <tbody {...getTableBodyProps()}>
                                    {
                                        rows.map((row: any) => {
                                            prepareRow(row);
                                            return (
                                                <tr id={row?.id} {...row.getRowProps()} // Default to 'id' if rowIdAccessor is not provided
                                                    onClick={() => handleRowClick(row)}
                                                    className={`${getRowStyle(row)}`}>
                                                    {props?.rowSelectionOptions?.enableMultiRowSelection && (
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedRows.has(row.original[props.rowSelectionOptions?.rowIdAccessor || 'id'])}
                                                                onChange={() => handleCheckboxChange(row)}
                                                            />
                                                        </td>
                                                    )}
                                                    {
                                                        row.cells.map((cell: any) => {
                                                            return (
                                                                <td  {...cell.getCellProps()}>
                                                                    {
                                                                        isLoading ?
                                                                            <SkeletonLoader count={1}/> :
                                                                            cell.render('Cell')
                                                                    }
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }

                                    {(page?.data?.length === 0 || (fetchActionQuery.status === "fulfilled" && !page && data?.length === 0)) && (
                                        <tr>
                                            <td colSpan={16}>{displayText}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                    <div className='mt-2'>
                        {!!props.renderOptions?.skipPaginationRender ||
                            userSettingQuery.data && computePageControl(userSettingQuery.data, "BOTTOM") && page?.data?.length > 0 &&
                            <Pagination
                                displayText={paginationDisplayText}
                                pageCount={pageCount}
                                setPageNumber={setPageNumber}
                                pageNumber={pageNumber}
                                setExternalPageSize={setPageSize}
                                groupName={props.userSettingGroup || ''}
                            />
                        }

                        {!!props.renderOptions?.skipUserSettingRender ||
                            <div className='flex justify-between'>
                                <div/>
                                {hasPermission(["UPRO-R"]) && <ModalButton
                                    modal={<UserSettingsModal
                                        columns={props.columns}
                                        show={true}
                                        setShow={() => {
                                        }}
                                        groupName={props.userSettingGroup || ''}/>
                                    }
                                    triggerButton={
                                        <IconButton
                                            icon={<FaCog/>}
                                            title={props.userSettingGroup + " Settings"}
                                        />
                                    }/>}
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    )
}