import React, {FC, useEffect, useState} from "react";
import {useGetErrorsByBatchIdQuery} from "../fincenFilingErrorApi";
import {DetailRow} from "../../../helpers/utils/OrderDetailUtils";
import AuditableInfo from "../../../components/common/AuditableInfo";

interface props {
    batchId?: string,
}

interface ErrorRowProps {
    source: string,
    level: string,
    typeCode: string,
    context: string,
    element: string,
    text: string,
}

const FincenErrorContainer: FC<props> = ({batchId}) => {
    const filingErrors = useGetErrorsByBatchIdQuery(batchId,
        {skip: !batchId, pollingInterval: 10000});

    const getColor = (level: any) => {
        const base = "border-l-";

        switch (level) {
            case "FATAL":
                return `${base}error-2`;
            case "WARN":
                return `${base}warn-2`;
            case "INFO":
                return `${base}highlight-5`;
            default:
                console.log("Unexpected level of " + level);
                break;
        }
    };

    const Row: FC<ErrorRowProps> = (data) => (
        <div title={data?.level}
             className={`${getColor(data?.level)} border border-surface-3 border-l-8 border-t border-b border-r rounded-sm 
             lg:grid sm:block grid-cols-12 p-3 pb-2 mt-3`}>

            <div className="col-span-8">
                <DetailRow
                    rowClassName={'mt-0 mb-1'}
                    label={'Type Code'}
                    isLoading={filingErrors?.isLoading}
                    value={`${data?.source} - ${data?.level} ${data?.typeCode}`}
                    isValueSpan={true}
                    hideLabel={true}/>
                <DetailRow
                    rowClassName={'mt-0 text-sm'}
                    className={''}
                    label={'Element'}
                    isLoading={filingErrors?.isLoading}
                    value={`${data?.context} ${data?.element}`}
                    hideLabel={true}/>

                <div title={data?.text} className="text-text-1">
                    {data?.text}
                </div>
            </div>
            <div className="col-span-4 text-sm">
                {/*TODO: Actually get auditable info but need to fetch users*/}
                <AuditableInfo data={{creationDate: '2025-03-27T00:35:00', createdBy: 'System'}}/>
            </div>
        </div>
    );

    return (
        <>
            <div>
                {filingErrors?.data?.map((filingError: any) => {
                    return <Row source={filingError?.source}
                                level={filingError?.level}
                                typeCode={filingError?.typeCode}
                                context={filingError?.context}
                                element={filingError?.elementName}
                                text={filingError?.text}/>
                    // <div>
                    //     <DetailRow label={'Type Code'}
                    //                isLoading={filingErrors?.isLoading}
                    //                value={`${filingError?.source} - ${filingError?.level} ${filingError?.typeCode}`}
                    //                isValueSpan={true}/>
                    //     <DetailRow label={'Element'}
                    //                isLoading={filingErrors?.isLoading}
                    //                value={`${filingError?.context} ${filingError?.elementName}`}/>
                    //     <DetailRow label={'Description'}
                    //                isLoading={filingErrors?.isLoading}
                    //                value={filingError?.text}/>
                    // </div>
                })
                }
            </div>
        </>
    )
}
export default FincenErrorContainer