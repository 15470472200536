import React, {ChangeEvent, FC, useEffect, useRef, useState} from "react";
import {
    createOptionListForSelectTag,
    isNullOrEmpty
} from "../../../../helpers/utils/Utils";
import {useGetAllStatesByCountryIdQuery, useGetAllStatesQuery} from "../../../data/states/statesApi";
import {
    useGetAllCountyByStateIdQuery, useGetRecipientByCountyIdQuery,
} from "../../../data/counties/countiesApi";
import {CreateOrderTabProps} from "../OrderDetailsTab";
import SelectField from "../../../../components/common/form/SelectField";
import InputField from "../../../../components/common/form/InputField";
import RecordingListSection from "./RecordingListSection";
import PersonInfoList from "./PersonInfoList";
import {useGetEnumsQuery} from "../../../../app/globalApi";
import {PersonInfoForm} from "./PersonInfoForm";
import {
    useGetRecipientInstrumentsQuery,
    useGetRequirementsForInstrumentQuery,
} from "../../simplifileApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useDispatch} from "react-redux";
import {strApi} from "../../../../app/strApi";
import Button from "../../../../components/common/form/Button";
import {useGetAllCannedFileNamesQuery} from "../../../data/cannedFileNames/cannedFileNamesApi";
import ButtonTray from "../../../../components/common/form/ButtonTray";
import {useGetCustomerByIdQuery} from "../../../user/customer/customerApi";
import HelperInfoList from "./HelperInfoList";

const RecordingDetailTab: FC<CreateOrderTabProps> = ({createOrderData, setCreateOrderData, setTab}) => {

    const dispatch = useDispatch();
    const fileInputRef = useRef<any>(null);
    const [packageData, setPackageData] = useState<any>({grantees: [], grantors: [], helperDocuments: [], decimal: [], fileName: {value: "Document Package", label: "Document Package"}});
    const [editPackageIndexList, setEditPackageIndexList] = useState<number[]>([]);
    const [undoPackageListData, setUndoPackageListData] = useState<any[]>([]);
    const [unSavedPersonInfo, setUnsavedPersonInfo] = useState(false);
    const customerByIdQuery = useGetCustomerByIdQuery(createOrderData?.customerId?.value ?? skipToken);
    const allStatesApiResult = useGetAllStatesByCountryIdQuery("236b617cc-105e-4f73-a91c-e2162beac864");;
    const allCannedFileNamesApiResult = useGetAllCannedFileNamesQuery(customerByIdQuery?.data?.tenantId ?? skipToken);
    const [render, setRender] = useState<any>(null);
    const [grantorObj, setGrantorObj] = useState<any>({type: {"label": "Individual", "value": "INDIVIDUAL"}});
    const [granteeObj, setGranteeObj] = useState<any>({type: {"label": "Individual", "value": "INDIVIDUAL"}});

    const {data: enums} = useGetEnumsQuery("");

    const {
        data: countyByStateId,
        isLoading: countyByStateIdLoading,
        isFetching: countyByStateIdFetching
    } = useGetAllCountyByStateIdQuery(createOrderData?.state?.value ?? skipToken);


    const {
        data: recipientByCountyId
    } = useGetRecipientByCountyIdQuery(createOrderData.countyId?.value ? {
        countyId: createOrderData.countyId?.value,
        tenantId: customerByIdQuery?.data?.tenantId
    } : skipToken);

    const {
        data: recipientInstrumentsApiResult,
        isLoading: recipientInstrumentsLoading,
        isFetching: recipientInstrumentsFetching
    } = useGetRecipientInstrumentsQuery({
        recipientId: recipientByCountyId?.recipientID,
        query: {tenantId: customerByIdQuery?.data?.tenantId}
    }, {skip: !recipientByCountyId?.recipientID || !customerByIdQuery?.data?.tenantId});

    const {data: requirementsForInstrument} = useGetRequirementsForInstrumentQuery(
        {
            recipientId: recipientByCountyId?.recipientID,
            instrumentName: packageData?.documentType?.value,
            tenantId: customerByIdQuery?.data?.tenantId,
        },
        {skip: !packageData?.documentType || !recipientByCountyId?.recipientID || !customerByIdQuery?.data?.tenantId || packageData?.fileName?.value === "Document Package"}
    );

    useEffect(() => {
        if (!packageData?.documentType && recipientByCountyId?.recipientID) {
            // Invalidate the cache
            dispatch(strApi.util.invalidateTags(["requirementsForInstrument"]));
        }
    }, [packageData?.documentType, dispatch, recipientByCountyId?.recipientID]);


    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: any }) => {
        const {name, value} = "target" in e ? e.target : e;
        setCreateOrderData({
            ...createOrderData,
            [name]: value,
            // If state is de-selected it should empty the countyId field too
            ...(name === "state" ? {"countyId": null} : {})
        })
        if (name === "countyId" || name === "state") {
            setPackageData({
                ...packageData,
                "documentType": null,
                "decimal": [],
                "helperDocuments": []
            })
        }
    }

    const handlePackageChange = (e: ChangeEvent<HTMLInputElement> | {
        name: string;
        value: any,
        files?: null
    }) => {
        const {name, value, files} = "target" in e ? e.target : e;

        setPackageData({
            ...packageData,
            [name]: files ? files[0] : value,
            ...(name === "documentType" && {
                decimal: [],
                helperDocuments: [],
            })
        })
    }

    const handleAddPackageToList = () => {

        const currentPackageList = createOrderData?.packageList || []
        setCreateOrderData({
            ...createOrderData,
            packageList: [...currentPackageList, packageData]
        });
        setUndoPackageListData([...currentPackageList, packageData]);
        if (fileInputRef.current) {
            // @ts-ignore
            fileInputRef.current.value = "";
        }
        setPackageData({})
    }

    const isValidConsideration = () => {
        if (requirementsForInstrument !== undefined && requirementsForInstrument.length > 0) {
            let decimal = requirementsForInstrument.filter((el: any) => el.path === "consideration");

            if (decimal.length > 0 && decimal[0].required === "ALWAYS" && !packageData.consideration) {
                return false;
            }
        }
        return true;
    }

    const isValidFileAndDocumentType = () => {
        if (packageData?.fileName?.value !== "Document Package"){
            return ["fileName", "documentType"]?.every(cur => !isNullOrEmpty(packageData?.[cur])) && !isNullOrEmpty(fileInputRef?.current?.value) && (packageData?.fileName?.value === "Custom" ? !isNullOrEmpty(packageData?.customFileName) : true)
        }else{
            return ["fileName"]?.every(cur => !isNullOrEmpty(packageData?.[cur])) && !isNullOrEmpty(fileInputRef?.current?.value) && (packageData?.fileName?.value === "Custom" ? !isNullOrEmpty(packageData?.customFileName) : true)
        }
    }

    const isNextButtonEnabled = () => {
        // Check required fields in createOrderData
        const areMainFieldsValid = ["state", "countyId", "packageList"].every(cur => !isNullOrEmpty(createOrderData?.[cur]));

        // Check if all packages with a defined consideration have a non-empty value
        const isConsiderationValid = createOrderData?.packageList?.every((pkg: any) =>
            pkg.consideration === undefined || !isNullOrEmpty(pkg.consideration)
        );

        // Enable the button if main fields are valid, package considerations are valid, and editPackageIndexList is empty
        return areMainFieldsValid && isConsiderationValid && isNullOrEmpty(editPackageIndexList);
    };


    const isValidGrantorsAndGrantee = () => {
        if (requirementsForInstrument !== undefined && requirementsForInstrument.length > 0 && (packageData?.grantors?.length === 0 || packageData?.grantees?.length === 0)) {
            return !requirementsForInstrument.some((item: any) =>
                item.conditionals.some((conditional: any) => conditional.required === "ALWAYS") || item.required === "ALWAYS"
            );
        }
        return true;
    }

    const isValidHelperDocument = () => {
        if (packageData?.helperDocuments?.length > 0) {
            for (const el of packageData.helperDocuments) {
                if (el.required === "ALWAYS" && !el?.file) {
                    return false; // Exit immediately if condition is met
                }
            }
        }
        return true; // Return true if all conditions are met
    };

    useEffect(() => {
        if (requirementsForInstrument && requirementsForInstrument.length > 0) {
            let helper = requirementsForInstrument.filter((el: any) => el.type === "HELPER_DOCUMENT");
            let decimal = requirementsForInstrument.filter((el: any) => el.path === "consideration");

            if (helper.length > 0) {
                packageData.helperDocuments = helper;
            } else {
                packageData.helperDocuments = [];
            }

            if (decimal.length > 0) {
                packageData.decimal = decimal;
            } else {
                packageData.decimal = [];
            }
            setPackageData(packageData);
            setRender(new Date().getMilliseconds());
        }
    }, [requirementsForInstrument]);

    return (
        <>
            <div className="col-span-12 mt-4 p-4 rounded-sm border border-surface-6 relative">
                <label className="absolute bg-surface-2 -top-3.5 pr-1 text-text-1">Package Info</label>
                <SelectField
                    label="State"
                    value={createOrderData?.state}
                    placeholder="Select State..."
                    options={createOptionListForSelectTag(allStatesApiResult?.data, "name", "id")}
                    onChange={(selectedOption) => {
                        handleOnChange({name: "state", value: selectedOption})
                    }}
                    required={true}
                />

                <SelectField
                    label="County"
                    value={createOrderData?.countyId}
                    placeholder="Select County..."
                    options={createOptionListForSelectTag(countyByStateId, "name", "id")}
                    onChange={(selectedOption) => {
                        handleOnChange({name: "countyId", value: selectedOption})
                    }}
                    required={true}
                    isLoading={isNullOrEmpty(createOrderData?.state) || countyByStateIdLoading || countyByStateIdFetching}
                />
            </div>

            <div className="col-span-12 mt-8 p-4 rounded-sm border border-surface-6 relative">
                <label className="text-text-1 absolute bg-surface-2 -top-3.5 pr-1">Documents </label>
                <SelectField
                    label="Name"
                    value={packageData?.fileName || null}
                    placeholder="Select Name..."
                    options={createOptionListForSelectTag(allCannedFileNamesApiResult?.data, "name", "name")}
                    onChange={(selectedOption) => {
                        handlePackageChange({name: "fileName", value: selectedOption})
                    }}
                    required={true}
                />
                {
                    packageData?.fileName?.value === "Custom" &&
                    <InputField
                        label=""
                        value={packageData?.customFileName || ""}
                        type="text"
                        placeholder="Enter Document Name..."
                        onChange={handlePackageChange}
                        name={"customFileName"}
                    />
                }
                <SelectField
                    label="Type"
                    value={packageData?.documentType || null}
                    placeholder="Select Document Type..."
                    options={createOptionListForSelectTag(recipientInstrumentsApiResult, "instrument", "instrument")}
                    onChange={(selectedOption) => {
                        handlePackageChange({name: "documentType", value: selectedOption});
                    }}
                    isLoading={isNullOrEmpty(createOrderData?.countyId) || recipientInstrumentsLoading || recipientInstrumentsFetching || countyByStateIdLoading || countyByStateIdFetching}
                    required={packageData?.fileName?.value !== "Document Package"}
                />

                <div className={`col-span-12 md:grid md:grid-cols-12 gap-3 mt-3`}>
                    <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                        <h2 className="text-text-1">*File</h2>
                    </div>
                    <div className="col-span-9">
                        <input
                            className={"w-full bg-surface-2 rounded-md border border-surface-4 text-text-1 form_control p-2"}
                            ref={fileInputRef}
                            onChange={handlePackageChange}
                            name={"file"}
                            type="file"
                            accept={".pdf"}
                        />
                    </div>
                </div>

                {packageData?.decimal?.length > 0 && <InputField
                    label="Consideration"
                    value={packageData?.consideration || ""}
                    type="number"
                    placeholder="Enter Consideration..."
                    onChange={handlePackageChange}
                    name={"consideration"}
                    required={packageData?.decimal[0].required === "ALWAYS"}
                />}

                <div className="col-span-12 md:grid md:grid-cols-12 gap-3 mt-3">
                    <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                        <h2 className="text-text-1">Grantors</h2>
                    </div>
                    <div className="col-span-9">
                        <PersonInfoForm
                            obj={grantorObj}
                            enums={enums}
                            handleSubmitAction={(e) => {
                                setPackageData({
                                    ...packageData,
                                    grantors: [...packageData.grantors ?? [], e]
                                });
                            }}
                            submitBtnText={"Add"}
                            requirementsForInstrument={packageData?.documentType?.value !== undefined ? requirementsForInstrument : []}
                            item={"grantor"}
                            setUnsavedPersonInfo={setUnsavedPersonInfo}
                            undoAction={(e) => {
                                setGrantorObj({type: {"label": "Individual", "value": "INDIVIDUAL"}})
                            }}
                        />
                    </div>
                </div>

                {
                    packageData?.grantors?.length > 0 &&
                    <PersonInfoList personList={packageData?.grantors}
                                    enums={enums}
                                    onDeleteAction={(index: number) => {
                                        setPackageData((prevState: any) => ({
                                            ...prevState,
                                            ["grantors"]: prevState["grantors"].filter((_: any, i: number) => i !== index)
                                        }));
                                    }}
                                    submitAction={(items: any[]) => {
                                        setPackageData((prevState: any) => ({
                                            ...prevState,
                                            ["grantors"]: items
                                        }));
                                    }}
                                    requirementsForInstrument={packageData?.documentType?.value !== undefined ? requirementsForInstrument : []}
                                    item={"grantor"}
                                    setUnsavedPersonInfo={setUnsavedPersonInfo}
                    />
                }

                <div className="col-span-12 md:grid md:grid-cols-12 gap-3 mt-3">
                    <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                        <h2 className="text-text-1">Grantees</h2>
                    </div>
                    <div className="col-span-9">
                        <PersonInfoForm
                            obj={granteeObj}
                            enums={enums}
                            handleSubmitAction={(e) => {
                                setPackageData({
                                    ...packageData,
                                    grantees: [...packageData.grantees ?? [], e]
                                });
                            }}
                            submitBtnText={"Add"}
                            requirementsForInstrument={packageData?.documentType?.value !== undefined ? requirementsForInstrument : []}
                            item={"grantee"}
                            setUnsavedPersonInfo={setUnsavedPersonInfo}
                            undoAction={(e) => {
                                setGranteeObj({type: {"label": "Individual", "value": "INDIVIDUAL"}})
                            }}
                        />
                    </div>
                </div>

                {
                    packageData?.grantees?.length > 0 &&
                    <PersonInfoList personList={packageData?.grantees} enums={enums}
                                    onDeleteAction={(index: number) => {
                                        setPackageData((prevState: any) => ({
                                            ...prevState,
                                            ["grantees"]: prevState["grantees"].filter((_: any, i: number) => i !== index)
                                        }));
                                    }}
                                    submitAction={(items: any[]) => {
                                        setPackageData((prevState: any) => ({
                                            ...prevState,
                                            ["grantees"]: items
                                        }));
                                    }}
                                    requirementsForInstrument={packageData?.documentType?.value !== undefined ? requirementsForInstrument : []}
                                    item={"grantee"}
                                    setUnsavedPersonInfo={setUnsavedPersonInfo}
                    />
                }

                {
                    packageData?.helperDocuments?.length > 0 &&
                    <div className="col-span-12 md:grid md:grid-cols-12 gap-3">
                        <div className="col-span-3 sm:mt-2 lg-mt-0 flex items-center">
                            <h2 className="text-text-1">Helper Documents</h2>
                        </div>
                        <div className="col-span-9">
                            <HelperInfoList
                                helperList={packageData?.helperDocuments}
                                onDeleteAction={(index: number) => {
                                    setPackageData((prevState: any) => ({
                                        ...prevState,
                                        ["helperDocuments"]: prevState["helperDocuments"].filter((_: any, i: number) => i !== index)
                                    }));
                                }}
                                submitAction={(items: any[]) => {
                                    setPackageData((prevState: any) => ({
                                        ...prevState,
                                        ["helperDocuments"]: items
                                    }));
                                }}
                            />
                        </div>
                    </div>
                }


                <div className="flex justify-end mt-5">
                    <Button btnText={"Add Document"} isLoading={false}
                            type={(isValidFileAndDocumentType() && isValidHelperDocument() && isValidGrantorsAndGrantee() && isValidConsideration() && !unSavedPersonInfo) ? "submit" : "button"}
                            onClick={handleAddPackageToList}
                            isDisabled={!isValidFileAndDocumentType() || !isValidHelperDocument() || !isValidGrantorsAndGrantee() || !isValidConsideration() || unSavedPersonInfo}
                            title={(!isValidFileAndDocumentType() || !isValidHelperDocument() || !isValidGrantorsAndGrantee() || !isValidConsideration() || unSavedPersonInfo) ? "Unsaved Changes or Required fields missing" : ""}
                    />
                </div>
            </div>

            <ButtonTray
                buttons={[
                    {
                        buttonProps: {
                            btnText: "Previous",
                            onClick: () => setTab(1),
                            type: "close",
                        },
                        buttonType: "button"
                    },
                    {
                        buttonProps: {
                            btnText: "Next",
                            type: (isNextButtonEnabled() && !unSavedPersonInfo) ? "submit" : "button",
                            onClick: () => setTab(3),
                            isDisabled: (!isNextButtonEnabled() || unSavedPersonInfo),
                            title: (!isNextButtonEnabled() || unSavedPersonInfo) ? "Unsaved Changes or Required fields missing" : ""
                        },
                        buttonType: "button",

                    }
                ]}
                align={"end"}
                gap={2}
            />

            <RecordingListSection createOrderData={createOrderData} setCreateOrderData={setCreateOrderData}
                                  editPackageIndexList={editPackageIndexList} undoPackageListData={undoPackageListData}
                                  setEditPackageIndexList={setEditPackageIndexList}
                                  setUndoPackageListData={setUndoPackageListData}
                                  requirementsForInstrument={requirementsForInstrument}
                                  setUnsavedPersonInfo={setUnsavedPersonInfo}
            />

        </>
    )
}
export default RecordingDetailTab