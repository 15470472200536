import {IoIosArrowForward} from "react-icons/io";
import ModalButton from "../../../../components/common/form/ModalButton";
import {FaTrash} from "react-icons/fa6";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import FincenPartyForm from "./FincenPartyForm";
import React, {FC, useState} from "react";
import {isNullOrEmpty} from "../../../../helpers/utils/Utils";

interface props {
    type: string,
    createOrderData?: any,
    setCreateOrderData: any,
    setUnsavedPersonInfo: any
}

const FincenPartyListSection: FC<props> = ({type, createOrderData, setCreateOrderData, setUnsavedPersonInfo}) => {

    const [showPackageDetail, setShowPackageDetail] = useState<boolean[]>(new Array(createOrderData?.parties?.filter((el: any) => el.type === type)).fill(false));

    const toggleShowHelperDetail = (index: number) => {
        const updatedShowHelperDetail = [...showPackageDetail];
        updatedShowHelperDetail[index] = isNullOrEmpty(updatedShowHelperDetail[index]) ? true : !updatedShowHelperDetail[index];
        setShowPackageDetail(updatedShowHelperDetail);
    }

    return (
        <>
            {createOrderData?.parties?.map((obj: any, index: number) => {
                return <>
                    {obj.type === type.toUpperCase() &&
                        <div
                            className={`bg-highlight-2 justify-between flex px-2 py-1 cursor-pointer mt-2`}>
                            <div className="flex gap-1 items-center">
                                <h3 className="text-text-1 font-semibold">{obj.name}</h3>
                            </div>
                            <div className="flex gap-3">
                                <IoIosArrowForward
                                    className={"text-text-1 text-lg cursor-pointer " + (showPackageDetail[index] ? "rotate-90" : "")}
                                    onClick={() => {
                                        toggleShowHelperDetail(index);
                                    }}
                                />

                                <ModalButton
                                    triggerButton={<FaTrash
                                        className={"cursor-pointer text-text-1 text-lg hover:text-text-hover-1"}
                                        title={"Delete Document Helper"}/>}
                                    onOpen={() => {
                                    }}
                                    modal={
                                        <ConfirmationModal
                                            show={true}
                                            setShow={() => {
                                            }}
                                            title={"Delete " + type}
                                            bodyMessage={"Are you sure you want to delete this " + type + " ?"}
                                            onSubmit={() => {
                                                setCreateOrderData((prev: any) => ({
                                                    ...prev,
                                                    parties: prev.parties.filter((_: any, i: number) => i !== index)
                                                }));
                                            }}
                                            isLoading={false}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    }

                    {obj.type === type.toUpperCase() &&
                        showPackageDetail[index] &&
                        <FincenPartyForm title={type}
                                         submitBtnText={"Update"}
                                         setUnsavedPersonInfo={setUnsavedPersonInfo}
                                         setCreateOrderData={setCreateOrderData}
                                         obj={obj}
                                         index={index}/>
                    }
                </>
            })}

        </>
    )
}
export default FincenPartyListSection;