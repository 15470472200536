import {getCookie, showToast} from "./Utils";

export const handleRTKQuery = async (fetchData: any, onSuccess?: any, onFailure?: any, onComplete?: any) => {
    try {
        const response = await fetchData();
        onSuccess?.(response)
    } catch (error) {
        onFailure?.(error)
    } finally {
        onComplete?.();
    }
}

export const getAuthorizationHeader = () => {
    return {
        'Authorization': getCookie("authToken")
    }
}

export const getFileDownloadHeaders = () => {
    return {
        'Authorization': getCookie("authToken"),
        'Content-Disposition': "attachment; filename=template.csv",
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
}

export const handleQueryError = async (queryFulfilled: Promise<any>) => {
    queryFulfilled.catch(error => {
        showToast(error?.error?.data?.message, "error");
    })
}


export const handleQueryErrorAndSuccess = async (
    queryFulfilled: Promise<any>,
    action: 'Created' | 'Updated' | 'Deleted' | 'Reset' | 'Assigned' | any,
    entityName: string // the user-friendly name of the entity
) => {
    queryFulfilled.then(() => {
        showToast(`${action} ${entityName} successfully!`, "success");
    }).catch(error => {
        showToast(error?.error?.data?.message || "An error occurred", "error");
    });
};

