import {showToast} from "./Utils";

export function validateZipCode(zipCode: string): boolean {
    const validationMessage = "Invalid ZIP code format. Must be 'XXXXX', 'XXXXX-XXXX', or 'XXXXXXXXX'.";

    if (!zipCode.endsWith("-") && /^\d{5}(-?\d{4})?$/.test(zipCode)) {
        return true; // Valid ZIP code, no error message
    }

    showToast(validationMessage, 'warning'); // Return error message if invalid
    return false;
}


export function validateTaxIdOrEmpId(val: string, fieldName: string): boolean {

    const sanitizedTaxId = val.replace(/[-\s]/g, ""); // Remove dashes and spaces

    if (!/^\d{9}$/.test(sanitizedTaxId)) {
        showToast(`Invalid ${fieldName}: Must be a 9-digit numeric value.`, 'warning');
        return false;
    }

    if (new Set(sanitizedTaxId).size === 1) {
        showToast(`Invalid ${fieldName}: Cannot be all the same digits.`, 'warning');
        return false;
    }

    if (isIncrementalOrDecremental(sanitizedTaxId)) {
        showToast(`Invalid ${fieldName}: Cannot be a simple sequential pattern.`, 'warning');
        return false;
    }

    return true; // No validation errors
}

function isIncrementalOrDecremental(taxId: string): boolean {
    let isIncremental = true;
    let isDecremental = true;

    for (let i = 1; i < taxId.length; i++) {
        if (parseInt(taxId[i]) !== parseInt(taxId[i - 1]) + 1) {
            isIncremental = false;
        }
        if (parseInt(taxId[i]) !== parseInt(taxId[i - 1]) - 1) {
            isDecremental = false;
        }
    }

    return isIncremental || isDecremental;
}
