import React, {FC, ReactNode, useState} from "react";

interface props {
    // If null, no header
    title: string | null,
    contents: ReactNode
    shouldScroll?: boolean
    maxHeightClass?: string
}

const ContainerCard: FC<props> = ({title, contents, shouldScroll, maxHeightClass}) => {
    return (
        <>
            <div className="bg-surface-2 rounded-md p-3 mt-3">
                {title &&
                    <div className="flex gap-2 items-center border-b border-surface-3 pb-2">
                        <h3 className="text-text-1 font-semibold">{title}</h3>
                    </div>
                }
                <div className={`${shouldScroll && 'overflow-y-auto'} ${maxHeightClass && maxHeightClass}`}>
                    {contents}
                </div>
            </div>
        </>
    )
}

export default ContainerCard;