import Breadcrumb, {Crumb} from "../../../components/common/Breadcrumbs";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Form from "../../../components/common/form/Form";
import InputField from "../../../components/common/form/InputField";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import {skipToken} from "@reduxjs/toolkit/query";
import {useCreateStateMutation, useGetStateByIdQuery, useUpdateStateMutation} from "./statesApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {Routes as AppRoutes} from "../../../routes";

interface props {
    crumbs: Crumb[];
    title: string,
    btnText: string,
    isEditable?: boolean
}

const StateForm: FC<props> = ({crumbs, title, btnText, isEditable = true}) => {

    const navigate = useNavigate();
    let {stateId} = useParams();

    const [stateData, setStateData] = useState<any>({});
    const stateByIdApi = useGetStateByIdQuery(stateId ?? skipToken);
    const [createState, createStateApiResponse] = useCreateStateMutation();
    const [updateState, updateStateApiResponse] = useUpdateStateMutation();

    useEffect(() => {
        if (stateByIdApi?.data) {
            setStateData({...stateByIdApi?.data});
        }
    }, [stateByIdApi?.data]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const createOrUpdateState = async () => {
            if (btnText === "Create") {
                await createState(stateData).unwrap();
            } else {
                await updateState({
                    id: stateId,
                    requestBody: stateData,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateState, () => {
            setStateData({});
            navigate("/states");
        });
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setStateData({
            ...stateData,
            [name]: value
        })
    }

    return (
        <>
            <div className="px-4 py-2">
                <div className={"justify-between sm:block md:flex lg:flex"}>
                    <Breadcrumb crumbs={crumbs}/>
                    <AuditableInfoButton obj={stateByIdApi?.data}/>
                </div>
                <div className="add_state_outer flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">{title}</h3>
                        <Form onSubmit={handleSubmit} fields={
                            <>
                                <div className="grid grid-cols-12">

                                    <InputField
                                        name={"fipsCode"}
                                        label="FIPS Code"
                                        onChange={handleOnChange}
                                        value={stateData?.fipsCode || ""}
                                        type="number"
                                        placeholder="Enter FIPS Code..."
                                        required={true}
                                    />
                                    <InputField
                                        name={"name"}
                                        label="Name"
                                        onChange={handleOnChange}
                                        value={stateData?.name || ""}
                                        type="text"
                                        placeholder="Enter Name..."
                                        required={true}
                                    />

                                    <InputField
                                        name={"abbreviation"}
                                        label="State Abbreviation"
                                        onChange={handleOnChange}
                                        value={stateData?.abbreviation || ""}
                                        type="text"
                                        placeholder="Enter State Abbreviation..."
                                        required={true}
                                    />

                                    <InputField
                                        name={"taxRateDivisor"}
                                        label="Tax Rate Divisor"
                                        onChange={handleOnChange}
                                        value={stateData?.taxRateDivisor || ""}
                                        type="number"
                                        placeholder="Enter Tax Rate Divisor..."
                                    />

                                </div>

                                <ButtonTray
                                    buttons={[
                                        {
                                            buttonProps: {
                                                btnText: "Cancel",
                                                onClick: () => navigate(AppRoutes.States.path),
                                                type: "close",
                                            },
                                            buttonType: "button"
                                        },
                                        {
                                            buttonProps: {
                                                btnText: "Submit",
                                                type: "submit",
                                                isLoading: (createStateApiResponse.isLoading || updateStateApiResponse.isLoading),
                                                isVisible: isEditable
                                            },
                                            buttonType: "button",

                                        }
                                    ]}
                                    align="end"
                                    gap={2}
                                />

                            </>
                        }
                        />
                    </div>
                </div>


            </div>
        </>
    )
}
export default StateForm;