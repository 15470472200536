import React from "react";

const SuccessPage = () => {

    return (
        <>

            <div className="mb-4 mt-8 text-center text-text-1">
                <h4 className="text-2xl font-semibold">Thank you for reporting your certified information!</h4>
                <h4 className={"mt-2"}> Your transaction has been successfully processed and no further
                    action is needed.</h4>
            </div>
        </>
    );
}

export default SuccessPage;
