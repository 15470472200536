import React, {FC, FormEvent, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {useGetBatchByIdQuery} from "../fincenBatchApi";
import {useDispatch} from "react-redux";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {useFetchSimplifileTokenStatusQuery} from "../../user/users/usersApi";
import {hasPermission, jwtDecode} from "../../../helpers/utils/AccessControlUtils";
import {skipToken} from "@reduxjs/toolkit/query";
import {
    useDeleteDocumentMutation, useGetProductDocumentListQuery, useLazyViewFileQuery,
    useRestoreDocumentMutation,
    useUpdateDocumentVisibilityMutation
} from "../../order/orderFilesApi";
import {useRemoveDocumentMutation, useUpdateDocumentMutation} from "../../order/recordingDocumentApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useGetRecordingPackageByProductIdQuery} from "../../order/recordingPackageApi";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import {FaEye, FaEyeSlash, FaFilter, FaShareAlt, FaToggleOff, FaToggleOn} from "react-icons/fa";
import ButtonTray from "../../../components/common/form/ButtonTray";
import DocumentDeliverModal from "../../order/modal/DocumentDeliverModal";
import UploadDocumentModal from "../../order/modal/UploadDocumentModal";
import {
    FaCircleInfo,
    FaDownload,
    FaFileCircleMinus,
    FaFileCirclePlus,
    FaRotateRight,
    FaTrash, FaTrashCanArrowUp,
    FaUpload
} from "react-icons/fa6";
import Select from "react-select";
import {selectStyle} from "../../../helpers/utils/StyleUtils";
import SkeletonLoader from "../../../components/common/SkeletonLoader";
import ModalButton from "../../../components/common/form/ModalButton";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import {getFileSizeFromBytes, toTitleCase, truncateText} from "../../../helpers/utils/TextUtils";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import VendorInfoModal from "../../order/modal/VendorInfoModal";
import SimplifileLinkButton from "../../order/detailPage/SimplifileLinkButton";
import {AuthTokenPage} from "../../order/AuthTokenPage";
import AddDocumentToPackageModal from "../../order/modal/AddDocumentToPackageModal";
import AuditableInfo from "../../../components/common/AuditableInfo";
import {useSearchBatchFilesQuery} from "../batchFileApi";

interface props {
    batchId?: string | null,
}

const FincenFileContainer: FC<props> = ({batchId}) => {

    const dispatch = useDispatch();

    const [show, setShowFilterList] = useState(false);
    const [includeDeletedFiles, setIncludeDeletedFiles] = useState(false);
    const {data: enums} = useGetEnumsQuery("")
    const [selectedTypes, setSelectedTypes] = useState<any[]>(['BATCH'])
    const [selectedFileId, setSelectedFileId] = useState("");
    const [selectedRecordedDocId, setSelectedRecordedDocId] = useState("");

    const handleChange = (selectedOption: any[]) => {
        const selectedOptions = selectedOption?.map(c => c.value) || []
        setSelectedTypes(selectedOptions)
    };

    const [deleteDocument, {isLoading: isDeleteLoading}] = useDeleteDocumentMutation();
    const [restoreDocument, {isLoading: isRestoreLoading}] = useRestoreDocumentMutation();
    const [removeDocument, {isLoading: isRemoveLoading}] = useRemoveDocumentMutation();
    const [updateDocument, {isLoading: isUpdateFromSimplifileLoading}] = useUpdateDocumentMutation();
    const [viewFile] = useLazyViewFileQuery();

    const handleViewDownloadFile = async (fileToDownloadId: string, isDownload: boolean) => {
        viewFile({fileId: fileToDownloadId, isDownload: isDownload})
    }

    const handleDocumentDeleteSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return deleteDocument({id: selectedFileId});
    }

    const handleDocumentRestoreSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return restoreDocument({id: selectedFileId});
    }

    const handleRemoveDocumentSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return removeDocument(selectedRecordedDocId);
    }

    const handleUpdateDocumentSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return updateDocument(selectedRecordedDocId);
    }

    const {
        data: documents,
        isLoading: loading,
        isError: error,
    } = useSearchBatchFilesQuery({
        batchId: batchId,
        fileTypes: selectedTypes,
        includeDeleted: includeDeletedFiles
    }, {skip: !batchId, pollingInterval: 10000});

    const iconButtonStyle = "cursor-pointer text-highlight-3 text-lg hover:text-highlight-7";

    const filterOptionList = useMemo(() => createOptionListForSelectTag(enums?.BatchFileType, "displayName", "constant"),
        [enums]);

    return (
        <>
            <div className="bg-surface-2 rounded-md p-3 mt-3">
                <div className="flex justify-between gap-2 items-center border-b border-surface-3 pb-2">
                    <div className="text-text-1 font-semibold flex gap-3">
                        Documents ({documents?.data?.length || 0})
                        <div className={"mt-1"}>
                            <FaFilter className={iconButtonStyle} onClick={() => {
                                setShowFilterList(!show)
                            }}/>
                        </div>
                    </div>

                    <ButtonTray
                        buttons={[
                            {
                                buttonProps: {
                                    icon: <FaToggleOn/>,
                                    onClick: () => {
                                        setIncludeDeletedFiles(!includeDeletedFiles);
                                    },
                                    hasBackground: false,
                                    title: 'Hide Deleted Files',
                                    isVisible: hasPermission(["DELFILE-V"]) && includeDeletedFiles
                                },
                                buttonType: "icon",
                            },
                            {
                                buttonProps: {
                                    icon: <FaToggleOff/>,
                                    onClick: () => {
                                        setIncludeDeletedFiles(!includeDeletedFiles);
                                    },
                                    hasBackground: false,
                                    title: 'Show Deleted Files',
                                    isVisible: hasPermission(["DELFILE-V"]) && !includeDeletedFiles
                                },
                                buttonType: "icon",
                            },
                        ]}
                        align={"end"}
                        gap={4}
                        addTopLine={false}
                    />
                </div>

                {
                    show && <Select
                        placeholder='Select filter options...'
                        isMulti={true}
                        options={filterOptionList}
                        value={filterOptionList.filter((option: any) => selectedTypes.includes(option.value))}
                        //@ts-ignore
                        onChange={handleChange}
                        styles={selectStyle}
                    />
                }
                <div className={'overflow-y-auto max-h-96'}>
                    {
                        (loading) ?
                            <SkeletonLoader className={"mt-2"} count={3}/> : error && !documents ?
                                <div className="mt-2 border border-surface-3 p-4 rounded-sm">
                                    <h3 className="text-text-1">
                                        Unable to retrieve documents, please try again in a few minutes
                                    </h3>
                                </div>
                                : documents?.length === 0 ?
                                    <div className="mt-2 border border-surface-3 p-4 rounded-sm">
                                        <h3 className="text-text-1">
                                            No product documents to display
                                        </h3>
                                    </div> :
                                    documents?.data?.map((data: any, index: number) => {
                                        return <div
                                            key={index}
                                            //  TODO: Maybe have a separate color category for INFO/success/happy kind of stuff, like we do for error
                                            className={`${data?.delivered && 'border-l-highlight-2'} sm:block md:flex justify-between mt-3 border-l-8 border-t border-b border-r rounded-sm border-surface-3 py-1 px-2 ${data?.deleted && "bg-surface-1"}`}>

                                            {/*Start Visibility*/}
                                            <div className="sm:block md:flex gap-2 items-start relative">
                                                <div>
                                                    <div
                                                        className="text-ellipsis cursor-pointer text-highlight-6 hover:text-highlight-7"
                                                        onClick={() => {
                                                            handleViewDownloadFile(data.id, false).then()
                                                        }} title={data?.fileName}>{truncateText(data?.fileName, 40)}</div>
                                                    <div
                                                        className="text-text-2 italic">{getEnumDisplayNameByConstant(
                                                        enums?.BatchFileType,
                                                        data?.fileType
                                                    )} ({getFileSizeFromBytes(data?.fileSize, 0)})
                                                    </div>

                                                    {data?.recordingDocument?.vendorDetails &&
                                                        <div className="flex gap-1 items-center mt-1">
                                                            <h3 className="text-text-2 italic">{toTitleCase(data?.recordingDocument?.vendorDetails?.vendorStatus)}</h3>

                                                            <ModalButton
                                                                triggerButton={
                                                                    <FaCircleInfo title='View vendor information'
                                                                                  className={iconButtonStyle}/>
                                                                }
                                                                modal={
                                                                    <VendorInfoModal
                                                                        show={true}
                                                                        setShow={() => {
                                                                        }}
                                                                        recordingDocument={data?.recordingDocument}
                                                                    />
                                                                }
                                                            />
                                                        </div>}
                                                </div>
                                            </div>
                                            {/*End Visibility*/}

                                            <div>
                                                <div className={"md:mt-16 sm:mt-6"}>
                                                    <ButtonTray
                                                        buttons={[
                                                            {
                                                                buttonProps: {
                                                                    icon: <FaDownload/>,
                                                                    onClick: () => {
                                                                        handleViewDownloadFile(data.id, true).then();
                                                                    },
                                                                    hasBackground: false,
                                                                    title: "Download document",
                                                                },
                                                                buttonType: "icon",
                                                            },
                                                            {
                                                                buttonProps: {
                                                                    modal: <ConfirmationModal
                                                                        show={true}
                                                                        setShow={() => {
                                                                        }}
                                                                        title={"Delete Document"}
                                                                        bodyMessage={"Are you sure you want to delete this document ?"}
                                                                        onSubmit={handleDocumentDeleteSubmit}
                                                                        isLoading={isDeleteLoading}
                                                                    />,
                                                                    onOpen: () => {
                                                                        setSelectedFileId(data?.id)
                                                                    },
                                                                    triggerButton: <FaTrash className={iconButtonStyle}
                                                                                            title={"Delete document"}/>,
                                                                    isVisible: !data?.deleted && hasPermission(["FILE-D"])
                                                                },
                                                                buttonType: "modal",
                                                            },
                                                            {
                                                                buttonProps: {
                                                                    modal: <ConfirmationModal
                                                                        show={true}
                                                                        setShow={() => {
                                                                        }}
                                                                        title={"Restore Document"}
                                                                        bodyMessage={"Are you sure you want to restore this product document ?"}
                                                                        onSubmit={handleDocumentRestoreSubmit}
                                                                        isLoading={isRestoreLoading}
                                                                    />,
                                                                    onOpen: () => {
                                                                        setSelectedFileId(data?.id)
                                                                    },
                                                                    triggerButton: <FaTrashCanArrowUp
                                                                        className={iconButtonStyle}
                                                                        title={"Restore document"}/>,
                                                                    isVisible: data?.deleted && hasPermission(["FILE-U"])
                                                                },
                                                                buttonType: "modal",
                                                            }
                                                        ]}
                                                        align={"end"}
                                                        gap={1}
                                                        addTopLine={false}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    })}
                </div>
            </div>
        </>
    )
}
export default FincenFileContainer