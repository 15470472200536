import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryError, handleQueryErrorAndSuccess} from "../../helpers/utils/RtkQueryUtils";

export const questionnaireApi = strApi.injectEndpoints({

    endpoints: (build) => ({

        createQuestionnaire: build.mutation({
            query: ({requestBody, partyId}) => {
                return {
                    url: `/fincen-questionnaire/${partyId}`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Saved", "Questionnaire");

            },
        }),

        findQuestionnaireByPartyId: build.query({
            query: (partyId) => {
                return {
                    url: `/fincen-questionnaire/partyId/${partyId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled);
            },
        }),
    })
})

export const {
    useCreateQuestionnaireMutation,
    useFindQuestionnaireByPartyIdQuery,
    useLazyFindQuestionnaireByPartyIdQuery
} = questionnaireApi