import {GroupBase, StylesConfig} from "react-select";

// TODO: This needs some cleanup.
//  We should focus some effort on that after we move the tailwind.config.js values (i.e. the actual colors) to being css variables
//  Then use those css variables in the tailwind.config.js
//  The reason we should do that is to allow the use of theme values in plain css
export const selectStyle = {
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused
            ? "0 0 0 .2rem rgba(0, 123, 255, .25)"
            : provided.boxShadow,
        borderColor: state.isDisabled ? '#a9a9a93f' : '#626262',  // Change border color when disabled
        color: state.isDisabled ? "#a9a9a93f" : "#202020",         // Change text color when disabled
        backgroundColor: state.isDisabled ? "#2020203f" : "#353535",  // Change background color when disabled
        minHeight: "43px",
        "&:hover": {
            borderColor: state.isDisabled ? '#a9a9a93f' : '#7a7a7a',
        },
        borderRadius: "0.375rem",
    }),

    clearIndicator: (provided: any, state: any) => ({
        ...provided,
        display: state.isDisabled ? 'none' : provided.display,
        color: state.isDisabled ? '#a9a9a93f' : '#939393',
    }),

    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled ? '#a9a9a93f' : '#939393',
    }),
    indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#a9a9a93f' : '#939393',
    }),

    input: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled ? '#a9a9a93f' : '#fff',           // Change input text color when disabled
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled ? '#a9a9a93f' : provided.color,
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        color: '#fff',
        backgroundColor: state.isFocused ? '#225332' : '#4b4b4b',
    }),
    menu: (provided: any) => ({
        ...provided,
        backgroundColor: '#4b4b4b',
    }),
};

export interface OptionType {
    label: string;
    value: string;
}

export const chosenInputStyle: StylesConfig<OptionType, true, GroupBase<OptionType>> = {
    control: (provided: any, state: any) => ({
        ...provided,
        boxShadow: state.isFocused
            ? " 0 0 0 .2rem rgba(0, 123, 255, .25)"
            : provided.boxShadow,
        borderColor: "#626262",
        backgroundColor: "transparent",

    }),
    option: (provided, state) => ({
        ...provided,
        color: '#000',
    }),
    input: (provided) => ({
        ...provided,
        color: '#fff',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#e5e7eb',
        color: "#000"
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#000',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#000',
        ':hover': {
            backgroundColor: '#d1d5db',
            color: '#000',
        },
    }),
    menu: (provided) => ({
        ...provided,
        display: 'none', // Hide the dropdown menu
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none', // Hide the indicator separator
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        display: 'none', // Hide the dropdown indicator
    }),
};


export function getContactStatusStyle(status: string) {
    if (status === "COMPLETED") {
        return "bg-highlight-2";
    } else if (status === "NOT_INVITED") {
        return "bg-warn-2";
    } else if (status === "IN_PROGRESS") {
        return "bg-highlight-3";
    } else {
        return "bg-highlight-5";
    }
}

export const iconButtonStyle = "cursor-pointer text-highlight-3 text-lg hover:text-highlight-7";

