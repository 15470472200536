import {strApi} from "../../app/strApi";
import {
    getAuthorizationHeader,
    handleQueryError
} from "../../helpers/utils/RtkQueryUtils";

export const fincenFilingApi = strApi.injectEndpoints({

    endpoints: (build) => ({
        // Define the endpoint for searching orders
        getFilingById: build.query({
            query: (id) => {
                return {
                    url: `/fincen-filings/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ["fincenFilingById"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        getFilingsByBatchId: build.query({
            query: (params) => {
                return {
                    url: `/fincen-filings/batch/${params.batchId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["fincenFilingsByBatchId"]
        }),
        getFilingsByProductId: build.query({
            query: (params) => {
                return {
                    url: `/fincen-filings/productId/${params.productId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["fincenFilingsByProductId"]
        }),
    }),
});

export const {
    useGetFilingByIdQuery,
    useGetFilingsByBatchIdQuery,
    useGetFilingsByProductIdQuery,
} = fincenFilingApi;