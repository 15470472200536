import React, {FC, useState} from "react";
import InputField from "../../../components/common/form/InputField";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {useCreateSellerMutation} from "../sellerApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useGetAllStatesByCountryIdQuery} from "../../data/states/statesApi";
import {createOptionListForSelectTag, isNullOrEmpty} from "../../../helpers/utils/Utils";
import SelectField from "../../../components/common/form/SelectField";
import {useGetAllCountriesQuery} from "../../data/countries/countriesApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {validateTaxIdOrEmpId, validateZipCode} from "../../../helpers/utils/FincenValidation";
import {E164Number} from "libphonenumber-js/types";

interface Props {
    activeTab: string
    setActiveTab: any;
    tabsWithUnsavedChanges: any;
    setTabsWithUnsavedChanges: any;
    partyId: string,
    setOrgDetailData: any
}

const SellerForm: FC<Props> = ({
                                   activeTab,
                                   setActiveTab,
                                   tabsWithUnsavedChanges,
                                   setTabsWithUnsavedChanges,
                                   partyId,
                                   setOrgDetailData
                               }) => {

    const [currentTab, setCurrentTab] = useState("1");
    const [stateId, setStateId] = useState<any>(null);
    const [countryId, setCountryId] = useState<any>({
        value: "236b617cc-105e-4f73-a91c-e2162beac864",
        label: "United States of America"
    });
    const [createSellerApi, createSellerApiResponse] = useCreateSellerMutation();
    const allStatesApiResult = useGetAllStatesByCountryIdQuery(countryId?.value ?? skipToken);
    const [soleProprietorship, setSoleProprietorship] = useState<boolean>(false);
    const allCountriesApiResult = useGetAllCountriesQuery("");
    const [taxId, setTaxId] = useState("");

    const [organizationInfo, setOrganizationInfo] = useState({
        organizationName: "",
        natureOfBusiness: "",
        employerId: "",
    });

    const [contactInfo, setContactInfo] = useState({
        contactName: "",
        contactTitle: "",
        contactPhoneNumber: "",
    });

    const [addressInfo, setAddressInfo] = useState({
        streetAddress: "",
        city: "",
        zip: "",
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, section: string | {
        name: string;
        value: any
    }) => {
        const {name, value} = e.target;
        if (section === "organization") {
            setOrganizationInfo({...organizationInfo, [name]: value});
        } else if (section === "contact") {
            setContactInfo({...contactInfo, [name]: value});
        } else if (section === "address") {
            setAddressInfo({...addressInfo, [name]: value});
        }
    };

    const isNextButtonEnabled = (obj: {}) => {
        let list = Object.keys(obj) as Array<keyof typeof obj>;
        // @ts-ignore
        return list?.every(cur => !isNullOrEmpty(obj?.[cur]))
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        let list = tabsWithUnsavedChanges.filter((el: any) => el !== activeTab);
        setTabsWithUnsavedChanges(list);

        let obj = {
            requestBody: {
                partyId: partyId,
                stateId: stateId?.value,
                taxId: taxId,
                soleProprietorship: soleProprietorship,
                country: countryId?.value,
                ...organizationInfo,
                ...contactInfo,
                ...addressInfo
            }
        }

        await handleRTKQuery(
            async () => {
                return await createSellerApi(obj).unwrap();
            },
            (isSuccess: boolean) => {
                if (isSuccess) {
                    setOrgDetailData(obj?.requestBody);
                    setActiveTab(`sign`);
                }
            }
        );

    };

    const handleButton = (backAction: any, continueAction: any, buttonLabel: string, obj: {}) => {
        return (
            <ButtonTray
                buttons={[
                    {
                        buttonProps: {
                            btnText: "Back",
                            onClick: backAction,
                            type: "close",
                        },
                        buttonType: "button"
                    },
                    {
                        buttonProps: {
                            btnText: buttonLabel,
                            type: "submit",
                            isLoading: createSellerApiResponse.isLoading,
                            isVisible: true,
                            onClick: continueAction,
                            isDisabled: !isNextButtonEnabled(obj)
                        },
                        buttonType: "button",
                    }
                ]}
                align="end"
                gap={2}
                addTopLine={false}
            />
        );
    };

    const organizationFieldLabels = {
        organizationName: "Organization’s Legal Name",
        natureOfBusiness: "Nature Of Business",
        employerId: "Employer ID",
    };

    const contactFieldLabels = {
        contactName: "Name",
        contactTitle: "Title",
    };

    const addressFieldLabels = {
        streetAddress: "Street Address",
        city: "City",
        zip: "ZIP",
    };

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            {currentTab === "1" &&
                <div className="ml-auto w-3/4">
                    <h2 className="text-2xl font-semibold">Is this entity a sole proprietorship?</h2>
                    <div className={"mt-3 text-md"}>
                        <label>
                            <input
                                type="radio"
                                name="soleProprietorship"
                                value="true"
                                className="mr-2"
                                checked={soleProprietorship}
                                onChange={(e: any) => {
                                    setSoleProprietorship(!soleProprietorship);
                                }}
                            />
                            Yes
                        </label>
                    </div>
                    <div className={"mt-3 text-md"}>
                        <label>
                            <input
                                type="radio"
                                name="soleProprietorship"
                                value="false"
                                className="mr-2"
                                checked={!soleProprietorship}
                                onChange={(e: any) => {
                                    setSoleProprietorship(!soleProprietorship);
                                }}
                            />No
                        </label>
                    </div>

                    {handleButton(() => setActiveTab("questions"), () => setCurrentTab("2"), "Next", false)}

                </div>
            }

            {/* Organization Info Section */}
            {currentTab === "2" &&
                <>
                    <p className="text-2xl text-center text-text-1 mb-4">Organization Information</p>
                    {(Object.keys(organizationInfo) as Array<keyof typeof organizationInfo>).map((key, index) => (
                        <InputField
                            key={index}
                            label={organizationFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                            value={organizationInfo[key]}
                            type="text"
                            onChange={(e: any) => handleInputChange(e, "organization")}
                            name={key}
                            labelPosition="left"
                            required={true}
                        />
                    ))}

                    {soleProprietorship && <InputField label="Tax ID" labelPosition="left" name={"taxId"}
                                                       onChange={(e: any) => {
                                                           setTaxId(e.target.value);
                                                       }} value={taxId || ""} required={true}/>
                    }

                    {handleButton(() => setCurrentTab("1"), (e: any) => {
                        e.preventDefault();
                        if (validateTaxIdOrEmpId(organizationInfo["employerId"], "Employer ID") && (!soleProprietorship || validateTaxIdOrEmpId(taxId, "Tax ID"))) {
                            setCurrentTab("3");
                        }
                    }, "Continue", soleProprietorship ? {
                        ...organizationInfo,
                        taxId: taxId
                    } : organizationInfo)}
                </>
            }

            {/* Contact Info Section */}
            {currentTab === "3" &&
                <>
                    <p className="text-2xl text-center text-text-1 mb-4">Organization Contact Information</p>

                    <div className={"min-h-[315px]"}>
                        {(Object.keys(contactFieldLabels) as Array<keyof typeof contactFieldLabels>).map((key, index) => (
                            <InputField
                                key={index}
                                label={contactFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                                value={contactInfo[key]}
                                onChange={(e: any) => handleInputChange(e, "contact")}
                                name={key}
                                labelPosition="left"
                                required={true}
                            />
                        ))}

                        <InputField
                            name="contactPhoneNumber"
                            label="Phone"
                            value={contactInfo['contactPhoneNumber']}
                            // @ts-ignore
                            onChange={function (value?: E164Number | string): void {
                                // @ts-ignore
                                setContactInfo({...contactInfo, ['contactPhoneNumber']: value})
                            }}
                            type="phoneNumber"
                            required
                            placeholder="Enter Phone Number..."
                        />
                    </div>

                    {handleButton(() => setCurrentTab("2"), () => setCurrentTab("4"), "Continue", contactInfo)}
                </>
            }

            {/* Address Info Section */}
            {currentTab === "4" && <>
                <p className="text-2xl text-center text-text-1 mb-4">Organization Address Information</p>

                <div className={"min-h-[315px]"}>
                    <SelectField
                        label={"Country"}
                        value={countryId}
                        placeholder="Select Country..."
                        options={createOptionListForSelectTag(allCountriesApiResult?.data, "name", "id")}
                        onChange={(selectedOption) => {
                            setCountryId(selectedOption)
                        }}
                        required={true}
                    />

                    <SelectField
                        label={"State"}
                        value={stateId}
                        placeholder="Select State..."
                        options={createOptionListForSelectTag(allStatesApiResult?.data, "name", "id")}
                        onChange={(selectedOption) => {
                            setStateId(selectedOption)
                        }}
                        required={true}
                    />

                    {(Object.keys(addressInfo) as Array<keyof typeof addressInfo>).map((key, index) => (
                        <InputField
                            key={index}
                            label={addressFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                            value={addressInfo[key]}
                            type="text"
                            onChange={(e: any) => handleInputChange(e, "address")}
                            name={key}
                            labelPosition="left"
                            required={true}
                        />
                    ))}
                </div>
                {handleButton(() => setCurrentTab("3"), (e: any) => {
                    e.preventDefault();
                    if (validateZipCode(addressInfo["zip"])) {
                        handleSubmit(e).then();
                    }
                }, "Submit", {
                    ...addressInfo,
                    stateId: stateId?.value || null
                })}
            </>
            }
        </form>
    );
};

export default SellerForm;
