import InputField from "../../../../components/common/form/InputField";
import React, {FC, ChangeEvent, useState} from "react";
import {createOptionListForSelectTag} from "../../../../helpers/utils/Utils";
import SelectField from "../../../../components/common/form/SelectField";
import {useGetEnumsQuery} from "../../../../app/globalApi";
import {E164Number} from "libphonenumber-js/types";

interface props {
    title: string,
    submitBtnText: string
    setUnsavedPersonInfo: any,
    setCreateOrderData: React.Dispatch<React.SetStateAction<any>>,
    obj?: any,
    index?: any
}

const FincenPartyForm: FC<props> = ({
                                        title,
                                        submitBtnText,
                                        setUnsavedPersonInfo,
                                        setCreateOrderData,
                                        obj,
                                        index
                                    }) => {

    const [isChanged, setIsChanged] = useState<boolean>(false);
    const {data: enums} = useGetEnumsQuery("");

    const [partyObj, setPartyObj] = useState<any>(obj ??
        {
            type: title.toUpperCase(),
            name: "",
            contacts: [
                {
                    type: "",
                    name: "",
                    emailAddress: "",
                    phoneNumber: ""
                }
            ]
        }
    );

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: any }) => {
        const {name, value} = "target" in e ? e.target : e;

        setPartyObj((prev: any) => ({
            ...prev,
            contacts: prev.contacts.map((contact: any, index: number) =>
                index === 0 ? {...contact, [name]: value} : contact
            )
        }));

        setIsChanged(true);
        setUnsavedPersonInfo(true);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (submitBtnText === "Add") {

            setCreateOrderData((prev: any) => ({
                ...prev,
                parties: [...(prev?.parties || []), partyObj],
            }));

            setPartyObj({
                type: title.toUpperCase(),
                name: "",
                contacts: [
                    {
                        type: "",
                        name: "",
                        emailAddress: "",
                        phoneNumber: ""
                    }
                ]
            })

        } else {
            setCreateOrderData((prev: any) => ({
                ...prev,
                parties: prev.parties.map((contact: any, i: number) =>
                    i === index ? partyObj : contact
                )
            }));
        }

        setIsChanged(false);
        setUnsavedPersonInfo(false);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div
                    className={`col-span-12 p-4 rounded-sm border border-surface-6 relative ${index === undefined && "mt-6"}`}>
                    {index === undefined &&
                        <label className="absolute bg-surface-2 -top-3.5 pr-1 text-text-1">{title}</label>}

                    <InputField
                        required={true}
                        label='Legal Name' value={partyObj?.name} name={"name"}
                        onChange={(e: any) => {
                            setPartyObj((prevParty: any) => ({
                                ...prevParty,
                                name: e.target.value,
                            }))
                            setIsChanged(true);
                        }}
                    />

                    <div className={`col-span-12 md:grid md:grid-cols-12 gap-3 mt-3`}>

                        <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                            <h2 className="text-text-1">Contact</h2>
                        </div>

                        <div className={`col-span-9`}>
                            <div
                                className={`border border-surface-6 p-3 bg-surface-2 rounded-md"}`}>

                                <InputField
                                    required={true}
                                    label='Legal Name'
                                    name={"name"}
                                    value={partyObj?.contacts[0]?.name || ""}
                                    onChange={handleOnChange}
                                />

                                <InputField
                                    required={true}
                                    label='Email'
                                    type={"email"}
                                    value={partyObj?.contacts[0]?.emailAddress || ""}
                                    name={"emailAddress"}
                                    onChange={handleOnChange}/>

                                <InputField
                                    name="phoneNumber"
                                    label="Phone"
                                    value={partyObj?.contacts[0]?.phoneNumber}
                                    // @ts-ignore
                                    onChange={function (value?: E164Number | undefined): void {
                                        setPartyObj((prev: any) => ({
                                            ...prev,
                                            contacts: prev.contacts.map((contact: any, index: number) =>
                                                index === 0 ? {...contact, ['phoneNumber']: value} : contact
                                            )
                                        }));
                                    }}
                                    type="phoneNumber"
                                    required
                                    placeholder="Enter Phone Number..."
                                />

                                <SelectField
                                    placeholder='Select Contact Type...'
                                    value={partyObj?.contacts[0]?.type || null}
                                    options={createOptionListForSelectTag(enums?.["MemberContactType"], "displayName", "constant")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "type", value: selectedOption})
                                    }}
                                    required={true}
                                    label={"Contact Type"}
                                />

                            </div>
                        </div>
                    </div>

                    {isChanged && (
                        <div className="grid grid-cols-12 gap-2 mt-3">
                            <div className="col-span-12 flex items-end justify-end">
                                <button
                                    type="button"
                                    className="bg-surface-3 rounded-md text-text-1 text-xs px-2 py-1 mr-3 hover:bg-highlight-7"
                                    onClick={(e) => {
                                        if (submitBtnText === "Add") {
                                            setPartyObj({
                                                type: title.toUpperCase(),
                                                name: "",
                                                contacts: [
                                                    {
                                                        type: "REPRESENTATIVE",
                                                        name: "",
                                                        emailAddress: "",
                                                        phoneNumber: ""
                                                    }
                                                ]
                                            });
                                        } else {
                                            setPartyObj(obj);
                                        }
                                        setIsChanged(false);
                                        setUnsavedPersonInfo(false);
                                    }}
                                >
                                    Undo
                                </button>
                                <button
                                    type="submit"
                                    className="bg-highlight-2 rounded-md text-text-1 text-xs px-2 py-1"
                                >
                                    {submitBtnText}
                                </button>
                            </div>
                        </div>
                    )}

                </div>
            </form>
        </>
    )
}
export default FincenPartyForm;