import React, {FormEvent, useEffect, useState} from "react";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import {DataTable} from "../../../components/common/datatable/DataTable";
import IconButton from "../../../components/common/form/IconButton";
import {FaPlus, FaTrash} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {
    useDeletePlatformPricingByIdMutation,
    useLazyExportPlatformPricingDataQuery,
    useSearchPlatformPricingQuery
} from "./platformPricingApi";
import ModalButton from "../../../components/common/form/ModalButton";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {
    SearchSelectWithEnum,
    SearchSelectWithList
} from "../../../components/common/datatable/filter/SearchSelectWrapper";
import {useGetAllTenantsQuery} from "../../user/customer/tenantApi";
import {useGetAllRegularCustomersQuery} from "../../user/customer/customerApi";
import {useGetAllStatesByCountryIdQuery, useGetAllStatesQuery} from "../../data/states/statesApi";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";

const PlatformPricing = () => {

    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Platform Pricing", url: ""},
    ]);

    useEffect(() => {
        document.title = "Platform Pricing";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Platform Pricing");
    const {data: enums} = useGetEnumsQuery("");
    const tenantQueryApi = useGetAllTenantsQuery("");
    const allRegularCustomersResult = useGetAllRegularCustomersQuery("");
    const statesQueryApi = useGetAllStatesByCountryIdQuery("236b617cc-105e-4f73-a91c-e2162beac864");;
    const [deletePricingApi, {isLoading: deletePricingApiResponse}] = useDeletePlatformPricingByIdMutation();
    const [pricingId, setPricingId] = useState("");
    const [triggerExportPlatformPricingDataQuery] = useLazyExportPlatformPricingDataQuery();

    const handleDeletePricingSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (pricingId) {
            return deletePricingApi(pricingId);
        }
    }


    const columns: DataTableColumn[] = [
        {
            label: "Description",
            accessor: "description",
            cell: (props: any) => {
                let path = `/accounting/platform-pricing/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["PLPRC-R", "PLPRC-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Description..."}
                                                     name={"description"}
                                                     accessor={"description"}/>,
                overrideFilterLabel: "Name"
            }]
        },
        {
            label: "Product",
            accessor: "productType",
            cell: (props: any) => getEnumDisplayNameByConstant(enums?.["ProductType"], props.value),
            filterOptions: [{
                filterComponent: SearchSelectWithEnum(enums?.["ProductType"], "Select Product Type...", false, "productType", null),
            }]
        },
        {
            label: "Tenant",
            accessor: "tenantName",
            canSort: false,
            visibility: {
                disallowedCustomerLevels: ["CUSTOMER", "TENANT"]
            },
            filterOptions: [{
                filterComponent: tenantQueryApi?.data ? SearchSelectWithList(tenantQueryApi?.data, (tenant: any) => `${tenant.name}`, "id", "Select Tenant...", false, "tenantId") : [],
                overrideFilterAccessor: "tenantId"
            }]
        },
        {
            label: "Customer",
            accessor: "customerName",
            overrideSortColumn: "customerId",
            filterOptions: [{
                dependentColumns: ["Branch(es)"],
                filterComponent: allRegularCustomersResult?.data ? SearchSelectWithList(allRegularCustomersResult?.data, (customer: any) => `${customer.customerName} (${customer.customerNumber})`, "id", "Select Customer...", false, "customerId") : [],
                overrideFilterAccessor: "customerId",
            }],
            canSort: false,
        },
        {
            label: "Branch",
            accessor: "branchName",
            canSort: false
        },

        {
            label: "State",
            accessor: "name",
            canSort: false,
            filterOptions: [{
                filterComponent: statesQueryApi?.data ? SearchSelectWithList(statesQueryApi?.data, (state: any) => `${state.name}`, "id", "Select Select...", false, "stateId") : [],
            }]
        },

        {
            label: "County",
            accessor: "countyName",
            canSort: false
        },
        {
            label: "MSA",
            accessor: "metroAreaName",
            canSort: false
        },
        {
            label: "Display Price",
            accessor: "displayPrice",
        },
        {
            label: "Display Parcel",
            accessor: "displayPricePerParcel",
        },
        {
            label: "Price",
            accessor: "price",
        },
        {
            label: "Parcel",
            accessor: "pricePerParcel",
        },
        {
            label: "Action",
            visibility: {
                canColumnShow: hasPermission(["PLPRC-D"]),
                showByDefault: hasPermission(["PLPRC-D"]),
                hasShowSetting: false,
            },
            accessor: "systemGenerated",
            canSort: false,
            cell: (props: any) => {
                return <>
                    {!props.row.original.systemGenerated ? <div className="ml-4">
                        <ModalButton
                            triggerButton={
                                <FaTrash
                                    className={"cursor-pointer text-highlight-3 text-lg hover:text-highlight-7"}
                                    title={"Delete Platform Pricing"}/>
                            }
                            onOpen={() => {
                                setPricingId(props.row.original.id)
                            }}
                            modal={
                                <ConfirmationModal
                                    show={true}
                                    setShow={() => {
                                    }}
                                    title={"Delete Platform Pricing"}
                                    bodyMessage={"Are you sure you want to delete this platform pricing ?"}
                                    onSubmit={handleDeletePricingSubmit}
                                    isLoading={deletePricingApiResponse}
                                />
                            }
                        />
                    </div> : <></>}
                </>
            }
        }

    ]

    return (
        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchPlatformPricingQuery,
                    exportAction: triggerExportPlatformPricingDataQuery
                }}
                userSettingGroup={"Platform Pricing"}
                buttonOptions={{
                    addButton:
                        {
                            button: <IconButton
                                icon={<FaPlus/>}
                                hasBackground={true}
                                onClick={() => {
                                    navigate("/accounting/platform-pricing/create")
                                }}
                                title={"Create Platform Pricing"}
                            />,
                            permissions: ["PLPRC-C"]
                        },
                }}
                basePermission={"PLPRC"}
            />


        </div>
    )
}
export default PlatformPricing;