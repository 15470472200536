import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader,
    handleQueryError,
} from "../../../helpers/utils/RtkQueryUtils";

export const countriesApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getAllCountries: build.query({
            query: () => {
                return {
                    url: `/countries`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    }),
});


export const {
   useGetAllCountriesQuery
} = countriesApi;