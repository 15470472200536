import {FC, useEffect, useState} from "react"
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {DataTable} from "../../../components/common/datatable/DataTable";
import IconButton from "../../../components/common/form/IconButton";
import {FaPlus} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {useLazyExportMetroAreaDataQuery, useSearchMetroAreasQuery} from "./metroAreasApi";
import {SearchSelectWithList} from "../../../components/common/datatable/filter/SearchSelectWrapper";
import {useGetAllStatesByCountryIdQuery, useGetAllStatesQuery} from "../states/statesApi";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";


const MetroAreas: FC = () => {

    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Metro Areas", url: ""},

    ])

    useEffect(() => {
        document.title = "Metro Areas";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Metro Area");
    const stateQueryApi = useGetAllStatesByCountryIdQuery("236b617cc-105e-4f73-a91c-e2162beac864");;
    const [triggerExportMetroAreaDataQuery] = useLazyExportMetroAreaDataQuery();

    const columns: DataTableColumn[] = [
        {
            label: "Name",
            accessor: "name",
            cell: (props: any) => {
                let path = `/metro-areas/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["METR-R", "METR-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Name..."} name={"name"}
                                                     accessor={"name"}/>,
                overrideFilterLabel: "Name"
            }]
        },
        {
            label: "State", accessor: "state.abbreviation",
            filterOptions: [{
                dependentColumns: ["Counties"],
                filterComponent: SearchSelectWithList(stateQueryApi?.data, "name", "name", "Select State...", false, "name"),
                overrideFilterAccessor: "stateName"
            }],
        },
        {
            label: "Counties", accessor: "counties",
            canSort: false,
            cell: (props: any) => {
                let content = props.row.original.counties.map((curCounty: any) => {
                    return curCounty.name;
                }).join(", ");
                return <><span className="text_wrap_msa_grid" title={content}>{content}</span></>
            }
        },

    ]

    return (

        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchMetroAreasQuery,
                    exportAction: triggerExportMetroAreaDataQuery
                }}
                userSettingGroup={"County"}
                defaultSortOptions={{
                    accessor: "name",
                    direction: "asc"
                }}
                buttonOptions={{
                    addButton:
                        {
                            button: <IconButton
                                icon={<FaPlus/>}
                                hasBackground={true}
                                onClick={() => {
                                    navigate("/metro-areas/create")
                                }}
                                title={"Create Metro Area"}
                            />,
                            permissions: ["METR-C"]
                        },
                }}
                basePermission={"METR"}
            />

        </div>

    )
}
export default MetroAreas