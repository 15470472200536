import {strApi} from "../../app/strApi";
import {handleQueryErrorAndSuccess} from "../../helpers/utils/RtkQueryUtils";
import {setCookie, showToast} from "../../helpers/utils/Utils";

export const fincenAuthenticateApi = strApi.injectEndpoints({

    endpoints: (build) => ({

        requestFincenMfaChallenge: build.mutation({
            query: (contactId) => {
                return {
                    url: `/fincen/mfa/challenge?contactId=${contactId}`,
                    method: 'POST'
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "", "Your OTP has been sent to your verified phone number");
            },
        }),

        verifyFincenOtp: build.mutation({
            query: ({challengeId, otp}) => {
                return {
                    url: `/fincen/mfa/verify?challengeId=${challengeId}&otp=${otp}`,
                    method: 'POST'
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Verified", "Otp");
            },
        }),

        authenticateFincenRequest: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/fincen/authenticate`,
                    method: 'POST',
                    body: requestBody
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                queryFulfilled.then(res => {
                    setCookie("authToken", res.data.token);
                }).catch(error => {
                    showToast(error?.error?.data?.message, "error");
                })
            },
        }),

    })
})

export const {
    useRequestFincenMfaChallengeMutation,
    useVerifyFincenOtpMutation,
    useAuthenticateFincenRequestMutation
} = fincenAuthenticateApi