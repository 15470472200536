import InputField from "../../../components/common/form/InputField";
import React, {FC, useState} from "react";
import {useGetEnumsQuery} from "../../../app/globalApi";
import SelectField from "../../../components/common/form/SelectField";
import {createOptionListForSelectTag, isNullOrEmpty, partyFieldLabels} from "../../../helpers/utils/Utils";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {useParams} from "react-router-dom";
import {useGetAllStatesByCountryIdQuery} from "../../data/states/statesApi";
import {useCreateMemberMutation} from "../memberApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useGetAllCountriesQuery} from "../../data/countries/countriesApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {validateTaxIdOrEmpId, validateZipCode} from "../../../helpers/utils/FincenValidation";

interface props {
    setActiveTab: any,
    isPrimaryContact: boolean,
    partyId: string,
    contactReferenceId: string
    setMemberDetailData: any
}

const MemberForm: FC<props> = ({setActiveTab, isPrimaryContact, partyId, contactReferenceId, setMemberDetailData}) => {

    const {contactId} = useParams();
    const [stateId, setStateId] = useState<any>(null);
    const [country, setCountry] = useState<any>({
        value: "236b617cc-105e-4f73-a91c-e2162beac864",
        label: "United States of America"
    });
    const [idIssuer, setIdIssuer] = useState<any>(null);

    const allStatesApiResult = useGetAllStatesByCountryIdQuery(country?.value ?? skipToken);
    const allCountriesApiResult = useGetAllCountriesQuery("");
    const {data: enums} = useGetEnumsQuery("");
    const [idType, setIdType] = useState<any>();
    const [currentTab, setCurrentTab] = useState("1");
    const [createMemberApi, createMemberApiResponse] = useCreateMemberMutation();

    const [personalInfoData, setPersonalInfoFormData] = useState({
        legalFirstName: "",
        legalMiddleInitial: "",
        legalLastName: "",
        dateOfBirth: "",
        occupation: "",
        taxId: ""
    });

    const [identificationInfoData, setIdentificationInfoData] = useState({
        idNumber: ""
    });

    const [addressInfoData, setAddressInfoData] = useState({
        streetAddress: "",
        city: "",
        zip: "",
    });

    const isNextButtonEnabled = (obj: {}) => {
        let list = Object.keys(obj) as Array<keyof typeof obj>;
        // @ts-ignore
        return list?.every(cur => !isNullOrEmpty(obj?.[cur]))
    }

    function handleButton(backAction: any, continueAction: any, buttonLabel: string, obj: {}) {
        return <>
            <ButtonTray
                buttons={[
                    {
                        buttonProps: {
                            btnText: "Back",
                            onClick: backAction,
                            type: "close",
                        },
                        buttonType: "button"
                    },
                    {
                        buttonProps: {
                            btnText: buttonLabel,
                            type: "submit",
                            isLoading: createMemberApiResponse.isLoading,
                            isVisible: true,
                            onClick: continueAction,
                            isDisabled: !isNextButtonEnabled(obj)
                        },
                        buttonType: "button",
                    }
                ]}
                align="end"
                gap={2}
                addTopLine={false}
            />

        </>
    }

    const handlePersonalInfoChange = (e: any) => {
        const {name, value} = e.target;
        let updatedValue = value;

        if (name === "legalMiddleInitial") {
            // Allow only a single alphabet (A-Z or a-z)
            updatedValue = value.slice(0, 1).replace(/[^A-Za-z]/g, "");
        }
        setPersonalInfoFormData({...personalInfoData, [name]: updatedValue});
    };


    const handleIdentificationInfoChange = (e: any) => {
        const {name, value} = e.target;
        setIdentificationInfoData({...identificationInfoData, [name]: value});
    };

    const handleAddressInfoChange = (e: any) => {
        const {name, value} = e.target;
        setAddressInfoData({...addressInfoData, [name]: value});
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        let obj = {
            ...personalInfoData,
            ...identificationInfoData,
            ...addressInfoData,
            country: country?.value,
            partyId: partyId,
            idIssuer: idIssuer,
            fincenContactId: isPrimaryContact ? contactReferenceId : contactId,
            stateId: stateId?.value,
            idType: idType?.value
        };
        await handleRTKQuery(
            async () => {
                return await createMemberApi({
                    requestBody: obj
                }).unwrap(); // Ensure `unwrap()` is used to handle errors properly
            },
            (isSuccess: boolean) => {
                if (isSuccess) {
                    setMemberDetailData(obj);
                    if (isPrimaryContact) {
                        setActiveTab(`verify-contact`);
                    } else {
                        setActiveTab(`sign`);
                    }
                }
            }
        );
    };

    return (
        <form onSubmit={handleSubmit}>
            {
                currentTab === "1" && <>
                    <p className={"text-2xl text-center text-text-1 mb-4"}>Personal Information</p>
                    <div className={"min-h-[315px]"}>

                        {(Object.keys(personalInfoData) as Array<keyof typeof personalInfoData>)
                            .map((key, index) => (
                                <InputField
                                    key={index}
                                    label={partyFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                                    value={personalInfoData[key]}
                                    type={key === "dateOfBirth" ? "date" : "text"}
                                    onChange={handlePersonalInfoChange}
                                    name={key}
                                    labelPosition="left"
                                    required={true}
                                />
                            ))
                        }

                    </div>
                    {handleButton(() => setActiveTab("verify-code"), (e: any) => {
                        e.preventDefault();
                        if (validateTaxIdOrEmpId(personalInfoData["taxId"], "Tax ID")) {
                            setCurrentTab("2");
                        }
                    }, "Continue", personalInfoData)}
                </>
            }

            {
                currentTab === "2" && <>
                    <p className={"text-2xl text-center text-text-1 mb-4"}>Address Information</p>
                    <div className={"min-h-[315px]"}>
                        <SelectField
                            label={"Country"}
                            value={country}
                            placeholder="Select Country..."
                            options={createOptionListForSelectTag(allCountriesApiResult?.data, "name", "id")}
                            onChange={(selectedOption) => {
                                setCountry(selectedOption)
                            }}
                            required={true}
                        />

                        <SelectField
                            label={"State"}
                            value={stateId}
                            placeholder="Select State..."
                            options={createOptionListForSelectTag(allStatesApiResult?.data, "name", "id")}
                            onChange={(selectedOption) => {
                                setStateId(selectedOption)
                            }}
                            required={true}
                        />

                        {(Object.keys(addressInfoData) as Array<keyof typeof addressInfoData>)
                            .map((key, index) => (
                                <InputField
                                    key={index}
                                    label={partyFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                                    value={addressInfoData[key]}
                                    type={"text"}
                                    onChange={handleAddressInfoChange}
                                    name={key}
                                    labelPosition="left"
                                    required={true}
                                />
                            ))
                        }
                    </div>
                    {handleButton(() => setCurrentTab("1"), (e: any) => {
                        e.preventDefault();
                        if (validateZipCode(addressInfoData["zip"])) {
                            setCurrentTab("3");
                        }
                    }, "Continue", addressInfoData)}
                </>
            }

            {
                currentTab === "3" &&
                <>
                    <p className={"text-2xl text-center text-text-1 mb-4"}>Identification Information</p>
                    <div className={"min-h-[315px]"}>
                        <SelectField
                            placeholder='Select ID Type...'
                            value={idType}
                            options={createOptionListForSelectTag(enums?.["MemberIdType"], "displayName", "constant")}
                            onChange={(selectedOption) => {
                                setIdType(selectedOption);
                            }}
                            label={"ID Type"}
                            isMulti={false}
                            required={true}
                        />

                        {(Object.keys(identificationInfoData) as Array<keyof typeof identificationInfoData>)
                            .map((key, index) => (
                                <InputField
                                    key={index}
                                    label={partyFieldLabels[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c: any) => c.toUpperCase())}
                                    value={identificationInfoData[key]}
                                    type={"text"}
                                    onChange={handleIdentificationInfoChange}
                                    name={key}
                                    labelPosition="left"
                                    required={true}
                                />
                            ))
                        }

                        {(idType?.value === "ALIEN_REGISTRATION" || idType?.value === "OTHER") &&
                            <InputField label="ID Issuer" labelPosition="left" name={"idIssuer"}
                                        onChange={(e: any) => {
                                            setIdIssuer(e.target.value);
                                        }} value={idIssuer || ""} required={true}/>}
                    </div>
                    {handleButton(() => setCurrentTab("2"), handleSubmit, "Submit", identificationInfoData)}
                </>
            }

        </form>
    );
};

export default MemberForm;
