// @ts-ignore
import logo from "../../images/logo.svg";
import React, {FC} from "react";
import {FaArrowLeft} from "react-icons/fa";
import IconButton from "../../components/common/form/IconButton";
import {useNavigate} from "react-router-dom";

interface props {
    productByIdResponse: any,
    showOrderPageButton: boolean
}

const Header: FC<props> = ({productByIdResponse, showOrderPageButton}) => {

    const navigate = useNavigate();

    return (
        <header
            className="top-0 sm:block py-1 lg:flex md:block z-50 items-center fixed w-full header bg-surface-1 px-2 border-b-2 border-highlight-2">
            <div className="sm:flex justify-between lg:flex md:flex items-center w-full">
                {/* Logo Section */}
                <a className="flex items-center space-x-2" href="/">
                    <img src={logo} alt="Company Logo" className="h-10"/>
                </a>

                {/* Back Button - Aligned to the Right */}
                {showOrderPageButton && <IconButton
                    icon={<FaArrowLeft/>}
                    label="Back To Order"
                    title="Back To Order"
                    className="ml-auto" // Pushes button to the right
                    onClick={() => {
                        navigate("/orders/" + productByIdResponse?.data?.orderId + "#" + productByIdResponse?.data?.id);
                    }}
                />}
            </div>
        </header>

    )
}

export default Header;