import React, {useState} from "react";
import InputField from "../../components/common/form/InputField";
import {MdDelete} from "react-icons/md";
import {IoIosArrowForward} from "react-icons/io";
import AttestModal from "./AttestModal";
import logo from "../../images/logo.svg";

const IndividualsTransactionFormPage = () => {
    const [individuals, setIndividuals] = useState([]);
    const [count, setCount] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);
    const handleCloseModal = () => setModalOpen(false);
    const handleOpenModal = () => setModalOpen(true);

    // State for individual form values
    const [individualForm, setIndividualForm] = useState({
        legalFirstName: "",
        middleName: "",
        legalLastName: "",
        dob: "",
        taxId: "",
        streetAddress: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        occupation: "",
        idType: "",
        idNumber: "",
        idIssuer: ""
    });

    // Function to add individual to the list
    const addIndividual = () => {
        setIndividuals([
            ...individuals,
            {...individualForm, id: Date.now(), isCollapsed: true}
        ]);
        setIndividualForm({
            legalFirstName: "",
            middleName: "",
            legalLastName: "",
            dob: "",
            taxId: "",
            streetAddress: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            occupation: "",
            idType: "",
            idNumber: "",
            idIssuer: ""
        });
    };

    // Toggle collapse for individual
    const toggleIndividualCollapse = (id) => {
        setIndividuals(individuals.map(individual => individual.id === id ? {
            ...individual,
            isCollapsed: !individual.isCollapsed
        } : individual));
    };

    // Function to delete individual
    const deleteIndividual = (id) => {
        setIndividuals(individuals.filter(individual => individual.id !== id));
    };

    // Update handler function
    const updateIndividual = (id) => {
        const updatedData = individualForm[id];
        setIndividuals((prev) =>
            prev.map((indiv) => (indiv.id === id ? {...indiv, ...updatedData} : indiv))
        );
        setIndividualForm((prev) => ({...prev, [id]: undefined})); // Clear temporary form data
    };

    const labelMap = {
        firstName: "Legal First Name",
        middleName: "Legal Middle Name",
        lastName: "Legal Last Name",
        dob: "Date of Birth",
        taxId: "Tax ID",
        streetAddress: "Street Address",
        city: "City",
        state: "State",
        zip: "ZIP Code",
        country: "Country",
        occupation: "Occupation",
        idType: "ID Type",
        idNumber: "ID Number",
        idIssuer: "ID Issuer",
        // Add more mappings as needed
    };

    return (
        <>
            <header
                className="sm:block py-1 lg:flex md:block z-50 items-center fixed w-full header bg-surface-1 px-2 border-b-2 border-highlight-2">
                <div className="sm:flex justify-between lg:block md:flex flex items-center"><a
                    className="flex items-center space-x-2" href="/">
                    <img src={logo} alt="Company Logo" className="h-10"/></a>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512"
                         className="text-text-1 lg:hidden sm:block md:block" height="1em" width="1em"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path>
                    </svg>
                </div>
            </header>
            <div className="flex items-center justify-center px-1 py-2 pt-14">
                <div className="flex justify-center">
                    <div className="bg-surface-2 w-full p-6 rounded-md">
                        <div className="col-span-12 grid grid-cols-12 gap-3">
                            <div className="col-span-7 sm:mt-2 lg:mt-0 flex items-center">
                                <div className="flex items-center">
                                    <span
                                        className="text-text-1">*How many members are involved in this transaction?</span>
                                </div>
                            </div>
                            <div className="relative col-span-5">
                                <input type="number"
                                       required
                                       name="count"
                                       onChange={(e : any) => setCount(e.target.value)}
                                       className="placeholder:text-text-2 rounded-md border p-2 text-text-1 bg-surface-2 w-full border-surface-4"
                                       value={count}/>
                            </div>
                        </div>

                        {/* Individuals Form */}
                        <div className="col-span-12 mt-4 p-4 rounded-sm border border-surface-6 relative">
                            <label className="text-text-1 absolute bg-surface-2 -top-3.5 pr-1">Individuals</label>
                            {Object.keys(individualForm).filter((key) => !/^\d+$/.test(key)).map((key, index) => (
                                <InputField
                                    key={index}
                                    label={labelMap[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c) => c.toUpperCase())}
                                    value={individualForm[key]}
                                    type={key === 'dob' ? 'date' : 'text'}
                                    onChange={(e : any) => setIndividualForm({...individualForm, [key]: e.target.value})}
                                    name={key}
                                    labelPosition={"left"}
                                    required={true}
                                />
                            ))}
                            <div className="grid grid-cols-12 gap-2 mt-2">
                                <div className="col-span-12 flex items-end justify-end">
                                    <button type="button"
                                            className={`bg-highlight-2 rounded-md text-text-1 text-xs px-2 py-1 mr-3 hover:bg-highlight-7 ${!individualForm["legalFirstName"] ? 'cursor-not-allowed' : ''}`}
                                            disabled={!individualForm["legalFirstName"]}
                                            onClick={addIndividual}>Add
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Render Added Individuals */}
                        <div>
                            {individuals.map((individual) => (
                                <>
                                    <div
                                        className="flex items-center justify-between bg-highlight-2 text-text-1 p-2 mt-2 cursor-pointer">
                                        <span>{individual.legalFirstName} {' ' + individual.middleName} {' ' + individual.legalLastName}</span>


                                        <div className="flex gap-3">
                                            <IoIosArrowForward
                                                className={"text-text-1  text-lg cursor-pointer " + (!individual.isCollapsed ? "rotate-90" : "")}
                                                onClick={() => toggleIndividualCollapse(individual.id)}/>

                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent toggle on delete click
                                                    deleteIndividual(individual.id);
                                                }}
                                                className="text-white"
                                            >
                                                <MdDelete
                                                    className={"text-text-1 text-xl cursor-pointer"}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    {!individual.isCollapsed && (
                                        <div key={individual.id}
                                             className="border p-3 mb-2 border-surface-6">

                                            <div>
                                                <InputField
                                                    label="Legal First Name"
                                                    value={individualForm[individual.id]?.legalFirstName || individual.legalFirstName}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                legalFirstName: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="legalFirstName"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="Legal Middle Name"
                                                    value={individualForm[individual.id]?.middleName || individual.middleName}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                middleName: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="middleName"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="Legal Last Name"
                                                    value={individualForm[individual.id]?.legalLastName || individual.legalLastName}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                legalLastName: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="legalLastName"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="Date of Birth"
                                                    value={individualForm[individual.id]?.dob || individual.dob}
                                                    type="date"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                dob: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="dob"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="Tax ID"
                                                    value={individualForm[individual.id]?.taxId || individual.taxId}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                taxId: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="taxId"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="Street Address"
                                                    value={individualForm[individual.id]?.streetAddress || individual.streetAddress}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                streetAddress: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="streetAddress"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="City"
                                                    value={individualForm[individual.id]?.city || individual.city}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                city: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="city"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="State"
                                                    value={individualForm[individual.id]?.state || individual.state}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                state: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="state"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="Zip"
                                                    value={individualForm[individual.id]?.zip || individual.zip}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                zip: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="zip"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="Country"
                                                    value={individualForm[individual.id]?.country || individual.country}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                country: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="country"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="Occupation"
                                                    value={individualForm[individual.id]?.occupation || individual.occupation}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                occupation: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="occupation"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="ID Type"
                                                    value={individualForm[individual.id]?.idType || individual.idType}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                idType: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="idType"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="ID Number"
                                                    value={individualForm[individual.id]?.idNumber || individual.idNumber}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                idNumber: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="idNumber"
                                                    labelPosition="left"
                                                    required={true}
                                                />
                                                <InputField
                                                    label="ID Issuer"
                                                    value={individualForm[individual.id]?.idIssuer || individual.idIssuer}
                                                    type="text"
                                                    onChange={(e : any) =>
                                                        setIndividualForm({
                                                            ...individualForm,
                                                            [individual.id]: {
                                                                ...individualForm[individual.id],
                                                                idIssuer: e.target.value
                                                            }
                                                        })
                                                    }
                                                    name="idIssuer"
                                                    labelPosition="left"
                                                    required={true}
                                                />

                                                <div className="grid grid-cols-12 gap-2 mt-2">
                                                    <div className="col-span-12 flex items-end justify-end">
                                                        <button type="button"
                                                                className={`bg-highlight-2 rounded-md text-text-1 text-xs px-2 py-1 mr-3 hover:bg-highlight-7 ${!individual.legalFirstName ? 'cursor-not-allowed' : ''}`}
                                                                disabled={!individual.legalFirstName}
                                                                onClick={() => updateIndividual(individual.id)}>Update
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))}
                        </div>

                        <div className="flex justify-end mt-5">
                            <button type="button"
                                    className={`inline-flex px-2 py-1 items-center rounded bg-highlight-3 text-text-1 focus:outline-none focus:shadow-outline hover:transition-all hover:duration-200 hover:ease ${individuals.length === 0 ? 'cursor-not-allowed' : ''}`}
                                    onClick={handleOpenModal}
                                    disabled={individuals.length === 0}>Submit
                            </button>
                        </div>

                    </div>
                </div>

                <AttestModal isOpen={isModalOpen} onClose={handleCloseModal}
                             body={"This transaction requires reporting to FinCen. Please attest to the truth of your\n" +
                                 "                    statement by clicking the button below."}/>

            </div>
        </>
    );
};

export default IndividualsTransactionFormPage;
