import {ModalProps} from "../../../../helpers/constants";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import Modal from "../../../../components/common/form/Modal";
import Form from "../../../../components/common/form/Form";
import InputField from "../../../../components/common/form/InputField";
import {OptionType} from "../../../../helpers/utils/StyleUtils";
import {createOptionListForSelectTag} from "../../../../helpers/utils/Utils";
import SelectField from "../../../../components/common/form/SelectField";
import {useGetAllStatesByCountryIdQuery, useGetAllStatesQuery} from "../../../data/states/statesApi";
import {handleRTKQuery} from "../../../../helpers/utils/RtkQueryUtils";
import {useCreateRegionMutation, useGetRegionByIdQuery, useUpdateRegionMutation} from "../regionApi";
import {skipToken} from "@reduxjs/toolkit/query";
import ButtonTray from "../../../../components/common/form/ButtonTray";

interface props extends ModalProps {
    customerId: string | undefined,
    title: string,
    regionId?: string,
    btnText: string,
    isEditable?: boolean
}

const AddUpdateRegionModal: FC<props> = ({customerId, title, regionId, show, setShow, btnText, isEditable = true}) => {

    const statesApiResult = useGetAllStatesByCountryIdQuery("236b617cc-105e-4f73-a91c-e2162beac864");;
    const [regionData, setRegionData] = useState<any>({});
    const [createRegion, createRegionApiResponse] = useCreateRegionMutation();
    const [updateRegion, updateRegionApiResponse] = useUpdateRegionMutation();
    const regionByIdResponse = useGetRegionByIdQuery(regionId ?? skipToken);

    useEffect(() => {
        let regionObj = regionByIdResponse?.data;
        if (regionObj) {
            setRegionData({
                name: regionObj.name,
                stateId: regionObj.stateId ? {
                    label: statesApiResult?.data?.filter((el: any) => el.id === regionObj.stateId)[0]?.name,
                    value: regionObj.stateId
                } : null
            })
        }

    }, [regionByIdResponse?.data, statesApiResult?.data]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let commonObj = {
            ...regionData,
            stateId: regionData.stateId?.value,
            customer: {
                id: customerId
            }
        }

        const createOrUpdate = async () => {
            if (btnText === "Create") {
                await createRegion({...commonObj, enabled: true}).unwrap();
            } else {
                await updateRegion({
                    id: regionId,
                    requestBody: commonObj,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdate, () => {
            setRegionData({});
            setShow(false);
        });

    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setRegionData({
            ...regionData,
            [name]: value,
        })
    }

    return (
        <>
            <Modal title={title} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                   fields={
                       <Form onSubmit={handleSubmit} fields=
                           {
                               <>
                                   <InputField
                                       name={"name"}
                                       label="Name"
                                       onChange={handleOnChange}
                                       value={regionData?.name || ""}
                                       type="text"
                                       placeholder="Enter Name..."
                                       required={true}
                                   />

                                   <SelectField
                                       placeholder='Select State...'
                                       value={regionData?.stateId}
                                       options={createOptionListForSelectTag(statesApiResult?.data, "name", "id")}
                                       onChange={(selectedOption) => {
                                           handleOnChange({name: "stateId", value: selectedOption})
                                       }}
                                       label={"State"}
                                   />

                                   <ButtonTray
                                       buttons={[
                                           {
                                               buttonProps: {
                                                   btnText: "Cancel",
                                                   onClick: () => setShow(false),
                                                   type: "close",
                                               },
                                               buttonType: "button"
                                           },
                                           {
                                               buttonProps: {
                                                   btnText: "Submit",
                                                   type: "submit",
                                                   isLoading: (createRegionApiResponse.isLoading || updateRegionApiResponse.isLoading),
                                                   isVisible: isEditable
                                               },
                                               buttonType: "button",

                                           }
                                       ]}
                                       align="end"
                                       gap={2}
                                   />

                               </>
                           }
                       />
                   }
            />

        </>
    )
}
export default AddUpdateRegionModal;