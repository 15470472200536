import React, {ChangeEvent, FC, useEffect, useState} from "react";
import InputField from "../../../components/common/form/InputField";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import SendLinkModal from "../modal/SendLinkModal";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useGetContactByIdQuery, useUpdateContactMutation} from "../contactApi";
import {skipToken} from "@reduxjs/toolkit/query";
import SelectField from "../../../components/common/form/SelectField";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import 'react-phone-number-input/style.css'
import {E164Number} from "libphonenumber-js/types.cjs";

interface props {
    setActiveTab: any,
    formType: string,
    updatingContactId?: string,
    setContactReferenceId: any,
    partyId: string
}

const ContactForm: FC<props> = ({setActiveTab, formType, updatingContactId, setContactReferenceId, partyId}) => {

    const [contactData, setContactData] = useState<Record<string, any>>({
        partyId: partyId,
        status: "NOT_INVITED"
    });
    const [showConfirmationModel, setShowConfirmationModel] = useState<boolean>();

    const [updateContactApi, updateContactApiResponse] = useUpdateContactMutation();
    const getContactByIdApi = useGetContactByIdQuery(updatingContactId ?? skipToken);
    const {data: enums} = useGetEnumsQuery("");

    useEffect(() => {
        let obj = getContactByIdApi?.data;
        if (obj) {
            setContactData({
                ...obj,
                type: obj.type ? {
                    value: obj.type,
                    label: getEnumDisplayNameByConstant(
                        enums?.["MemberContactType"],
                        obj.type
                    ),
                } : null,
            });
        }
    }, [getContactByIdApi?.data])

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (formType === "Add") {
            setShowConfirmationModel(true);
        } else {
            await handleRTKQuery(
                async () => {
                    return await updateContactApi({
                        id: updatingContactId,
                        requestBody: {...contactData, type: contactData?.type?.value}
                    }).unwrap();
                },
                (isSuccess: boolean) => {
                    if (isSuccess) {
                        setContactData({});
                        setActiveTab("verify-contact");
                    }
                }
            );
        }
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setContactData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return (
        <>
            <form onSubmit={handleSubmit}>

                <p className="text-2xl text-center text-text-1 mb-4">
                    {formType} Contact Information
                </p>

                <div className={"min-h-[315px]"}>
                    <InputField label="Name" labelPosition="left" name={"name"} onChange={handleOnChange}
                                value={contactData?.name || ""} required={true}/>
                    <InputField label="Email" labelPosition="left" name={"emailAddress"} type={"email"}
                                onChange={handleOnChange}
                                value={contactData?.emailAddress || ""} required={true}/>

                            <InputField
                                name="phoneNumber"
                                label="Phone"
                                value={contactData?.phoneNumber}
                                // @ts-ignore
                                onChange={function (value?: E164Number | undefined): void {
                                    setContactData((prev) => ({
                                        ...prev,
                                        ['phoneNumber']: value,
                                    }));
                                }}
                                type="phoneNumber"
                                required
                                placeholder="Enter Phone Number..."
                            />

                    <SelectField
                        placeholder='Select Contact Type...'
                        value={contactData?.type || null}
                        options={createOptionListForSelectTag(enums?.["MemberContactType"], "displayName", "constant")}
                        onChange={(selectedOption) => {
                            handleOnChange({name: "type", value: selectedOption})
                        }}
                        required={true}
                        label={"Contact Type"}
                    />
                </div>
                <ButtonTray
                    buttons={[
                        {
                            buttonProps: {
                                btnText: "Back",
                                onClick: () => setActiveTab("verify-contact"),
                                type: "close",
                            },
                            buttonType: "button"
                        },
                        {
                            buttonProps: {
                                btnText: "Submit",
                                type: "submit",
                                isLoading: updateContactApiResponse.isLoading,
                                isVisible: true,
                            },
                            buttonType: "button",
                        }
                    ]}
                    align="end"
                    gap={2}
                    addTopLine={false}
                />

            </form>

            {showConfirmationModel &&
                <SendLinkModal
                    show={showConfirmationModel}
                    setShow={setShowConfirmationModel}
                    setActiveTab={setActiveTab}
                    contactData={contactData}
                    setContactReferenceId={setContactReferenceId}
                />
            }
        </>
    )
}
export default ContactForm;