import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryError, handleQueryErrorAndSuccess} from "../../helpers/utils/RtkQueryUtils";

export const sellerApi = strApi.injectEndpoints({

    endpoints: (build) => ({

        createMember: build.mutation({
            query: ({requestBody}) => {
                return {
                    url: `/fincen-member`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Member");
            },
        }),

        existLatestMemberByContactId: build.query({
            query: (contactId) => {
                return {
                    url: `/fincen-member/exist/${contactId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled);
            },
        }),
    })
})

export const {
    useCreateMemberMutation,
    useLazyExistLatestMemberByContactIdQuery
} = sellerApi