import Modal from "../../../../components/common/form/Modal";
import {ModalProps} from "../../../../helpers/constants";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import Form from "../../../../components/common/form/Form";
import {useGetEnumsQuery} from "../../../../app/globalApi";
import {createOptionListForSelectTag, isNullOrEmpty} from "../../../../helpers/utils/Utils";
import SelectField from "../../../../components/common/form/SelectField";
import {priorityCategories} from "../../../../enums/common";
import {useGetAllStatesByCountryIdQuery, useGetAllStatesQuery} from "../../../data/states/statesApi";
import {useGetAllCountyByStateAbbrQuery} from "../../../data/counties/countiesApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetAllRegularCustomersQuery} from "../../customer/customerApi";
import {useGetBranchesByCustomerIdsQuery} from "../../customer/branchApi";
import {handleRTKQuery} from "../../../../helpers/utils/RtkQueryUtils";
import {useCreateTeamRuleMutation, useGetTeamRuleByIdQuery, useUpdateTeamRuleMutation} from "../teamsApi";
import {useSearchMetroAreasQuery} from "../../../data/metroAreas/metroAreasApi";
import {useParams} from "react-router-dom";
import {getEnumDisplayNameByConstant} from "../../../../helpers/utils/EnumUtils";
import {selectStyle} from "../../../../helpers/utils/StyleUtils";
import Select from "react-select";
import ButtonTray from "../../../../components/common/form/ButtonTray";

interface props extends ModalProps {
    teamRuleId?: string | undefined,
    title: string,
    btnText: string
}

const AddUpdateRule: FC<props> = ({teamRuleId, btnText, title, show, setShow}) => {

    let {teamId} = useParams();

    const [ruleData, setRuleData] = useState<any>({});
    const [updatedBranches, setUpdatedBranches] = useState<any>([]);
    const statesApiResult = useGetAllStatesByCountryIdQuery("236b617cc-105e-4f73-a91c-e2162beac864");;
    const customerApiResult = useGetAllRegularCustomersQuery("");
    const teamRuleByIdApiResult = useGetTeamRuleByIdQuery(teamRuleId ?? skipToken);
    const {data: enums} = useGetEnumsQuery("");
    const branchesApiResult = useGetBranchesByCustomerIdsQuery(ruleData?.customers?.map((el: any) => {
        return el.value
    }) ?? []);
    const [createTeamRuleApi, createTeamRuleApiResponse] = useCreateTeamRuleMutation();
    const [updateTeamRuleApi, updateTeamRuleApiResponse] = useUpdateTeamRuleMutation();

    // Check if data exists and find the selected state
    const selectedState = statesApiResult.data?.find((el : any) => el.id === ruleData?.stateId?.value);
    const stateAbbreviation = selectedState?.abbreviation ?? skipToken;
    const stateName = selectedState?.name ?? null;

    // Fetch counties by state abbreviation
    const countyByStateAbbr = useGetAllCountyByStateAbbrQuery(stateAbbreviation);

    // Fetch metro areas, skipping if no stateName
    const metroAreaByStateAbbr = useSearchMetroAreasQuery(
        { pageSize: 10000, stateName },
        { skip: !stateName }
    );


    const [low, setLow] = useState({
        "name": "Low",
        "min": 1,
        "max": 100,
    });

    const [medium, setMedium] = useState({
        "name": "Medium",
        "min": 101,
        "max": 500,
    });

    const [high, setHigh] = useState({
        "name": "High",
        "min": 501,
        "max": 1000,
    });

    const [extreme, setExtreme] = useState({
        "name": "Extreme",
        "min": 1001,
        "max": null,
    });

    const getCategoryForWeight = (weight: any) => weight <= low.max ? low : weight >= medium.min && weight <= medium.max ? medium : weight >= high.min && weight <= high.max ? high : extreme;

    const getCategoryByName = (name: any) => [low, medium, high, extreme].find(entry => entry.name === name)


    useEffect(() => {
        let obj = teamRuleByIdApiResult?.data;
        if (obj) {
            let ruleQueryParameters = obj?.ruleQueryParameters;
            setRuleData(
                {
                    category: {
                        value: getCategoryForWeight(obj?.weight)?.name,
                        label: getCategoryForWeight(obj?.weight).name
                    },
                    productTypes: ruleQueryParameters?.productTypes?.map((el: any) => {
                        return {
                            value: el,
                            label: getEnumDisplayNameByConstant(enums?.["ProductType"], el)
                        }
                    }),
                    productStatuses: ruleQueryParameters?.productStatuses?.map((el: any) => {
                        return {
                            value: el,
                            label: getEnumDisplayNameByConstant(enums?.["ProductStatus"], el)
                        }
                    }),
                    stateId: ruleQueryParameters?.stateId ? {
                        value: ruleQueryParameters?.stateId,
                        label: obj?.stateName
                    } : null,
                    countyId: ruleQueryParameters?.countyId ? {
                        value: ruleQueryParameters?.countyId,
                        label: obj?.countyName
                    } : null,
                    metroAreaId: ruleQueryParameters?.metroAreaId ? {
                        value: ruleQueryParameters?.metroAreaId,
                        label: obj?.metroAreaName
                    } : null,
                    minimumOrderAge: ruleQueryParameters?.minimumOrderAge,
                    minimumOrderAgeType: ruleQueryParameters?.minimumOrderAgeType ? {
                        value: ruleQueryParameters?.minimumOrderAgeType,
                        label: getEnumDisplayNameByConstant(
                            enums?.["OrderAgeType"],
                            ruleQueryParameters?.minimumOrderAgeType
                        )
                    } : null,
                    customers: obj?.customers?.map((el: any) => {
                        return {
                            value: el.id,
                            label: el.customerName
                        };
                    }),
                    branches: obj?.branches?.map((el: any) => {
                        return {
                            value: el.id,
                            label: el.name
                        };
                    }),
                    teamId: teamId
                })
        }

    }, [teamRuleByIdApiResult?.data]);

    useEffect(() => {
        if (branchesApiResult?.data && customerApiResult?.data && ruleData?.customers) {
            const updatedBranches = branchesApiResult.data?.map((el: any) => {
                const customer = customerApiResult.data?.find((customer: any) => customer.id === el.customerId);
                return {
                    ...el,
                    customerName: customer ? customer.customerName : "Unknown", // Assuming `customer.name` exists
                };
            });

            // Assuming you're using a state to store updated branch data
            setUpdatedBranches(updatedBranches); // Replace with your state setter
        }
    }, [branchesApiResult?.data, customerApiResult?.data]);

    useEffect(() => {
        if (ruleData?.stateId?.value) {
            setRuleData(
                {
                    ...ruleData,
                    county: null,
                    metroArea: null
                }
            )
        }
    }, [ruleData?.stateId]);

    useEffect(() => {
        if (ruleData?.customers?.length === 0) {
            setRuleData(
                {
                    ...ruleData,
                    branches: null
                }
            )
        }
    }, [ruleData?.customers]);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | {
        name: string;
        value: any
    } | ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = "target" in e ? e.target : e;
        setRuleData({...ruleData, [name]: value});
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let categoryForName = getCategoryByName(ruleData.category?.value);

        let obj = {
            weight: categoryForName ? categoryForName.min : null,
            ruleQueryParameters: {
                productTypes: ruleData?.productTypes?.map((el: any) => {
                    return el.value
                }),
                productStatuses: ruleData?.productStatuses?.map((el: any) => {
                    return el.value
                }),
                stateId: ruleData?.stateId?.value,
                countyId: ruleData?.countyId?.value,
                metroAreaId: ruleData?.metroAreaId?.value,
                minimumOrderAge: ruleData?.minimumOrderAge,
                minimumOrderAgeType: ruleData?.minimumOrderAgeType?.value
            },
            customers: ruleData?.customers?.map((el: any) => {
                return customerApiResult?.data.filter((customer: any) => el.value === customer.id)[0];
            }),
            branches: ruleData?.branches?.map((el: any) => {
                return branchesApiResult?.data.filter((branch: any) => el.value === branch.id)[0];
            }),
            teamId: teamId
        }


        const createOrUpdateRule = async () => {
            if (btnText === "Create") {
                await createTeamRuleApi(obj).unwrap();
            } else {
                await updateTeamRuleApi({
                    id: teamRuleId,
                    requestBody: obj,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateRule, () => {
            setRuleData({});
            setShow(false);
        });
    }


    return (
        <>
            <Modal title={title} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                   fields={
                       <Form onSubmit={handleSubmit} fields={
                           <>

                               <SelectField
                                   label="Category"
                                   options={createOptionListForSelectTag(priorityCategories, "displayName", "constant")}
                                   value={ruleData.category || null}
                                   placeholder="Select Category..."
                                   onChange={(selectedOption) => handleOnChange({
                                       name: "category",
                                       value: selectedOption
                                   })}
                                   required={true}
                               />

                               <SelectField
                                   label="Product Type(s)"
                                   options={createOptionListForSelectTag(enums?.["ProductType"], "displayName", "constant")}
                                   value={ruleData.productTypes || null}
                                   placeholder="Select Product Type(s)..."
                                   onChange={(selectedOption) => handleOnChange({
                                       name: "productTypes",
                                       value: selectedOption
                                   })}
                                   isMulti={true}
                                   required={true}
                               />

                               <SelectField
                                   label="Product Status(es)"
                                   options={createOptionListForSelectTag(enums?.["ProductStatus"], "displayName", "constant")}
                                   value={ruleData.productStatuses || null}
                                   placeholder="Select Product Status(es)..."
                                   onChange={(selectedOption) => handleOnChange({
                                       name: "productStatuses",
                                       value: selectedOption
                                   })}
                                   isMulti={true}
                               />

                               <SelectField
                                   label="State"
                                   options={createOptionListForSelectTag(statesApiResult?.data, "name", "id")}
                                   value={ruleData.stateId || null}
                                   placeholder="Select State..."
                                   onChange={(selectedOption) => handleOnChange({
                                       name: "stateId",
                                       value: selectedOption
                                   })}
                               />

                               <SelectField
                                   label="County"
                                   value={ruleData?.countyId}
                                   placeholder="Select County..."
                                   options={createOptionListForSelectTag(countyByStateAbbr?.data, "name", "id")}
                                   onChange={(selectedOption) => {
                                       handleOnChange({name: "countyId", value: selectedOption})
                                   }}
                                   isLoading={countyByStateAbbr?.isLoading || countyByStateAbbr?.isFetching}
                               />

                               <SelectField
                                   label="Metro Area"
                                   value={ruleData?.metroAreaId}
                                   placeholder="Select Metro Area..."
                                   options={createOptionListForSelectTag(metroAreaByStateAbbr?.data, "name", "id")}
                                   onChange={(selectedOption) => {
                                       handleOnChange({name: "metroAreaId", value: selectedOption})
                                   }}
                                   isLoading={metroAreaByStateAbbr?.isLoading || metroAreaByStateAbbr?.isFetching}
                               />

                               <SelectField
                                   label="Customer(s)"
                                   value={ruleData?.customers}
                                   placeholder="Select Customer(s)..."
                                   options={createOptionListForSelectTag(customerApiResult?.data, "customerName", "id")}
                                   onChange={(selectedOption) => {
                                       handleOnChange({name: "customers", value: selectedOption})
                                   }}
                                   isMulti={true}
                               />

                               <SelectField
                                   label="Branch(es)"
                                   value={ruleData?.branches || null}
                                   placeholder="Select Branch(es)..."
                                   options={createOptionListForSelectTag(updatedBranches, "name", "id", "customerName")}
                                   onChange={(selectedOption) => {
                                       handleOnChange({name: "branches", value: selectedOption})
                                   }}
                                   isMulti={true}
                                   isLoading={isNullOrEmpty(ruleData?.customers)}
                               />

                               <div className={"col-span-12 md:grid md:grid-cols-12 gap-3"}>

                                   <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                                       <div className="text-text-1">Max Order Age</div>
                                   </div>

                                   <div className={"col-span-4 flex gap-3"}>
                                       <input placeholder="Enter Min Order Age.hh.."
                                              type={"number"}
                                              className={"placeholder:text-text-2 rounded-md border border-surface-5 text-text-1 form_control p-2  w-full  mt-3 bg-surface-2"}
                                              name={"minimumOrderAge"}
                                              onChange={handleOnChange}
                                       />
                                   </div>

                                   <div className={"col-span-5 mt-3.5"}>
                                       <Select
                                           styles={selectStyle}
                                           onChange={(selectedOption) => {
                                               handleOnChange({name: "minimumOrderAgeType", value: selectedOption})
                                           }}
                                           value={ruleData?.minimumOrderAgeType || null}
                                           isClearable={true}
                                           options={createOptionListForSelectTag(enums?.['OrderAgeType'], "displayName", "constant")}
                                           placeholder={"Select Unit..."}
                                       />
                                   </div>

                               </div>

                               <ButtonTray
                                   buttons={[
                                       {
                                           buttonProps: {
                                               btnText: "Cancel",
                                               onClick: () => setShow(false),
                                               type: "close",
                                           },
                                           buttonType: "button"
                                       },
                                       {
                                           buttonProps: {
                                               btnText: "Submit",
                                               type: "submit",
                                               isLoading: (createTeamRuleApiResponse.isLoading || updateTeamRuleApiResponse.isLoading),
                                           },
                                           buttonType: "button",

                                       }
                                   ]}
                                   align={"end"}
                                   gap={2}
                               />

                           </>}
                       />}


            />
        </>
    )
}

export default AddUpdateRule;