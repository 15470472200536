import React, {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {useGetBatchByIdQuery} from "../fincenBatchApi";
import FincenBatchFilingContainer from "./FincenBatchFilingContainer";
import FincenErrorContainer from "./FincenErrorContainer";
import FincenFileContainer from "./FincenFileContainer";
import ContainerCard from "../../../components/common/ContainerCard";
import {DetailRow} from "../../../helpers/utils/OrderDetailUtils";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import {useGetEnumsQuery} from "../../../app/globalApi";

const FincenBatchDetails: FC = () => {

    let {batchId} = useParams();
    const {data: enums} = useGetEnumsQuery("");

    const batchQuery = useGetBatchByIdQuery(batchId);

    const [crumbs, setCrumbs] = useState<any[]>([])

    useEffect(() => {
        if (batchQuery?.data) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "FinCen Batches", url: "/fincen-batches"},
                {content: batchQuery?.data?.batchName, url: ""}
            ])

            document.title = `${batchQuery?.data?.batchName}`;
        }
    }, [batchQuery?.data]);

    const BatchInfo = () => {
        return <>
            <div>
                <DetailRow label='Name'
                           value={batchQuery?.data?.batchName}
                           isLoading={batchQuery?.isLoading}
                           isError={batchQuery?.isError}
                           title='Name of the batch file'/>
                <DetailRow label='Tracking ID'
                           value={batchQuery?.data?.trackingId}
                           isLoading={batchQuery?.isLoading}
                           isError={batchQuery?.isError}
                           title='ID provided by FinCen for tracking this batch, only exists after message file provided'/>

                <DetailRow label='Status'
                           value={getEnumDisplayNameByConstant(enums?.["FincenBatchStatus"], batchQuery?.data?.status)}
                           isLoading={batchQuery?.isLoading}
                           isError={batchQuery?.isError}
                           isValueSpan={true}
                           title='Status of the batch'/>

                <DetailRow label='Activity Count'
                           value={batchQuery?.data?.size}
                           isLoading={batchQuery?.isLoading}
                           isError={batchQuery?.isError}
                           title='Count of activities included in the batch'/>
            </div>
        </>;
    }

    return (
        <>
            <div className="px-5 py-5">
                <Breadcrumb crumbs={crumbs}/>
                <div className="md:grid sm:block grid-cols-12 gap-3">
                    <div className={'col-span-5'}/>
                </div>
                <div className="md:grid sm:block grid-cols-12 gap-3">
                    <div className="col-span-5">
                        <ContainerCard title='Batch Info'
                                       contents={<BatchInfo/>}/>
                    </div>
                    <div className="col-span-7 ">
                        <ContainerCard title='Filings'
                                       contents={<FincenBatchFilingContainer batchId={batchId}/>}/>

                        <ContainerCard title='Errors'
                                       contents={<FincenErrorContainer batchId={batchId}/>}/>

                        <FincenFileContainer batchId={batchId}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FincenBatchDetails