import React, {FC, useEffect, useState} from "react";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {
    useCreateQuestionnaireMutation,
    useFindQuestionnaireByPartyIdQuery,
} from "../questionnaireApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {buyerQuestions, sellerQuestions} from "../../../helpers/utils/Utils";

interface props {
    setActiveTab: any,
    skippedTabs: any,
    onComplete: any,
    partyId: string,
    setQuestionResponse: any,
    partyType: string;
    showOrderPageButton: boolean
}

const QuestionnaireForm: FC<props> = ({
                                          setActiveTab,
                                          skippedTabs,
                                          onComplete,
                                          partyId,
                                          partyType,
                                          setQuestionResponse,
                                          showOrderPageButton
                                      }) => {

    const [questionnaireApi, questionnaireApiResponse] = useCreateQuestionnaireMutation();
    const questions = partyType === "SELLER" ? sellerQuestions : buyerQuestions;
    const questionnaireByPartyIdResponse = useFindQuestionnaireByPartyIdQuery(partyId, {
        skip: !showOrderPageButton, // API only runs when `showOrderPageButton` is true
    });

    const [noneOfTheAbove, setNoneOfTheAbove] = useState(false);

    const allQuestions = [...sellerQuestions, ...buyerQuestions];
    // State to track answers for each question
    const [answers, setAnswers] = useState<any>(
        allQuestions.reduce((acc, question) => ({...acc, type: partyType, [question.name]: null}), {})
    );

    useEffect(() => {
        let obj = questionnaireByPartyIdResponse?.data;
        if (obj) {
            setAnswers(obj);
        }
    }, [questionnaireByPartyIdResponse?.data]);

    useEffect(() => {
        if (partyType) {
            setAnswers((prevAnswers: any) => ({
                ...prevAnswers,
                type: partyType,
            }));
        }
    }, [partyType]);

    const handleNavigation = async () => {
        await handleRTKQuery(
            async () => {
                return await questionnaireApi({requestBody: answers, partyId: partyId}).unwrap();
            },
            (isSuccess: boolean) => {
                if (isSuccess) {
                    setQuestionResponse(answers);
                    // @ts-ignore
                    const {
                        type,
                        id,
                        createdBy,
                        creationDate,
                        deleted,
                        modifiedBy,
                        modifiedDate,
                        partyId,
                        productId,
                        ...filteredAnswers
                    } = answers;

                    console.log("filteredAnswers", filteredAnswers);
                    if (Object.values(filteredAnswers).every((answer) => (answer === false || answer == null))) {
                        console.log("filteredAnswers");
                        setActiveTab("organization-detail");
                    } else {
                        skippedTabs.push("organization-detail");
                        skippedTabs.push("verify-contact");
                        setActiveTab("sign");
                    }
                    onComplete();
                }
            }
        );
    };

    const handleAnswerChange = (name: string) => {
        setAnswers((prevAnswers: any) => ({
            ...prevAnswers,
            [name]: !prevAnswers[name],
        }));
    };

    const InformationBlurb = () => {
        return <div className='text-text-1 text-xl'>
            Your Title Company has designated you as the primary contact for your entity to supply
            information required for FinCEN reporting.
            <br/>
            <br/>
            If you believe you have been designated in error and are not the individual who is able to
            supply entity information, please add the correct contact.
            <br/>
            If you are not able to provide the correct contact, please <a
            className='cursor-pointer text-highlight-3 hover:text-highlight-7'
            href='mailto:fincenreporting@fincensettlementreporting.com'>contact our support</a>.
        </div>;
    }
    return (
        <>
            <div className="flex">
                <div className="w-full mt-5">
                    {/* Added content */}
                    <div className="text-text-1">
                        {<InformationBlurb/>}
                        <br/>
                        <hr className="text-surface-2"/>
                        <br/>
                        <h2 className="text-2xl font-semibold">{partyType === "SELLER" ?
                            "Is this real estate transaction associated with any of the following?" :
                            "Is the entity that you are affiliated with regarding this transaction any of the following?"}
                        </h2>
                        <div className="mt-4">
                            <ul className="mt-3 space-y-2">
                                <li>Select all that apply</li>
                                {questions.map(({text, name}) => (
                                    <li>
                                        {/*// @ts-ignore*/}
                                        <input type={"checkbox"}
                                               onClick={() => handleAnswerChange(name)}
                                               checked={(answers && answers[name]) || false}/>
                                        <span className="ml-2">{text.split("\n").map((line, index) => (
                                            <React.Fragment key={index}>{line}<br/></React.Fragment>))}</span>

                                    </li>
                                ))}
                                <li>
                                    <input type={"checkbox"}
                                           onClick={() => {
                                               setNoneOfTheAbove(!noneOfTheAbove)
                                           }}
                                           checked={noneOfTheAbove}/>
                                    <span className="ml-2">None of the above</span>
                                </li>
                            </ul>
                        </div>
                        {/*    TODO: Add 'None of the Above' option that must be checked if none others are to proceed*/}
                    </div>

                    <div className={"mb-2 mr-4"}>
                        <ButtonTray
                            buttons={[
                                {
                                    buttonProps: {
                                        btnText: "Back",
                                        onClick: () => {
                                            setActiveTab("verify-code");
                                        },
                                        type: "close",
                                    },
                                    buttonType: "button"
                                },
                                {
                                    buttonProps: {
                                        btnText: "Continue",
                                        type: "submit",
                                        isLoading: questionnaireApiResponse.isLoading,
                                        isVisible: true,
                                        // isDisabled: !noneOfTheAbove, // TODO: noneOfTheAbove should disable all other options if selected. Disable "Continue" until any option is selected
                                        onClick: handleNavigation
                                    },
                                    buttonType: "button",
                                }
                            ]}
                            align="end"
                            gap={2}
                            addTopLine={false}
                        />
                    </div>


                </div>
            </div>
        </>
    );
};

export default QuestionnaireForm;
