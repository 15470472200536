// @ts-ignore
import logo from "../../../images/logo.svg";
import React, {useEffect, useState} from "react";
import "../loginPage.modules.css";
import Button from "../../../components/common/form/Button";
import {FaEnvelope, FaKey, FaPhone} from "react-icons/fa";
import InputField from "../../../components/common/form/InputField";
import {useLoginUserMutation, useRequestMfaChallengeMutation, useVerifyMfaChallengeMutation} from "../loginPageApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useLocation, useNavigate} from "react-router-dom";
import {setCookie} from "../../../helpers/utils/Utils";

const VerifyMfaForm = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const {username, password, mfaResponse} = location.state || {};

    const [mfaDevices, setDevices] = useState<any>([]);
    const [selectedDevice, setSelectedDevice] = useState<any>([]);
    const [code, setCode] = useState<string>("");
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [timer, setTimer] = useState(30);
    const [isSubmit, setIsSubmit] = useState(false);
    const [requestMfaChallenge, {isLoading: requestMfaChallengeLoading}] = useRequestMfaChallengeMutation();
    const [verifyMfaChallenge, {isLoading: verifyMfaChallengeLoading}] = useVerifyMfaChallengeMutation();
    const [challengeId, setChallengeId] = useState(null);
    const [loginUser] = useLoginUserMutation();

    useEffect(() => {
        const storedResponse = mfaResponse;

        if (storedResponse != null) {

            const parsedResponse = JSON.parse(storedResponse);
            const challengeId = parsedResponse?.mfaInfo?.mfaChallengeId || null;

            setChallengeId(challengeId);

            const verifiedDevices = parsedResponse?.mfaInfo?.mfaDevices.filter(
                (el: any) => el.verified === true
            );

            const preferred = parsedResponse?.mfaInfo?.mfaDevices.filter(
                (el: any) => el.preferred
            );

            if (preferred.length > 0) {
                setSelectedDevice(preferred[0]);
            } else {
                setSelectedDevice(verifiedDevices[0]);
            }

            if (verifiedDevices && Array.isArray(verifiedDevices)) {
                setDevices(verifiedDevices); // Update the state with parsedResponse
            }
        }
    }, []);

    useEffect(() => {
        if (timer > 0 && isResendDisabled) {
            const countdown = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
            return () => clearInterval(countdown);
        } else {
            setIsResendDisabled(false);
        }
    }, [timer, isResendDisabled]);

    const handleDeviceSelection = (deviceId: string) => {
        setSelectedDevice(deviceId);
    };

    useEffect(() => {
        if (isSubmit) {
            setIsResendDisabled(true);
            setTimer(30);
        }
    }, [isSubmit]);

    const handleSubmit = async (e: any) => {
        if (selectedDevice) {
            await handleRTKQuery(
                async () => {
                    return requestMfaChallenge({
                        challengeId: challengeId,
                        mfaDeviceId: selectedDevice?.id,
                        remember: false,
                    }).unwrap();
                },
                () => {
                    setIsSubmit(true);
                }
            );
        }
    };

    const startTimer = () => {
        setIsResendDisabled(true);
        setTimer(30);

        const timerInterval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(timerInterval); // Clear the interval when the timer ends
                    setIsResendDisabled(false); // Enable the resend button
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    const handleResendCode = () => {
        if (!isResendDisabled) {
            return requestMfaChallenge({
                challengeId: challengeId,
                mfaDeviceId: selectedDevice?.id,
                remember: false,
            }).unwrap();
            startTimer();
        }
    };

    const handleVerifySubmit = async (e: any) => {
        if (code) {
            await handleRTKQuery(
                async () => {
                    return verifyMfaChallenge({
                        challengeId: challengeId,
                        solution: code,
                    }).unwrap();
                },
                (response: any) => {
                    handleRTKQuery(
                        async () => {

                            return loginUser({
                                username: username,
                                password: password,
                                mfaToken: response.token,
                            }).unwrap();
                        },
                        (response: any) => {
                            setCookie("authToken", response.token);
                            navigate(`/home`);
                        }
                    );
                }
            );
        }
    };

    const getDeviceIcon = (obj: any) => {
        switch (obj?.type) {
            case "AUTHENTICATOR":
                return (
                    <div
                        className={`flex gap-5 p-3 items-center mb-3 mt-3 rounded ${obj.id === selectedDevice?.id ? "bg-highlight-3 text-text-1" : "bg-surface-6"}`}
                        onClick={() => handleDeviceSelection(obj)}>
                        <FaKey className={"text-2xl"}/>
                        <p className={"text-xl"}>Authenticator App</p>
                    </div>
                );
            case "EMAIL":
                return (
                    <div
                        className={`flex gap-5 p-3 items-center mb-3 mt-3 rounded ${obj.id === selectedDevice?.id ? "bg-highlight-3 text-text-1" : "bg-surface-6"}`}
                        onClick={() => handleDeviceSelection(obj)}>
                        <FaEnvelope className={"text-2xl"}/>
                        <p className={"text-xl"}>Email {obj.credential}</p>
                    </div>
                );
            case "SMS":
                return (
                    <div
                        className={`flex gap-5 p-3 items-center mb-3 mt-3 rounded ${obj.id === selectedDevice?.id ? "bg-highlight-3 text-text-1" : "bg-surface-6"}`}
                        onClick={() => handleDeviceSelection(obj)}>
                        <FaPhone className={"text-2xl"}/>
                        <p className={"text-xl"}>Phone {obj.credential}</p>
                    </div>
                );
            default:
                return null;
        }
    };

    function getMessage(type: string) {
        let message;
        switch (type) {
            case 'AUTHENTICATOR':
                message = (
                    <>
                        <h6 className="text-center mb-3 text-text-1">A verification code has been sent to your
                            authenticator
                            app.</h6>
                        <p className="text-center text-text-1">Please enter the code generated by the app.</p>
                    </>
                );
                break;
            case 'SMS':
                message = (
                    <>
                        <h6 className="text-center mb-3 text-text-1">A verification code has been sent to your mobile
                            device via
                            SMS.</h6>
                        <p className="text-center text-text-1">Please enter the code received in the text message.</p>
                    </>
                );
                break;
            case 'EMAIL':
                message = (
                    <>
                        <h6 className="text-center mb-3 text-text-1">A verification code has been sent to your email
                            address.</h6>
                        <p className="text-center text-text-1">Please enter the code received in the email.</p>
                    </>
                );
                break;
            default:
                message = (
                    <>
                        <h6 className="text-center mb-3 text-text-1">A verification code has been sent to your selected
                            device.</h6>
                        <p className="text-center text-text-1">Please enter the code to proceed.</p>
                    </>
                );
        }

        return message;
    }

    return (
        <>
            <section className="login_form flex items-center justify-center">
                <div className="container mx-auto px-4">
                    <div className="flex justify-center">
                        <div className="w-full max-w-xl lg:max-w-lg md:max-w-md">
                            <div className="log_in relative z-10">
                                <div className="text-center mb-4">
                                    <img loading="lazy" src={logo} alt=""/>

                                    {!isSubmit ?
                                        <>
                                            <h1 className={"mt-1 text-2xl text-text-4 mb-3"}>
                                                Choose a way to Authenticate
                                            </h1>
                                            {mfaDevices.length > 0 ? (
                                                mfaDevices.map((el: any, index: number) => (
                                                    <React.Fragment key={index}>
                                                        {getDeviceIcon(el)}
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <h2 className={"mt-3 text-xl text-text-1"}>
                                                    No MFA devices available.
                                                </h2>
                                            )}

                                            {mfaDevices.length > 0 &&
                                                <div className="form-group mt-4">
                                                    <Button
                                                        btnText={"Send Verification Code"}
                                                        isLoading={requestMfaChallengeLoading}
                                                        onClick={handleSubmit}
                                                        type={"submit"}
                                                        isDisabled={!selectedDevice}
                                                    ></Button>
                                                </div>
                                            }
                                        </>
                                        :
                                        <>
                                            <h1 className={"mt-1 text-2xl text-text-4 mb-3"}>
                                                Verify Your Identity
                                            </h1>
                                            {getMessage(selectedDevice?.type)}

                                            <InputField
                                                name="code"
                                                label=""
                                                value={code}
                                                onChange={(e : any) => setCode(e.target.value)}
                                                type="email"
                                                required
                                                placeholder="Enter Code..."
                                                hideLabel={true}
                                                className="form-control"
                                            />

                                            <div className="form-group mt-4">
                                                <Button
                                                    btnText={"Verify Code"}
                                                    isLoading={verifyMfaChallengeLoading}
                                                    onClick={handleVerifySubmit}
                                                    type={"submit"}
                                                ></Button>
                                            </div>

                                            <div className="form-group mt-4">
                                                <Button
                                                    btnText={isResendDisabled ? `Re-send (${timer}s)` : "Re-send"}
                                                    isLoading={false}
                                                    onClick={handleResendCode}
                                                    type={"submit"}
                                                ></Button>
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default VerifyMfaForm;
