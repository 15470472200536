import React, {ChangeEvent, FC, useRef, useState} from "react";
import {RxDragHandleDots2} from "react-icons/rx";
import {IoIosArrowForward} from "react-icons/io";
import {MdDelete} from "react-icons/md";
import InputField from "../../../../components/common/form/InputField";
import Button from "../../../../components/common/form/Button";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import {removeItemAtIndex} from "../../../../helpers/utils/Utils";
import {isNullOrEmpty} from "../../../../helpers/utils/Utils";
import {PersonInfoForm} from "./PersonInfoForm";
import {useGetEnumsQuery} from "../../../../app/globalApi";
import PersonInfoList from "./PersonInfoList";
import ModalButton from "../../../../components/common/form/ModalButton";
import HelperInfoList from "./HelperInfoList";


interface props {
    createOrderData: any,
    setCreateOrderData: React.Dispatch<React.SetStateAction<any>>,
    editPackageIndexList: number[];
    undoPackageListData: number[];
    setEditPackageIndexList: React.Dispatch<React.SetStateAction<any[]>>;
    setUndoPackageListData: React.Dispatch<React.SetStateAction<any[]>>;
    requirementsForInstrument: any[];
    setUnsavedPersonInfo: any;
}

const RecordingListSection: FC<props> = ({
                                             createOrderData,
                                             setCreateOrderData,
                                             editPackageIndexList,
                                             undoPackageListData,
                                             setEditPackageIndexList,
                                             setUndoPackageListData,
                                             requirementsForInstrument,
                                             setUnsavedPersonInfo,
                                         }) => {


    const {data: enums} = useGetEnumsQuery("")

    const dragOverItem = useRef<any>(null)
    const dragItem = useRef<any>(null)
    const [showPackageDetail, setShowPackageDetail] = useState([])
    const [grantorObj, setGrantorObj] = useState<any>({type: {"label": "Individual", "value": "INDIVIDUAL"}});
    const [granteeObj, setGranteeObj] = useState<any>({type: {"label": "Individual", "value": "INDIVIDUAL"}});

    const handleEditPackage = (editPackageIndex: number, e: ChangeEvent<HTMLInputElement> | {
        name: string;
        value: string | string[],
        files?: null
    }) => {
        setEditPackageIndexList([...editPackageIndexList, editPackageIndex])
        const {name, value, files} = "target" in e ? e.target : e;
        const updatedPackageList = [...createOrderData.packageList]
        updatedPackageList[editPackageIndex] = {
            ...updatedPackageList[editPackageIndex],
            [name]: files ? files[0] : value
        }
        setCreateOrderData({
            ...createOrderData,
            packageList: updatedPackageList
        })
    }

    const handleSavePackageChanges = (editPackageIndex: number) => {
        const updatedEditPackageIndexList = [...editPackageIndexList]?.filter(cur => cur !== editPackageIndex)
        setEditPackageIndexList(updatedEditPackageIndexList)
        setUndoPackageListData([...createOrderData.packageList])
    }

    const handleUndoPackageChanges = (editPackageIndex: number) => {
        const updatedEditPackageIndexList = [...editPackageIndexList]?.filter(cur => cur !== editPackageIndex)
        setEditPackageIndexList(updatedEditPackageIndexList)
        const fileInput = document.getElementById('editFile-' + editPackageIndex) as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
        setCreateOrderData({
            ...createOrderData,
            packageList: undoPackageListData
        })
    }

    const toggleShowPackageDetail = (index: number) => {
        let updatedShowPackageDetail = [...showPackageDetail];
        //@ts-ignore
        updatedShowPackageDetail[index] = isNullOrEmpty(updatedShowPackageDetail[index]) ? true : !updatedShowPackageDetail[index]
        setShowPackageDetail([...updatedShowPackageDetail])
    }

    const handleDrag = () => {
        const updatedPackageList = [...createOrderData?.packageList];
        const updatedUndoPackageList = [...undoPackageListData];
        // get DraggedItem and remove it from existing array
        const [draggedItem] = updatedPackageList.splice(dragItem.current, 1);
        const [draggedUndoItem] = updatedUndoPackageList.splice(dragItem.current, 1);
        // put dragged item to new position
        updatedPackageList.splice(dragOverItem.current, 0, draggedItem);
        updatedUndoPackageList.splice(dragOverItem.current, 0, draggedUndoItem);
        dragItem.current = null;
        dragOverItem.current = null;
        // Update Actual Array
        setCreateOrderData({
            ...createOrderData,
            packageList: updatedPackageList
        })
        setUndoPackageListData(updatedUndoPackageList)
    }

    return (
        <>
            {
                createOrderData?.packageList?.length > 0 && createOrderData.packageList.map((curPackage: any, index: number) => {
                    return (
                        <div key={index} className="bg-surface-2 pt-2 ">
                            <div
                                className="bg-highlight-2 justify-between flex px-2 py-1 cursor-pointer"
                                draggable={true}
                                onDragEnter={() => {
                                    dragOverItem.current = index
                                }}
                                onDragEnd={handleDrag}
                                onDragStart={() => {
                                    dragItem.current = index
                                }}
                                onDragOver={(e) => {
                                    e.preventDefault()
                                }}
                            >
                                <div className="flex gap-1 items-center">
                                    <RxDragHandleDots2
                                        className="text-text-1 text-2xl"/>
                                    <h3 className="text-text-1 text-base font-semibold">
                                        {curPackage?.fileName?.label === "Custom" ? curPackage?.customFileName : curPackage?.fileName?.label}
                                    </h3>
                                </div>
                                <div className="flex gap-3">

                                    <IoIosArrowForward
                                        className={"text-text-1  text-lg cursor-pointer " + (showPackageDetail[index] ? "rotate-90" : "")}
                                        onClick={() => {
                                            toggleShowPackageDetail(index)
                                        }}/>

                                    <ModalButton
                                        triggerButton={<MdDelete
                                            className={"text-text-1 text-xl cursor-pointer"}
                                        />}
                                        onOpen={() => {
                                        }}
                                        modal={
                                            <ConfirmationModal
                                                title={"Delete document"}
                                                bodyMessage={"Are you sure you want to delete this document?"}
                                                show={true}
                                                setShow={() => {
                                                }}
                                                onSubmit={() => {
                                                    const updatedPackageListData = removeItemAtIndex(createOrderData.packageList, index)
                                                    setCreateOrderData({
                                                        ...createOrderData,
                                                        packageList: updatedPackageListData
                                                    })
                                                    setUndoPackageListData(updatedPackageListData)
                                                }}
                                                isLoading={false}
                                            />
                                        }
                                    />

                                </div>
                            </div>
                            {
                                showPackageDetail[index] &&

                                <div className="grid grid-cols-12 border border-surface-6 p-3">

                                    <InputField
                                        label="Name"
                                        value={curPackage?.fileName?.label === "Custom" ? curPackage?.customFileName : curPackage?.fileName?.label}
                                        type="text"
                                        placeholder="Enter File Name..."
                                        disabled={true}
                                        required={true}
                                    />

                                    <InputField
                                        label="Type"
                                        value={curPackage?.documentType?.label}
                                        type="text"
                                        placeholder="Enter Type..."
                                        disabled={true}
                                        required={curPackage?.fileName?.label !== "Document Package"}
                                    />

                                    <div className={`col-span-12 md:grid md:grid-cols-12 gap-3 mt-3`}>

                                        <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                                            <label className="text-text-1">*File</label>
                                        </div>
                                        <div className="col-span-9">
                                            <input
                                                id={"editFile-" + index}
                                                name={"file"}
                                                accept={".pdf"}
                                                onChange={(e : any) => {
                                                    handleEditPackage(index, e)
                                                }}
                                                className="w-full bg-surface-2 rounded-md border border-surface-4 text-text-1 form_control p-2"
                                                type="file"
                                            />
                                            <div className={"text-text-1"}> {curPackage?.file?.name}</div>
                                        </div>
                                    </div>


                                    {curPackage?.decimal.length > 0 && <InputField
                                        label="Consideration"
                                        value={curPackage?.consideration || ""}
                                        type="number"
                                        placeholder="Enter Consideration..."
                                        onChange={(e : any) => {
                                            const newValue = e.target.value;
                                            setCreateOrderData((prevData: any) => {
                                                const updatedPackages = [...prevData.packageList];
                                                updatedPackages[index].consideration = newValue;
                                                return {...prevData, packageList: updatedPackages};
                                            });
                                        }}
                                        name={"consideration"}
                                        required={curPackage?.decimal[0].required === "ALWAYS"}
                                    />}

                                    <div className="col-span-12 md:grid md:grid-cols-12 gap-3 mt-3">
                                        <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                                            <h2 className="text-text-1">Grantors</h2>
                                        </div>
                                        <div className="col-span-9">
                                            <PersonInfoForm
                                                enums={enums}
                                                submitBtnText={"Add"}
                                                handleSubmitAction={(e) => {
                                                    setCreateOrderData((prevState: any) => ({
                                                        ...prevState,
                                                        packageList: prevState.packageList.map((pkg: any, i: any) =>
                                                            i === index
                                                                ? {...pkg, grantors: [...pkg.grantors, e]}
                                                                : pkg
                                                        )
                                                    }));
                                                }}
                                                obj={grantorObj}
                                                requirementsForInstrument={requirementsForInstrument}
                                                item={"grantor"}
                                                setUnsavedPersonInfo={setUnsavedPersonInfo}
                                                undoAction={(e) => {
                                                    setGrantorObj({type: {"label": "Individual", "value": "INDIVIDUAL"}})
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {
                                        curPackage?.grantors && curPackage?.grantors.length > 0 &&
                                        <PersonInfoList personList={curPackage?.grantors}
                                                        enums={enums}
                                                        onDeleteAction={(i: number) => {
                                                            setCreateOrderData((prevState: any) => ({
                                                                ...prevState,
                                                                packageList: prevState.packageList.map((pkg: any, pkgIndex: number) =>
                                                                    pkgIndex === index
                                                                        ? {
                                                                            ...pkg,
                                                                            grantors: pkg.grantors.filter((_: any, grantorIndex: number) => grantorIndex !== i)
                                                                        }
                                                                        : pkg
                                                                )
                                                            }));
                                                        }}
                                                        submitAction={(items: any[]) => {
                                                            setCreateOrderData((prevState: any) => ({
                                                                ...prevState,
                                                                // eslint-disable-next-line array-callback-return
                                                                packageList: prevState.packageList.map((pkg: any, pkgIndex: number) =>
                                                                    pkgIndex === index
                                                                        ? {
                                                                            ...pkg,
                                                                            grantors: items
                                                                        }
                                                                        : pkg
                                                                )
                                                            }));
                                                        }}
                                                        requirementsForInstrument={requirementsForInstrument}
                                                        item={"grantor"}
                                                        setUnsavedPersonInfo={setUnsavedPersonInfo}
                                        />
                                    }

                                    <div className="col-span-12 md:grid md:grid-cols-12 gap-3 mt-3">
                                        <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                                            <h2 className="text-text-1">Grantees</h2>
                                        </div>
                                        <div className="col-span-9">
                                            <PersonInfoForm
                                                enums={enums}
                                                submitBtnText={"Add"}
                                                handleSubmitAction={(e) => {
                                                    setCreateOrderData((prevState: any) => ({
                                                        ...prevState,
                                                        packageList: prevState.packageList.map((pkg: any, i: any) =>
                                                            i === index
                                                                ? {...pkg, grantees: [...pkg.grantees, e]}
                                                                : pkg
                                                        )
                                                    }));
                                                }}
                                                obj={granteeObj}
                                                requirementsForInstrument={curPackage?.documentType?.value !== undefined ? requirementsForInstrument : []}
                                                item={"grantee"}
                                                setUnsavedPersonInfo={setUnsavedPersonInfo}
                                                undoAction={(e) => {
                                                    setGranteeObj({type: {"label": "Individual", "value": "INDIVIDUAL"}})
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {
                                        curPackage?.grantees && curPackage?.grantees.length > 0 &&
                                        <PersonInfoList personList={curPackage?.grantees}
                                                        enums={enums}
                                                        onDeleteAction={(i: number) => {
                                                            setCreateOrderData((prevState: any) => ({
                                                                ...prevState,
                                                                packageList: prevState.packageList.map((pkg: any, pkgIndex: number) =>
                                                                    pkgIndex === index
                                                                        ? {
                                                                            ...pkg,
                                                                            grantees: pkg.grantees.filter((_: any, granteeIndex: number) => granteeIndex !== i)
                                                                        }
                                                                        : pkg
                                                                )
                                                            }));
                                                        }}
                                                        submitAction={(items: any[]) => {
                                                            setCreateOrderData((prevState: any) => ({
                                                                ...prevState,

                                                                packageList: prevState.packageList.map((pkg: any, pkgIndex: number) =>
                                                                    pkgIndex === index
                                                                        ? {
                                                                            ...pkg,
                                                                            grantees: items
                                                                        }
                                                                        : pkg
                                                                )
                                                            }));
                                                        }}
                                                        requirementsForInstrument={curPackage?.documentType?.value !== undefined ? requirementsForInstrument : []}
                                                        item={"grantee"}
                                                        setUnsavedPersonInfo={setUnsavedPersonInfo}

                                        />
                                    }

                                    {curPackage?.helperDocuments && curPackage?.helperDocuments.length > 0 &&
                                        <div className="col-span-12 md:grid md:grid-cols-12 gap-3">
                                            <div className="col-span-3 sm:mt-2 lg-mt-0 flex items-center">
                                                <h2 className="text-text-1">Helper Documents</h2>
                                            </div>
                                            <div className="col-span-9">
                                                <HelperInfoList
                                                    helperList={curPackage?.helperDocuments}
                                                    submitAction={(items: any[]) => {
                                                        setCreateOrderData((prevState: any) => ({
                                                            ...prevState,
                                                            packageList: prevState.packageList.map((pkg: any, pkgIndex: number) =>
                                                                pkgIndex === index
                                                                    ? {
                                                                        ...pkg,
                                                                        helperDocuments: items
                                                                    }
                                                                    : pkg
                                                            )
                                                        }));
                                                    }}
                                                    onDeleteAction={(i: number) => {
                                                        setCreateOrderData((prevState: any) => ({
                                                            ...prevState,
                                                            packageList: prevState.packageList.map((pkg: any, pkgIndex: number) =>
                                                                pkgIndex === index
                                                                    ? {
                                                                        ...pkg,
                                                                        helperDocuments: pkg.helperDocuments.filter((_: any, helperIndex: number) => helperIndex !== i)
                                                                    }
                                                                    : pkg
                                                            )
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }

                                    {
                                        (editPackageIndexList.includes(index)) &&
                                        <div className={"col-span-12 md:grid md:grid-cols-12 gap-3 mt-3 grid"}>
                                            <div className={"col-span-3"}>
                                            </div>
                                            <div className={"col-span-9 flex gap-1"}>
                                                <Button className={" mr-1"} btnText={"Undo"} type={"close"}
                                                        onClick={() => {
                                                            handleUndoPackageChanges(index)
                                                        }}
                                                ></Button>
                                                <Button className={" ml-1"} btnText={"Save"} type={"submit"}
                                                        onClick={() => {
                                                            handleSavePackageChanges(index)
                                                        }}
                                                ></Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    )
                })
            }

        </>
    )
}

export default RecordingListSection;