import React, {ChangeEvent, FC, FormEvent, useState} from "react";
import Form from "../../components/common/form/Form"
import "./loginPage.modules.css"
// @ts-ignore
import logo from "../../images/logo.svg"
import Button from "../../components/common/form/Button";
import {useNavigate} from "react-router-dom";
import {useLoginUserMutation} from "./loginPageApi";
import {handleRTKQuery} from "../../helpers/utils/RtkQueryUtils";
import InputField from "../../components/common/form/InputField";
import {useDispatch, useSelector} from "react-redux";
import {clearRedirectPath} from "../../app/globalSlice/globalSlice";
import Footer from "../../components/layout/footer/Footer";

const LoginPage: FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const redirectPath = useSelector((state: any) => state.global.redirectPath);

    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
        userZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    const [loginUser, {isLoading}] = useLoginUserMutation();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleRTKQuery(
            async () => {
                return await loginUser(credentials).unwrap();
            },
            (response: any) => {
                if (response.token == null) {
                    navigate(`/verify-mfa`, {
                        state: {
                            username: credentials.username,
                            password: credentials.password,
                            mfaResponse: JSON.stringify(response)
                        }
                    });
                } else {
                    console.log("redirectPath", redirectPath);
                    navigate(redirectPath);
                    dispatch(clearRedirectPath());
                }
            }
        )
    };

    return (
        <>
            <section className="login_form flex items-center justify-center">
                <div className="container mx-auto px-4">
                    <div className="flex justify-center">
                        <div className="w-full max-w-xl lg:max-w-lg md:max-w-md">
                            <div className="log_in relative z-10">
                                <Form onSubmit={handleSubmit} fields={
                                    <>
                                        <div className="text-center mb-4">
                                            <img loading="lazy" src={logo} alt=""/>
                                        </div>
                                        <div className="form-group mb-4">
                                            <InputField
                                                name="username"
                                                type="text"
                                                value={credentials.username}
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder="Username/Email"
                                                required={true}
                                                hideLabel={true}
                                            />

                                        </div>

                                        <div className="form-group mb-4 relative">
                                            <InputField
                                                type={"password"}
                                                value={credentials.password}
                                                name="password"
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder="Password"
                                                required={true}
                                                hideLabel={true}
                                            />
                                        </div>

                                        <div className="form-group mb-4 ">
                                            <Button btnText={"Login"} isLoading={isLoading} type={"submit"}></Button>
                                        </div>

                                        <div className="forgot-password-txt mb-3">
                                            <button className="hover:text-blue-700 cursor-pointer"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate("/forgot-password")
                                                    }}>
                                                Forgot Password?
                                            </button>
                                        </div>
                                    </>
                                }/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}
export default LoginPage