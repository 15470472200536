import React, {FC, ReactNode, useMemo} from "react";
import TailSpinLoader from "../TailSpinLoader";

export type IconButtonProps = {
    icon: ReactNode;
    onClick?: () => void;
    label?: string;
    title?: string;
    className?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    hasBackground?: boolean;
};

const IconButton: FC<IconButtonProps> = ({
                                             icon,
                                             onClick,
                                             label,
                                             title,
                                             className,
                                             isLoading,
                                             isDisabled,
                                             hasBackground,
                                         }) => {

    const includesLabel = useMemo(() => !!label, [label]);

    const baseStyle = useMemo(() => {
        const sharedStyle = "rounded flex items-center";
        const labelStyle = includesLabel ? "text-sm px-1.5 py-0.5 gap-x-1.5" : "text-lg w-10";
        const additionalStyle = hasBackground && "justify-center";
        const backgroundStyle = hasBackground || includesLabel ? "text-text-1 bg-highlight-3 hover:bg-highlight-7" : "text-highlight-3 hover:text-highlight-7";

        return `${sharedStyle} ${labelStyle} ${additionalStyle} ${backgroundStyle}`;
    }, [includesLabel, hasBackground]);


    return (
        <div
            className={`${baseStyle} ${className} ${isDisabled ? "" : "cursor-pointer"}`}
            title={title}
            onClick={onClick}
        >
            {isLoading ? (label ? "Loading..." : "") : label}
            {isLoading ? <TailSpinLoader/> : icon}
        </div>
    );
};

export default IconButton;
