import ButtonTray from "../../../components/common/form/ButtonTray";
import Modal from "../../../components/common/form/Modal";
import React, {FC, FormEvent, useEffect, useState} from "react";
import {ModalProps} from "../../../helpers/constants";
import InputField from "../../../components/common/form/InputField";
import {
    useIssueVerificationTokenMutation,
    useVerifyMfaDeviceChallengeMutation,
} from "../mfaDeviceApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";

interface Props extends ModalProps {
    bodyMessage: string;
    id: string;
    type: string;
}

const VerifyMFADeviceModal: FC<Props> = ({show, setShow, bodyMessage, id, type}) => {
    const [solution, setSolution] = useState<string>("");
    const [verifyMfaChallengeApi, {isLoading: verifyMfaChallengeApiResponse}] = useVerifyMfaDeviceChallengeMutation();
    const [issueMfaTokenApi, {isLoading: issueMfaTokenApiLoading , data : issueMfaTokenApiResponse}] = useIssueVerificationTokenMutation();
    const [timer, setTimer] = useState(30);
    const [isResendDisabled, setIsResendDisabled] = useState(true);

    useEffect(() => {
        if (show) {
            setIsResendDisabled(true);
            setTimer(30);
        }
    }, [show]);

    useEffect(() => {
        if (timer > 0 && isResendDisabled) {
            const countdown = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
            return () => clearInterval(countdown);
        } else {
            setIsResendDisabled(false);
        }
    }, [timer, isResendDisabled]);

    useEffect(() => {
            issueMfaTokenApi(id);
    }, [id, issueMfaTokenApi, type]);


    const handleVerifyMfaDeviceSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (id && solution) {
            await handleRTKQuery(
                async () => {
                    return verifyMfaChallengeApi({id: id, challengeId: issueMfaTokenApiResponse?.id, solution: solution}).unwrap();
                },
                () => {
                    setShow(false);
                }
            );
        }
    };

    const handleIssuedMfaTokenSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (id) {
            return issueMfaTokenApi(id);
        }
    };

    return (
        <Modal
            title={"Verify MFA Device"}
            show={show}
            setShow={setShow}
            className={"w-2/5 mx-2"}
            fields={
                <>
                    <h3 className="mb-5 text-base font-normal text-text-1">{bodyMessage}</h3>

                    <InputField
                        hideLabel={true}
                        label=""
                        value={solution}
                        type="number"
                        onChange={(e : any) => setSolution(e.target.value)}
                        placeholder="Enter Verification Code..."
                    />

                    <ButtonTray
                        // @ts-ignore
                        buttons={[
                            {
                                buttonProps: {
                                    btnText: "Cancel",
                                    onClick: () => setShow(false),
                                    type: "close",
                                },
                                buttonType: "button",
                            },
                            {
                                buttonProps: {
                                    btnText: "Submit",
                                    onClick: async (e: any) => {
                                        e.preventDefault();
                                        await handleVerifyMfaDeviceSubmit(e);
                                    },
                                    type: "submit",
                                    isLoading: verifyMfaChallengeApiResponse,
                                    isDisabled: issueMfaTokenApiLoading || verifyMfaChallengeApiResponse || solution.length !== 6,
                                    title: solution.length !== 6 ? "Invalid code provided!" : ""
                                },
                                buttonType: "button",
                            },
                            type !== "AUTHENTICATOR" && {
                                buttonProps: {
                                    btnText: isResendDisabled ? `Re-send (${timer}s)` : "Re-send",
                                    onClick: async (e: any) => {
                                        e.preventDefault();
                                        await handleIssuedMfaTokenSubmit(e);
                                        setIsResendDisabled(true);
                                        setTimer(30);
                                    },
                                    type: "submit",
                                    isLoading: false,
                                    isDisabled: issueMfaTokenApiLoading || verifyMfaChallengeApiResponse || isResendDisabled,
                                },
                                buttonType: "button",
                            },
                        ].filter(Boolean)} // Filter out `false` values when type === "AUTHENTICATOR"
                        align={"end"}
                        gap={2}
                    />
                </>
            }
        />
    );
};

export default VerifyMFADeviceModal;
