import React, {FC} from "react";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {useGetProductByIdQuery} from "../productsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import OrderDetailInfo from "./OrderDetailInfo";
import {DetailRow, Separator} from "../../../helpers/utils/OrderDetailUtils";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";

interface Props {
    orderQuery: any,
    productId?: string | null;
}

const FincenProductDetailInfo: FC<Props> = ({productId, orderQuery}) => {

    const {data: enums} = useGetEnumsQuery("");

    const {
        data: productData,
        isLoading: isProductLoading,
        isError: isProductError,
    } = useGetProductByIdQuery(productId ?? skipToken);

    return (
        <>
            <div className="bg-surface-2 rounded-md rounded-tl-none p-3 pt-1.5">
                <OrderDetailInfo orderQuery={orderQuery}/>
                <DetailRow label="Status"
                           value={getEnumDisplayNameByConstant(enums?.["ProductStatus"], productData?.status)}
                           isLoading={isProductLoading}
                           isError={isProductError}
                           isValueSpan={true}/>

                <DetailRow
                    label="Created"
                    value={productData && formatValueAsDate(productData?.creationDate)}
                    isLoading={isProductLoading}
                    isError={isProductError}/>

                <DetailRow
                    label="Created by"
                    value={productData?.createdBy}
                    isLoading={isProductLoading}
                    isError={isProductError}/>

                <DetailRow label="Needed by"
                           value={formatValueAsDate(productData?.needByDate) || "-"}
                           isLoading={isProductLoading}
                           isError={isProductError}/>

                <DetailRow
                    label="Assignee"
                    value={productData?.assigneeName}
                    isLoading={isProductLoading}
                    isError={isProductError}/>

                {/*    TODO: Make a component to hold everything above separator and use it across all ProductDetailInfo variations*/}
                {/*    TODO: Put product-specific stuff below separator*/}

            </div>

        </>
    )
}

export default FincenProductDetailInfo;