import React, {useEffect, useState} from "react";
import Header from "../Header";
import CodeRequestSection from "../CodeRequestSection";
import {IoIosArrowDown} from "react-icons/io";
import SuccessPage from "../SuccessPage";
import Footer from "../../../components/layout/footer/Footer";
// @ts-ignore
import activeIcon from "../../../images/current.svg"

import QuestionnaireForm from "../form/QuestionnaireForm";
import ContactDetailPage from "../ContactDetailPage";
import ContactForm from "../form/ContactForm";
import MemberForm from "../form/MemberForm";
import SellerForm from "../form/SellerForm";
import BuyerForm from "../form/BuyerForm";
import {useParams} from "react-router-dom";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import {useGetContactByIdQuery} from "../contactApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useLazyFindPartyByIdQuery} from "../partyApi";
import SkeletonLoader from "../../../components/common/SkeletonLoader";
import SignatureForm from "../form/SignatureForm";
import VerifyCodeForm from "../form/VerifyCodeForm";
import {getCookie} from "../../../helpers/utils/Utils";
import ContainerCard from "../../../components/common/ContainerCard";
import {useLazyExistLatestMemberByContactIdQuery} from "../memberApi";
import {useLazyFindQuestionnaireByPartyIdQuery} from "../questionnaireApi";
import {useLazyFindLatestSellerByPartyIdQuery} from "../sellerApi";
import {useLazyGetProductByIdQuery} from "../../order/productsApi";
import {useLazyExistLatestBuyerByPartyIdQuery} from "../buyerApi";
import {useLazyFindLatestAttestationByContactIdQuery} from "../attestationApi";
import {jwtDecode} from "../../../helpers/utils/AccessControlUtils";

const StepperForm = () => {

    const {contactId} = useParams();
    const contactByIdApi = useGetContactByIdQuery(contactId ?? skipToken);
    const [productById, productByIdResponse] = useLazyGetProductByIdQuery();
    const [partyByIdApi, partyByIdResponse] = useLazyFindPartyByIdQuery();
    const [questionnaireByPartyIdApi, questionnaireByPartyIdResponse] = useLazyFindQuestionnaireByPartyIdQuery();
    const [latestBuyerByPartyIdApi, latestBuyerByPartyIdResponse] = useLazyFindLatestSellerByPartyIdQuery();
    const [latestSellerByPartyIdApi, latestSellerByPartyIdResponse] = useLazyExistLatestBuyerByPartyIdQuery();
    const [latestMemberByContactIdApi, latestMemberByContactIdResponse] = useLazyExistLatestMemberByContactIdQuery();
    const [latestAttestationByContactIdApi, latestAttestationByContactIdResponse] = useLazyFindLatestAttestationByContactIdQuery();

    const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>("");
    const [skippedTabs, setSkippedTabs] = useState<any>([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [targetTab, setTargetTab] = useState<string>("");
    const [tabsWithUnsavedChanges, setTabsWithUnsavedChanges] = useState<any>([]);
    const [completedSteps, setCompletedSteps] = useState<string[]>([]);
    const [isPrimaryContact, setIsPrimaryContact] = useState<any>(null);
    const [partyType, setPartyType] = useState<string>("");
    const [updatingContactId, setUpdatingContactId] = useState<string>("");
    const [contactReferenceId, setContactReferenceId] = useState<string>("");
    const [challengeId, setChallengeId] = useState<string>("");
    const [questionResponse, setQuestionResponse] = useState<any>([]);
    const [orgDetailData, setOrgDetailData] = useState<any>([]);
    const [memberDetailData, setMemberDetailData] = useState<any>([]);
    const [showOrderPageButton, setShowOrderPageButton] = useState<boolean>(false);

    useEffect(() => {
        let obj = contactByIdApi?.data;
        if (obj) {
            setIsPrimaryContact(obj.primaryContact);
            partyByIdApi(obj.partyId);
            const token = getCookie("authToken");
            if (!token) {
                setIsSidebarVisible(false);
                setActiveTab("request-verify-code");
            } else {
                let decode = jwtDecode();
                if (decode?.partyId) {
                    const hash = window.location.hash.replace("#", "");
                    if (hash && hash !== "request-verify-code") {
                        setIsSidebarVisible(true);
                    }
                    if (hash) {
                        setActiveTab(hash);
                    }
                } else {
                    setIsSidebarVisible(true);
                    latestAttestationByContactIdApi(contactId);
                    setShowOrderPageButton(true);
                    if (!obj.primaryContact) {
                        latestMemberByContactIdApi(contactId);
                    } else {
                        questionnaireByPartyIdApi(obj.partyId);
                        latestBuyerByPartyIdApi(obj.partyId);
                        latestSellerByPartyIdApi(obj.partyId);
                    }
                }
            }
        }
    }, [contactByIdApi?.data]);

    useEffect(() => {
        if (isPrimaryContact == null) {
            return;
        }

        completedSteps.push('verify-code');
        if (!isPrimaryContact) {
            if (latestMemberByContactIdResponse?.data && latestAttestationByContactIdResponse?.data) {
                completedSteps.push("member-detail");
                setActiveTab("success");
            } else if (latestMemberByContactIdResponse?.data && !latestAttestationByContactIdResponse?.data) {
                completedSteps.push("member-detail");
                setActiveTab("sign");
            } else {
                setActiveTab("member-detail");
            }
        } else {
            if (!questionnaireByPartyIdResponse?.data) {
                setActiveTab("questions");
            } else if (!latestBuyerByPartyIdResponse?.data && !latestSellerByPartyIdResponse?.data) {
                completedSteps.push("questions");
                setActiveTab("organization-detail");
            } else if (latestAttestationByContactIdResponse?.data) {
                completedSteps.push("organization-detail");
                completedSteps.push("sign");
                const hasTrueValue = Object.values(questionnaireByPartyIdResponse?.data).some(value => value === true);
                if (hasTrueValue) {
                    skippedTabs.push("organization-detail");
                    skippedTabs.push("verify-contact");
                }
                setActiveTab("success");
            } else {
                partyType === "SELLER" ? setActiveTab("sign") : setActiveTab("verify-contact");
            }
        }
    }, [
        questionnaireByPartyIdResponse?.data,
        latestBuyerByPartyIdResponse?.data,
        latestSellerByPartyIdResponse?.data,
        latestMemberByContactIdResponse?.data,
        latestAttestationByContactIdResponse?.data]);

    useEffect(() => {
        let obj = partyByIdResponse?.data;

        if (obj) {
            setPartyType(obj.type);
            getCookie("authToken") && productById(obj?.productId);
        }

    }, [partyByIdResponse?.data]);


    useEffect(() => {
        setMemberDetailData(latestMemberByContactIdResponse?.data);
    }, [latestMemberByContactIdResponse?.data]);

    useEffect(() => {
        setQuestionResponse(questionnaireByPartyIdResponse?.data);
    }, [questionnaireByPartyIdResponse?.data]);

    // Update the URL hash when the selectedTab changes
    useEffect(() => {
        if (activeTab) {
            window.location.hash = activeTab;
        }
    }, [activeTab]);

    const markStepCompleted = (step: string) => {
        setCompletedSteps((prev) => [...prev, step]);
    };

    const allowedSteps = isPrimaryContact
        ? (partyType === "SELLER" ? [
                {label: 'Authenticate', key: 'verify-code', isClickable: false},
                {
                    label: 'Answer pre-qualification questions',
                    key: 'questions',
                    isClickable: completedSteps.includes('verify-code')
                },
                {
                    label: 'Enter organization details',
                    key: 'organization-detail',
                    isClickable: completedSteps.includes('verify-code') && completedSteps.includes('questions')
                },
                {label: 'Sign & Complete', key: 'sign', isClickable: completedSteps.includes('sign')},
            ] :
            [{label: 'Authenticate', key: 'verify-code', isClickable: false},
                {
                    label: 'Answer pre-qualification questions',
                    key: 'questions',
                    isClickable: completedSteps.includes('verify-code')
                },
                {
                    label: 'Enter organization details',
                    key: 'organization-detail',
                    isClickable: completedSteps.includes('verify-code') && completedSteps.includes('questions')
                },
                {
                    label: 'Add & Verify contacts',
                    key: 'verify-contact',
                    isClickable: completedSteps.includes('verify-code') && completedSteps.includes('questions')
                },
                {label: 'Sign & Complete', key: 'sign', isClickable: completedSteps.includes('sign')}])
        : [
            {label: 'Request & Verify Code', key: 'verify-code', isClickable: false},
            {label: 'Member Information', key: 'member-detail', isClickable: completedSteps.includes('member-detail')},
            {label: 'Sign & Complete', key: 'sign'},
        ];

    const stepsConfig: Record<string, React.ElementType | ((props: any) => JSX.Element)> = {
        "verify-code": (props: any) => <VerifyCodeForm {...props} onComplete={() => markStepCompleted('verify-code')}/>,
        "questions": (props: any) => <QuestionnaireForm {...props} onComplete={() => markStepCompleted('questions')}
                                                        setQuestionResponse={setQuestionResponse}/>,
        "organization-detail": (props: any) => partyType === "SELLER" ?
            <SellerForm {...props} setOrgDetailData={setOrgDetailData}/> :
            <BuyerForm {...props} setOrgDetailData={setOrgDetailData}/>,
        "verify-contact": (props: any) => <ContactDetailPage {...props} setUpdatingContactId={setUpdatingContactId}/>,
        "add-contact": (props: any) => <ContactForm {...props} formType="Add"
                                                    setContactReferenceId={setContactReferenceId}/>,
        "update-contact": (props: any) => <ContactForm {...props} formType="Update"
                                                       updatingContactId={updatingContactId}/>,
        "member-detail": (props: any) => <MemberForm {...props} contactReferenceId={contactReferenceId}
                                                     setMemberDetailData={setMemberDetailData}/>,
        "sign": (props: any) => <SignatureForm {...props} questionResponse={questionResponse}
                                               orgDetailData={orgDetailData} memberDetailData={memberDetailData}/>,
        "success": () => <SuccessPage/>,
    };

    const FincenLandingPageExplainer = () => {
        return <p className='text-center text-text-1 text-xl text-wrap p-16'>
            FinCEN Settlement Reporting has been requested by Stewart Title Company to
            gather certain information about your entity or trust in relation to your current
            contemplated real estate transaction in order to ensure compliance with
            guidelines
            issued by the Financial Crimes Enforcement Network (FinCEN)
        </p>;
    }

    const FinCenBackgroundCard = () => {
        return <div className='px-16 py-16'>
            <ContainerCard title='FinCEN Reporting Background' contents={
                <p className='text-text-1 text-wrap p-4'>
                    In 2024, FinCEN (a department of the US Treasury) issued a rule requiring that real estate
                    transactions which meet
                    certain criteria be reported through a prescribed process. FinCEN Settlement Reporting specializes
                    in collecting and
                    reviewing certain transaction details to confirm whether they would meet the criteria to be reported
                    and then
                    subsequently reporting if appropriate.
                    <br/><br/>
                    Please be aware that you will be certifying electronically that the information you have provided is
                    true and
                    accurate to the best of your knowledge. Also, please note that delays in submitting information
                    through the attached
                    link could also cause delays in completing the associated real estate transaction.
                    <br/><br/>
                    Included for your reference are sources of related information:
                    <br/><a className={'cursor-pointer text-highlight-3 hover:text-highlight-7'}
                            href="https://fincen.gov">FinCEN Home</a>
                    <br/><a className={'cursor-pointer text-highlight-3 hover:text-highlight-7'}
                            href="https://fincen.gov/anti-money-laundering-act-2020">The Anti-Money Laundering Act of
                    2020</a>
                    <br/><br/>
                    <i>
                        If you have any additional questions, please contact FinCEN Settlement Reporting at:
                        <br/>
                        <a className={'cursor-pointer text-highlight-3 hover:text-highlight-7'}
                           href="tel:+1111111111">(111) 111-1111
                        </a>
                        <br/>
                        <a className={'cursor-pointer text-highlight-3 hover:text-highlight-7'}
                           href="mailto:support@fincensettlementreporting.com">support@fincensettlementreporting.com
                        </a>
                        <br/>Hours: 8:00 AM - 5:00 PM Central Standard Time
                    </i>
                </p>}/>
        </div>;
    }

    const CollapsedStepperSkeleton = () => {
        return <div className="w-full max-w-md m-auto justify-center px-4 py-2 mt-3">
            {Array.from({length: 6}).map((_, index) => (
                <div key={index} className="bg-surface-2 border border-surface-2 rounded-xl mb-3">
                    <div className="cursor-pointer justify-between p-3">
                        <div className="gap-1 items-center">
                            <SkeletonLoader count={1}/>
                        </div>
                    </div>
                </div>
            ))}
        </div>;
    }

    const CollapsedStepper = () => {
        return <div className="w-full max-w-md m-auto justify-center px-4 py-2 mt-3 grow">
            <ul className="pl-4 before:absolute before:left-0 before:top-6 before:bottom-0 before:w-[2px] before:h-[calc(100%-3rem)] before:bg-highlight-1 relative">
                <li className="relative mt-3">
                    <img className="absolute left-[-24px] top-3 w-5 h-5" src={activeIcon}
                         alt={"current"}/>
                    <CodeRequestSection setIsSidebarVisible={setIsSidebarVisible}
                                        phoneNumber={contactByIdApi?.data?.phoneNumber}
                                        setChallengeId={setChallengeId}
                                        setActiveTab={setActiveTab}/>
                </li>
                {allowedSteps.map((item, index) => (
                    index > 0 &&
                    <li key={item.key}
                        className="relative mt-3">
                        <i className="absolute left-[-21px] top-3 fa-solid fa-circle text-highlight-0 text-xs"/>
                        <div className="bg-surface-2 border border-surface-2 rounded-xl">
                            <div className="cursor-pointer flex justify-between p-3">
                                <div className="flex gap-1 items-center">
                                    <h3 className="text-text-2">{item.label}</h3>
                                </div>
                                <div className="flex gap-3">
                                    <IoIosArrowDown className="text-lg cursor-pointer"/>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>;
    }

    const StepperSideBar = () => {
        return <aside
            className="pt-12 left-0 sm:translate-x-0 min-h-screen w-52 text-text-1 top-0 transition-transform z-40 bg-surface-2 fixed h-screen">
            <div className="sm:mt-5 md:mt-0 lg:mt-0 pl-4">
                <ul className="pl-4 before:absolute before:left-0 before:top-4 before:bottom-0 before:w-[2px] before:h-[calc(100%-2rem)] before:bg-highlight-1 relative">
                    {allowedSteps.map((tab: any, index: number) => {

                        const tabIndex = ["verify-code", "questions", "organization-detail", "verify-contact", "add-contact", "member-detail", "sign", "success"].indexOf(tab.key);
                        const currentIndex = ["verify-code", "questions", "organization-detail", "verify-contact", "add-contact", "member-detail", "sign", "success"].indexOf(activeTab);

                        return (
                            <li key={index}
                                className={`p-2 relative mt-3 ${tab.isClickable && tab.key !== activeTab && !skippedTabs.includes(tab.key) ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (tab.isClickable && tab.key !== activeTab && !skippedTabs.includes(tab.key)) {
                                        setTargetTab(tab.key);
                                        setShowConfirmationModal(true);
                                    }
                                }}>
                                {activeTab === tab.key &&
                                    <img className="absolute left-[-24px] top-3 w-5 h-5"
                                         src={activeIcon}
                                         alt={"dd"}/>}

                                {activeTab !== tab.key && tabIndex > currentIndex &&
                                    <i className="absolute left-[-21px] top-3 fa-solid fa-circle text-highlight-0 text-xs"/>
                                }

                                {tabIndex < currentIndex ? (
                                    <>
                                        <i className="absolute left-[-21px] top-3 fa-solid fa-circle text-text-1 text-xs"/>
                                        <i className={`absolute left-[-23px] top-3 ${skippedTabs.includes(tab.key) ? `fa-solid fa-circle-minus text-surface-6` :
                                            (tabsWithUnsavedChanges.includes(tab.key) ? `fa-sharp fa-solid fa-circle-exclamation text-warn-3` : `fa-solid fa-circle-check text-highlight-0`)}`}/>
                                    </>
                                ) : null}
                                <p className='text-sm'>{tab.label}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </aside>;
    }
    const ContextualHeader = () => {
        return <div className={"mt-2"}>
            <span className={"font-bold text-2xl"}>{partyByIdResponse?.data?.name}</span>
            <br/>
            <span>{contactByIdApi?.data?.name}</span>
        </div>;
    }

    const StepContainer = () => {
        return <>
            <div className={'items-center w-3/4 p-2'}>
                {/*@ts-ignore*/}
                {stepsConfig[activeTab] && stepsConfig[activeTab]({
                    setActiveTab,
                    setTabsWithUnsavedChanges,
                    skippedTabs,
                    tabsWithUnsavedChanges,
                    activeTab,
                    setSkippedTabs,
                    partyId: contactByIdApi?.data?.partyId,
                    phoneNumber: contactByIdApi?.data?.phoneNumber,
                    isPrimaryContact,
                    challengeId,
                    partyType,
                    productId: partyByIdResponse?.data?.productId,
                    showOrderPageButton
                })
                }
            </div>
        </>;
    }
    const MainContentContainer = () => {
        return <div className="flex flex-col flex-1 grow text-text-1">
            <div className="relative w-100 lg:pl-64 md:pl-64 sm:pl-0">
                <ContextualHeader/>
                <div className="justify-center mt-4 grow flex items-start">
                    <StepContainer/>
                </div>
            </div>
        </div>
    }

    return (
        <>
            <div className="pt-12 min-h-screen flex flex-col">
                <Header productByIdResponse={productByIdResponse} showOrderPageButton={showOrderPageButton}/>

                {!isSidebarVisible ?
                    (contactByIdApi.isLoading ?
                            <CollapsedStepperSkeleton/>
                            :
                            <div className={'w-1/2 m-auto justify-center'}>
                                <FincenLandingPageExplainer/>
                                <CollapsedStepper/>
                                <FinCenBackgroundCard/>
                            </div>
                    )
                    :
                    <>
                        <div className="grow">
                            <StepperSideBar/>
                            <MainContentContainer/>
                        </div>
                    </>
                }

                <Footer/>
            </div>

            {
                showConfirmationModal && <ConfirmationModal title={"Unsaved Changes Warning"}
                                                            bodyMessage={"You have unsaved changes. If you leave now, any unsaved information will be lost. Are you sure you want to continue?"}
                                                            onSubmit={() => {
                                                                tabsWithUnsavedChanges.push(activeTab);
                                                                setActiveTab(targetTab);
                                                            }}
                                                            isLoading={false}
                                                            show={showConfirmationModal}
                                                            setShow={setShowConfirmationModal}/>
            }
        </>
    );
};

export default StepperForm;
