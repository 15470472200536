import React from 'react';
import Button, {ButtonProps} from "./Button";
import ModalButton, {ModalButtonProps} from "./ModalButton";
import IconButton, {IconButtonProps} from "./IconButton"; // Assuming you have an IconButton component

interface ModalButtonProp extends ModalButtonProps {
    isVisible?: boolean
}

interface IconButtonProp extends IconButtonProps {
    isVisible?: boolean
}

interface ButtonTrayProps {
    buttons: Array<{
        buttonType: 'button' | 'modal' | 'icon';
        buttonProps: ButtonProps | ModalButtonProps | IconButtonProp;
    }>;
    align?: 'start' | 'center' | 'end';
    gap?: number;
    addTopLine?: boolean
}

const ButtonTray: React.FC<ButtonTrayProps> = ({
                                                   buttons,
                                                   align = 'end',
                                                   gap = 1,
                                                   addTopLine = true
                                               }) => {

    const hasButtonTypeButton = buttons.some(button => button.buttonType === 'button');

    return (
        <div
            className={`flex justify-${align} gap-${gap} ${hasButtonTypeButton  ? 'pt-4 mt-4 ' : ''} ${addTopLine ? 'border-t border-surface-3' : ''}`}
        >
            {buttons.map((button, index) => {
                if (button.buttonType === 'button') {
                    const {
                        btnText,
                        title,
                        onClick,
                        type,
                        isLoading,
                        isVisible,
                        isDisabled
                    } = button.buttonProps as ButtonProps;
                    return isVisible !== false && (
                        <Button
                            key={index}
                            btnText={btnText || ''}
                            title={title}
                            onClick={onClick}
                            type={type || 'button'}
                            isLoading={isLoading || false}
                            isDisabled={isDisabled || false}
                        />
                    );
                } else if (button.buttonType === 'modal') {
                    const {
                        modal,
                        triggerButton,
                        onOpen,
                        isVisible
                    } = button.buttonProps as ModalButtonProp;
                    return isVisible !== false && (
                        <ModalButton
                            modal={modal}
                            triggerButton={triggerButton}
                            onOpen={onOpen}
                        />
                    );
                } else if (button.buttonType === 'icon') {
                    const {
                        icon,
                        className,
                        label,
                        title,
                        onClick,
                        isLoading,
                        isVisible
                    } = button.buttonProps as IconButtonProp;
                    return isVisible !== false && (
                        <IconButton
                            key={index}
                            className={className}
                            icon={icon}
                            label={label} // Display text if provided
                            title={title}
                            onClick={onClick}
                            isLoading={isLoading || false}
                        />
                    )
                }
                return null;
            })}
        </div>
    );
};

export default ButtonTray;
