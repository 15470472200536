import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryError, handleQueryErrorAndSuccess} from "../../helpers/utils/RtkQueryUtils";

export const contactApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        createContact: build.mutation({
            query: ({requestBody, isSendLink = false}) => {
                return {
                    url: `/fincen-contact?isSendLink=${isSendLink}`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['contactsByPartyId', 'contactById', 'partiesByProductId'],
            async onQueryStarted({isSendLink}, {queryFulfilled}) {
                const successMessage = isSendLink ? "Contact created & link sent" : "Contact created";
                await handleQueryErrorAndSuccess(queryFulfilled, "", successMessage);
            },
        }),

        getContactById: build.query({
            query: (id) => {
                return {
                    url: `/fincen-contact/${id}`,
                    method: 'GET'
                };
            },
            providesTags: ['contactById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        updateContact: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/fincen-contact/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['contactsByPartyId', 'contactById', 'partiesByProductId'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Contact");
            },
        }),

        getAllContactByPartyId: build.query({
            query: (partyId) => {
                return {
                    url: `/fincen-contact/partyId/${partyId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ['contactsByPartyId'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled);
            },
        }),

        deleteContactById: build.mutation({
            query: (id) => {
                return {
                    url: `/fincen-contact/${id}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader()
                };
            },
            invalidatesTags: ['contactsByPartyId', 'partiesByProductId'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Deleted", "Contact");
            },
        }),

        sendFincenLink: build.mutation({
            query: (contactId) => {
                return {
                    url: `/fincen-contact/sendLink/${contactId}`,
                    method: 'POST',
                    headers: getAuthorizationHeader()
                };
            },
            invalidatesTags: ['partiesByProductId', 'partiesByProductId'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled);
            },
        }),
    })
})

export const {
    useCreateContactMutation,
    useDeleteContactByIdMutation,
    useGetAllContactByPartyIdQuery,
    useGetContactByIdQuery,
    useUpdateContactMutation,
    useSendFincenLinkMutation
} = contactApi;