import Breadcrumb, {Crumb} from "../../../components/common/Breadcrumbs";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetAllStatesByCountryIdQuery, useGetAllStatesQuery} from "../states/statesApi";
import {useCreateMetroAreaMutation, useGetMetroAreaByIdQuery, useUpdateMetroAreaMutation} from "./metroAreasApi";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import Form from "../../../components/common/form/Form";
import InputField from "../../../components/common/form/InputField";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import SelectField from "../../../components/common/form/SelectField";
import {useGetAllCountyByStateAbbrQuery} from "../counties/countiesApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {Routes as AppRoutes} from "../../../routes";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface props {
    crumbs: Crumb[];
    title: string,
    btnText: string,
    isEditable?: boolean
}

const MetroAreaForm: FC<props> = ({crumbs, title, btnText, isEditable = true}) => {

    const navigate = useNavigate();
    let {metroAreaId} = useParams();

    const [createMetroArea, createMetroAreaApiResponse] = useCreateMetroAreaMutation();
    const [updateMetroArea, updateMetroAreaApiResponse] = useUpdateMetroAreaMutation();
    const metroAreaByIdApi = useGetMetroAreaByIdQuery(metroAreaId ?? skipToken);
    const [metroAreaData, setMetroAreaData] = useState<any>({});
    const statesApiResult = useGetAllStatesByCountryIdQuery("236b617cc-105e-4f73-a91c-e2162beac864");;
    const countiesApiResult = useGetAllCountyByStateAbbrQuery(metroAreaData?.state?.value ?? skipToken);


    useEffect(() => {
        let obj = metroAreaByIdApi?.data;
        if (obj) {
            setMetroAreaData({
                ...obj,
                state: {
                    label: obj.state?.name,
                    value: obj.state?.abbreviation,
                },
                counties: obj.counties?.map((el: any) => ({
                    label: el?.name,
                    value: el?.id,
                }))
            })
        }
    }, [metroAreaByIdApi?.data]);

    useEffect(() => {
        let state = metroAreaData?.state?.value;
        if (!state) {
            setMetroAreaData({
                ...metroAreaData,
                counties: []
            });
        }
    }, [metroAreaData?.state]);


    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let commonObj = {
            ...metroAreaData,
            state: statesApiResult?.data.filter((el: any) => el.abbreviation === metroAreaData.state.value)?.[0],
            counties: metroAreaData.counties?.map((el: any) => {
                return countiesApiResult?.data?.filter((ml: any) => ml.id === el.value)?.[0]
            })
        };

        const createOrUpdateMetroArea = async () => {
            if (btnText === "Create") {
                await createMetroArea(commonObj).unwrap();
            } else {
                await updateMetroArea({
                    id: metroAreaId,
                    requestBody: commonObj,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateMetroArea, () => {
            setMetroAreaData({});
            navigate("/metro-areas");
        });
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setMetroAreaData({
            ...metroAreaData,
            [name]: value,
        })
    }

    return (
        <>
            <div className="px-4 py-2">

                <div className={"justify-between sm:block md:flex lg:flex"}>
                    <Breadcrumb crumbs={crumbs}/>
                    <AuditableInfoButton obj={metroAreaByIdApi?.data}/>
                </div>

                <div className="flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">{title}</h3>
                        <Form onSubmit={handleSubmit} fields={<>
                            <div className="grid grid-cols-12">
                                <InputField
                                    name={"name"}
                                    label="Name"
                                    onChange={handleOnChange}
                                    value={metroAreaData?.name || ""}
                                    type="text"
                                    placeholder="Enter Name..."
                                    required={true}
                                />

                                <SelectField
                                    placeholder='Select State...'
                                    value={metroAreaData?.state}
                                    options={createOptionListForSelectTag(statesApiResult?.data, "name", "abbreviation")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "state", value: selectedOption})
                                    }}
                                    label={"State"}
                                    required={true}
                                />

                                <SelectField
                                    placeholder='Select Counties...'
                                    value={metroAreaData?.counties}
                                    options={createOptionListForSelectTag(countiesApiResult?.data, "name", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "counties", value: selectedOption})
                                    }}
                                    label={"Counties"}
                                    required={true}
                                    isMulti={true}
                                />

                            </div>


                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Cancel",
                                            onClick: () => navigate(AppRoutes.MetroAreas.path),
                                            type: "close",
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Submit",
                                            type: "submit",
                                            isLoading: (createMetroAreaApiResponse.isLoading || updateMetroAreaApiResponse.isLoading),
                                            isVisible: isEditable
                                        },
                                        buttonType: "button",

                                    }
                                ]}
                                align="end"
                                gap={2}
                            />

                        </>}
                        />
                    </div>
                </div>
            </div>
        </>

    )
}
export default MetroAreaForm;