import {useNavigate, useParams} from "react-router-dom";
import {useGetCannedNoteByIdQuery, useUpdateCannedNoteMutation} from "./cannedNotesApi";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import Form from "../../../components/common/form/Form";
import InputField from "../../../components/common/form/InputField";
import TextAreaField from "../../../components/common/form/TextAreaField";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import SelectField from "../../../components/common/form/SelectField";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {Routes as AppRoutes} from "../../../routes";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";

const UpdateCannedNote = () => {
    const navigate = useNavigate();
    let {noteId} = useParams();

    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getCannedNoteById, {noteId}, {
        redirectToErrorPage: true,
        skip: !noteId
    });

    const cannedNoteApi = useGetCannedNoteByIdQuery(noteId);
    const [cannedNoteData, setCannedNoteData] = useState<any>({});
    const [updateCannedNoteApi, updateCannedNoteApiResponse] = useUpdateCannedNoteMutation()

    const [crumbs, setCrumbs] = useState<any[]>([]);
    const {data: enums} = useGetEnumsQuery("");

    useEffect(() => {
        let obj = cannedNoteApi?.data;
        if (obj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Canned Notes", url: "/canned-notes"},
                {content: `Note ${obj?.noteNumber}`, url: ""}
            ]);

            document.title = `${obj?.noteNumber}`;
        }
    }, [cannedNoteApi]);


    useEffect(() => {
        let obj = cannedNoteApi?.data;
        if (obj) {
            setCannedNoteData({
                ...obj,
                type: obj.type ? {
                    value: obj.type,
                    label: getEnumDisplayNameByConstant(
                        enums?.["CertificateNoteType"],
                        obj.type
                    ),
                } : null,
            });
        }
    }, [cannedNoteApi?.data]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let obj = {
            ...cannedNoteData,
            type: cannedNoteData.type?.value,
        }

        const UpdateSystemProperty = async () => {
            await updateCannedNoteApi({
                id: noteId,
                requestBody: obj,
            }).unwrap();
        };

        await handleRTKQuery(UpdateSystemProperty, () => {
            setCannedNoteData({});
            navigate(AppRoutes.CannedNotes.path);
        });

    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | {
        name: string;
        value: any
    } | ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = "target" in e ? e.target : e;
        setCannedNoteData({
            ...cannedNoteData,
            [name]: value,
        })
    }

    const handleToggle = (property: string) => () => {
        setCannedNoteData((prevData: any) => ({
            ...prevData,
            [property]: !prevData[property]
        }));
    };

    return (
        <>
            <div className="px-4 py-2">
                <div className={"justify-between sm:block md:flex lg:flex"}>
                    <Breadcrumb crumbs={crumbs}/>
                    <AuditableInfoButton obj={cannedNoteApi?.data}/>
                </div>
                <div className="flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">Update Canned
                            Note</h3>
                        <Form onSubmit={handleSubmit} fields={<>
                            <div className="grid grid-cols-12">
                                <InputField
                                    name={"noteNumber"}
                                    label="Note No."
                                    onChange={handleOnChange}
                                    value={cannedNoteData?.noteNumber || ""}
                                    type="number"
                                    placeholder="Enter Note No...."
                                    required={true}
                                    disabled={true}
                                />
                                <TextAreaField
                                    label={"Comment"}
                                    placeholder={"Enter Comment...."}
                                    onChange={handleOnChange}
                                    required={true}
                                    name={"comment"}
                                    value={cannedNoteData?.comment || ""}
                                />

                                <SelectField
                                    placeholder='Select Note Type...'
                                    value={cannedNoteData?.type || null}
                                    options={createOptionListForSelectTag(enums?.["CertificateNoteType"], "displayName", "constant")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "type", value: selectedOption})
                                    }}
                                    required={true}
                                    label={"Note Type"}
                                />

                                <InputField
                                    name="bold"
                                    label="Bold"
                                    onChange={handleToggle("bold")}
                                    checked={cannedNoteData.bold || ""}
                                    type="checkbox"
                                />

                                <InputField
                                    name="yellowHighlight"
                                    label="Yellow Highlight"
                                    onChange={handleToggle("yellowHighlight")}
                                    checked={cannedNoteData.yellowHighlight || ""}
                                    type="checkbox"
                                />

                                <InputField
                                    name="redColor"
                                    label="Red Color"
                                    onChange={handleToggle("redColor")}
                                    checked={cannedNoteData.redColor || ""}
                                    type="checkbox"
                                />

                            </div>

                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Cancel",
                                            onClick: () => navigate(AppRoutes.CannedNotes.path),
                                            type: "close",
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Submit",
                                            type: "submit",
                                            isLoading: (updateCannedNoteApiResponse.isLoading),
                                            isVisible: hasPermission(["CANNOTE-U"])
                                        },
                                        buttonType: "button",

                                    }
                                ]}
                                align="end"
                                gap={2}
                            />

                        </>}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateCannedNote;