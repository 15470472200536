import InputField from "../../../../components/common/form/InputField";
import SelectField from "../../../../components/common/form/SelectField";
import {
    createOptionListForSelectTag,
    getBooleanDisplayIcon,
} from "../../../../helpers/utils/Utils";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import {useGetEnumsQuery} from "../../../../app/globalApi";
import {useGetAllStatesByCountryIdQuery, useGetAllStatesQuery} from "../../../data/states/statesApi";
import {OptionType} from "../../../../helpers/utils/StyleUtils";
import Form from "../../../../components/common/form/Form";
import {handleRTKQuery} from "../../../../helpers/utils/RtkQueryUtils";
import {useCreateBranchMutation, useGetBranchByIdQuery} from "../branchApi";
import {useGetCustomerByIdQuery} from "../customerApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useUpdateBranchMutation} from "../branchApi";
import {getEnumDisplayNameByConstant} from "../../../../helpers/utils/EnumUtils";
import ButtonTray from "../../../../components/common/form/ButtonTray";

interface props {
    branchId?: string,
    customerId: string | undefined,
    setShow?: any,
    btnText: string,
    cancelBtnText: string
    isEditable?: boolean
}

const BranchForm: FC<props> = ({branchId, customerId, setShow, btnText, cancelBtnText, isEditable = true}) => {

    const {data: enums} = useGetEnumsQuery("");
    const statesApiResult = useGetAllStatesByCountryIdQuery("236b617cc-105e-4f73-a91c-e2162beac864");
    const [branchData, setBranchData] = useState<any>({});
    const [createBranchApi, createBranchApiResponse] = useCreateBranchMutation();
    const [updateBranchApi, updateBranchApiResponse] = useUpdateBranchMutation();
    const customerQuery = useGetCustomerByIdQuery(customerId ?? skipToken);
    const branchQuery = useGetBranchByIdQuery(
        branchId ? {
            branchId: branchId
        } : skipToken,
    );

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setBranchData({
            ...branchData,
            [name]: value,
        })
    }

    useEffect(() => {
        let obj = branchQuery?.data;
        if (obj) {
            setBranchData(transformBranchData(obj));
        }
    }, [branchQuery?.data, statesApiResult?.data]);

    const handleToggle = (property: string) => (e: any) => {

        setBranchData((prevData: any) => ({
            ...prevData,
            [property]: !prevData[property]
        }));
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let obj = {
            ...branchData,
            deliveryBlacklist: getBlackListArray(branchData.deliveryWhitelist)?.map((el: any) => {
                return el.constant
            }),
            availableProduct: branchData.availableProduct?.map((el: any) => {
                return el.value
            }),
            emailNotificationMode: branchData.emailNotificationMode?.value,
            preferredState: branchData.preferredState?.value,
            includedParcelCount: 1,
            tenantId: customerQuery?.data.tenantId,
        }

        // const createOrUpdate = async () => {
        if (btnText === "Create") {
            // await createBranchApi({customerId: customerId, requestBody: obj}).unwrap();
            await handleRTKQuery(
                async () => {
                    return await createBranchApi({customerId: customerId, requestBody: obj}).unwrap();
                },
                () => {
                    setBranchData({});
                    setShow(false);
                }
            );
        } else {
            await updateBranchApi({
                customerId: customerId,
                branchId: branchId,
                requestBody: obj,
            }).unwrap();
        }

    }

    // Return blacklist array by filtering out whitelist items from all ProductTypes.
    const getBlackListArray = (deliveryWhitelist: { label: string, value: any }[]): any[] => {
        if (enums?.["ProductType"] && deliveryWhitelist && deliveryWhitelist.length > 0) {
            const whitelistValues = deliveryWhitelist.map(item => item.value);
            return enums["ProductType"].filter((productType: any) =>
                !whitelistValues.includes(productType.constant)
            );
        }
        return [];
    };

    const getWhiteListArray = (deliveryBlacklist: string[]): any[] => {
        if (enums?.["ProductType"] && deliveryBlacklist && deliveryBlacklist.length > 0) {
            return enums["ProductType"].filter((productType: any) =>
                !deliveryBlacklist.includes(productType.constant)
            );
        }
        return [];
    };

    const handleCloseBtnClick = () => {
        if (btnText === "Create") {
            setShow(false);
        } else {
            let obj = branchQuery?.data;
            if (obj) {
                setBranchData(transformBranchData(obj));
            }
        }
    };

    const transformBranchData = (obj: any) => {
        if (!obj) return null;

        return {
            ...obj,
            preferredState: obj.preferredState
                ? {
                    label: statesApiResult?.data?.find(
                        (el: any) => el.id === obj.preferredState
                    )?.name,
                    value: obj.preferredState,
                }
                : null,
            emailNotificationMode: obj.emailNotificationMode
                ? {
                    value: obj.emailNotificationMode,
                    label: getEnumDisplayNameByConstant(
                        enums?.["EmailNotificationMode"],
                        obj.emailNotificationMode
                    ),
                }
                : null,
            availableProduct: obj?.availableProduct
                ? obj?.availableProduct.map((el: any) => ({
                    value: el,
                    label: getEnumDisplayNameByConstant(
                        enums?.["ProductType"],
                        el
                    ),
                }))
                : [],
            deliveryWhitelist: obj?.deliveryBlacklist
                ? getWhiteListArray(obj.deliveryBlacklist).map((el) => ({
                    value: el.constant,
                    label: el.displayName,
                }))
                : [],
        };
    };

    return (
        <>
            <Form onSubmit={handleSubmit} fields={
                <>
                    <InputField
                        name={"name"}
                        label="Branch Name"
                        onChange={handleOnChange}
                        value={branchData?.name || ""}
                        type="text"
                        placeholder="Enter Branch Name..."
                        required={true}
                    />

                    <InputField
                        name={"displayLabel"}
                        label="Display Label"
                        onChange={handleOnChange}
                        value={branchData?.displayLabel || ""}
                        type="text"
                        placeholder="Enter Display Label..."
                    />

                    {branchQuery?.data && <div className="col-span-12 md:grid md:grid-cols-12 gap-3 ">
                        <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                            <div className="text-text-1">Is Default</div>
                        </div>
                        <div className="col-span-9 mt-2">
                            {getBooleanDisplayIcon(branchData?.defaultBranch, "Enabled", "Disabled", '')}
                        </div>
                    </div>
                    }

                    <SelectField
                        placeholder='Select Available Products...'
                        value={branchData?.availableProduct || null}
                        options={createOptionListForSelectTag(enums?.["ProductType"], "displayName", "constant")}
                        onChange={(selectedOption) => {
                            handleOnChange({name: "availableProduct", value: selectedOption})
                        }}
                        label={"Available Products"}
                        isMulti={true}
                    />

                    <SelectField
                        placeholder='Select Auto Delivery...'
                        value={branchData?.deliveryWhitelist || null}
                        options={createOptionListForSelectTag(enums?.["ProductType"], "displayName", "constant")}
                        onChange={(selectedOption) => {
                            handleOnChange({name: "deliveryWhitelist", value: selectedOption})
                        }}
                        label={"Auto Delivery"}
                        isMulti={true}
                    />

                    <SelectField
                        placeholder='Select Email Notification Mode...'
                        value={branchData?.emailNotificationMode || null}
                        options={createOptionListForSelectTag(enums?.["EmailNotificationMode"], "displayName", "constant")}
                        onChange={(selectedOption) => {
                            handleOnChange({name: "emailNotificationMode", value: selectedOption})
                        }}
                        label={"Notification Mode"}
                        required={true}
                    />

                    <SelectField
                        placeholder='Select Preferred State...'
                        value={branchData?.preferredState}
                        options={createOptionListForSelectTag(statesApiResult?.data, "name", "id")}
                        onChange={(selectedOption) => {
                            handleOnChange({name: "preferredState", value: selectedOption})
                        }}
                        label={"Preferred State"}
                    />

                    <InputField
                        name={"salesTaxRate"}
                        label="Sales Tax Rate"
                        onChange={handleOnChange}
                        value={branchData?.salesTaxRate || ""}
                        type="number"
                        placeholder="Enter Sales Tax Rate..."
                    />

                    <InputField
                        name={"partnerBranchId"}
                        label="Partner Branch ID"
                        onChange={handleOnChange}
                        value={branchData?.partnerBranchId || ""}
                        type="text"
                        placeholder="Enter Partner Branch ID..."
                    />

                    <InputField
                        name={"revenueEnabled"}
                        label="Is Revenue Enabled"
                        onClick={handleToggle("revenueEnabled")}
                        checked={branchData?.revenueEnabled || ""}
                        type='toggle'
                    />


                    <ButtonTray
                        buttons={[
                            {
                                buttonProps: {
                                    btnText: "Cancel",
                                    onClick: handleCloseBtnClick,
                                    type: "close",
                                },
                                buttonType: "button"
                            },
                            {
                                buttonProps: {
                                    btnText: "Submit",
                                    type: "submit",
                                    isLoading: (createBranchApiResponse.isLoading || updateBranchApiResponse.isLoading),
                                    isVisible: isEditable
                                },
                                buttonType: "button",

                            }
                        ]}
                        align="end"
                        gap={2}
                    />

                </>
            }/>

        </>
    )
}
export default BranchForm;