import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryError, handleQueryErrorAndSuccess} from "../../helpers/utils/RtkQueryUtils";

export const sellerApi = strApi.injectEndpoints({

    endpoints: (build) => ({

        createSeller: build.mutation({
            query: ({requestBody}) => {
                return {
                    url: `/fincen-seller`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Organization");
            },
        }),

        findLatestSellerByPartyId: build.query({
            query: (partyId) => {
                return {
                    url: `/fincen-seller/exist/${partyId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled);
            },
        }),
    })
})

export const {
    useCreateSellerMutation,
    useLazyFindLatestSellerByPartyIdQuery
} = sellerApi