import React, {FC, useRef, useState} from "react";
// @ts-ignore
import SignatureCanvas from "react-signature-canvas";
import ButtonTray from "../../../components/common/form/ButtonTray";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import {FaTrash, FaUpload} from "react-icons/fa6";
import PdfDocument from "../PdfDocument";
import {showToast} from "../../../helpers/utils/Utils";
import {pdf, PDFViewer} from "@react-pdf/renderer";
import {useGetAllContactByPartyIdQuery} from "../contactApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useUploadAttestationMutation} from "../attestationApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useParams} from "react-router-dom";
import InputField from "../../../components/common/form/InputField";

interface props {
    partyType: string,
    setSkippedTabs: any,
    setActiveTab: any,
    skippedTabs: [],
    partyId: string,
    orgDetailData: any,
    questionResponse: [],
    productId: string,
    isPrimaryContact: any,
    memberDetailData: any
}

const SignatureForm: FC<props> = ({
                                      partyType,
                                      setSkippedTabs,
                                      setActiveTab,
                                      skippedTabs,
                                      partyId,
                                      orgDetailData,
                                      questionResponse,
                                      productId,
                                      isPrimaryContact,
                                      memberDetailData
                                  }) => {

    const {contactId} = useParams();
    const allContactsByPartyId = useGetAllContactByPartyIdQuery(partyId ?? skipToken);
    const [uploadAttestationApi, uploadAttestationApiResponse] = useUploadAttestationMutation();
    const [legalName, setLegalName] = useState<string>("");
    const [finalLegalName, setFinalLegalName] = useState<string>("");

    const [signature, setSignature] = useState<string | null>(null);
    const sigCanvas = useRef<SignatureCanvas | null>(null);
    const [showModal, setShowModal] = useState(false);

    // Function to save the signature as an image
    const saveSignature = () => {
        if (sigCanvas.current.isEmpty()) {
            showToast(`Please provide a signature before saving.`, "warning");
            return;
        }
        if (sigCanvas.current) {
            setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
            setFinalLegalName(legalName);
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const blob = await pdf(
            <PdfDocument
                partyType={partyType}
                orgDetailData={orgDetailData}
                questionResponse={questionResponse}
                signature={signature}
                skippedTabs={skippedTabs}
                allContactsByPartyId={allContactsByPartyId}
                isPrimaryContact={isPrimaryContact}
                memberDetailData={memberDetailData}
                legalName={finalLegalName}
            />
        ).toBlob();

        // Convert Blob to File for Multipart FormData
        const file = new File([blob], 'generated-document.pdf', {type: 'application/pdf'});

        // Create FormData
        const formData = new FormData();
        formData.append('file', file);

        await handleRTKQuery(
            async () => {
                return await uploadAttestationApi({
                    contactId: contactId,
                    formData: formData,
                    productId: productId
                }).unwrap();
            },
            (isSuccess: boolean) => {
                if (isSuccess) {
                    setShowModal(false);
                    setActiveTab("success");
                }
            }
        );
    }

    return (
        <>
            <div className="flex flex-col items-center">
                <p className="text-lg text-center text-text-1">
                    {/*Based on your responses, no FinCEN reporting is required at this time.<br/>*/}
                    To complete this process, please review the statement below and provide your certification.
                </p>

                <div className="mt-7 border shadow-lg rounded-lg overflow-hidden w-full max-w-4xl pdf-viewer">

                    <PDFViewer style={{width: "100%", height: "600px"}}>
                        <PdfDocument partyType={partyType}
                                     orgDetailData={orgDetailData}
                                     questionResponse={questionResponse}
                                     signature={signature}
                                     skippedTabs={skippedTabs}
                                     allContactsByPartyId={allContactsByPartyId}
                                     isPrimaryContact={isPrimaryContact}
                                     memberDetailData={memberDetailData}
                                     legalName={finalLegalName}
                        />
                    </PDFViewer>
                </div>

                <div>
                    <div className={'text-text-2 mt-8 mb-4'}>
                        <p>Electronic signatures have the same legal effect as ink signatures on hard copies.
                            <br/> By typing
                            my name in the following box, I certify the above statements to be true and correct, to the
                            best of my knowledge.
                        </p>
                        <p>By signing the certification of accuracy above, and typing your name
                            in the box below, you are explicitly indicating that the information you have entered is
                            accurate</p>
                    </div>

                    <InputField required
                                label={'Legal Name'}
                                labelPosition={'top'}
                                value={legalName}
                                onChange={(e : any) => {
                                    setLegalName(e.target.value);
                                }}/>
                    <p className={"mt-8 text-text-1"}>*Pen your signature here</p>
                    <div className="relative border rounded-lg border-text-2 bg-text-1">
                        <div className={'relative h-[200px]'}>
                            <SignatureCanvas
                                ref={sigCanvas}
                                penColor="black"
                                canvasProps={{
                                    width: 850,
                                    height: 200,
                                    className: "w-full h-full",
                                }}
                            />
                        </div>
                        {/*// TODO: Use button tray and change to icon buttons*/}
                        {/* Buttons Inside Canvas */}
                        <div className="absolute bottom-2 right-2 flex gap-2 hover:text-highlight-7">
                            {/* Clear Button */}
                            <button
                                onClick={() => {
                                    sigCanvas.current?.clear()
                                    setSignature(null);
                                }}
                                className="bg-surface-2 text-white p-2 rounded-full shadow"
                                title={"Clear"}
                            ><FaTrash size={18} className={"bg-surface-2 text-text-4 hover:text-highlight-7"}/>
                            </button>

                            {/* Upload Button */}
                            <button
                                onClick={() => {
                                    saveSignature();
                                }}
                                className={`bg-surface-2 text-white p-2 rounded-full ${!legalName ? "disabled:bg-gray-400 disabled:cursor-not-allowed group" : ""}`}
                                title={legalName ? "Apply signature and legal name to PDF" : "Legal Name Missing"}
                                disabled={!legalName}
                            ><FaUpload size={18} className={"bg-surface-2 text-text-4 hover:text-highlight-7"}/>
                            </button>
                        </div>


                    </div>

                    <div className={"mb-3"}>

                        <ButtonTray
                            buttons={[
                                {
                                    buttonProps: {
                                        btnText: "Back",
                                        onClick: () => {
                                            setSkippedTabs([]);
                                            setActiveTab(skippedTabs.length > 0 ? "questions" : (partyType === "SELLER" ? "organization-detail" : "verify-contact"));
                                        },
                                        type: "close",
                                    },
                                    buttonType: "button"
                                },
                                {
                                    buttonProps: {
                                        btnText: "Submit",
                                        type: "submit",
                                        isLoading: false,
                                        isVisible: true,
                                        isDisabled: !signature || !legalName || (legalName !== finalLegalName),
                                        onClick: () => {
                                            setShowModal(true);
                                        }
                                    },
                                    buttonType: "button",

                                }
                            ]}
                            align="end"
                            gap={2}
                            addTopLine={false}
                        />
                    </div>
                </div>

            </div>

            {showModal && <ConfirmationModal
                show={true}
                setShow={setShowModal}
                title={"Submit your signed certification of accuracy"}
                bodyMessage={`Are you sure you want to submit your signed certification of accuracy?`}
                onSubmit={(e: any) => {
                    handleSubmit(e).then(r => {
                    });
                }}
                isLoading={uploadAttestationApiResponse.isLoading}
                waitForResponse={true}
            />}
        </>
    )

};

export default SignatureForm;
