import React, {useState} from "react";
import InputField from "../../components/common/form/InputField";
import {MdDelete} from "react-icons/md";
import {IoIosArrowForward} from "react-icons/io";
import AttestModal from "./AttestModal";
import logo from "../../images/logo.svg";
import {Routes as AppRoutes} from "../../routes";
import {useNavigate} from "react-router-dom";

const BusinessTransactionFormPage = () => {
    const [businesses, setBusinesses] = useState([]);
    const [count, setCount] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);
    const handleCloseModal = () => setModalOpen(false);
    const handleOpenModal = () => setModalOpen(true);

    const navigate = useNavigate();

    // State for business form values
    const [businessForm, setBusinessForm] = useState({
        orgName: "",
        firstName: "",
        middleInitial: "",
        lastName: "",
        taxId: "",
        dba: "",
        streetAddress: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        alienIdentification: "",
        idIssuer: ""
    });

    // Function to add business to the list
    const addBusiness = () => {
        setBusinesses([
            ...businesses,
            {...businessForm, id: Date.now(), isCollapsed: true}
        ]);
        setBusinessForm({
            orgName: "",
            firstName: "",
            middleInitial: "",
            taxId: "",
            dba: "",
            streetAddress: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            alienIdentification: "",
            idIssuer: ""
        });
    };

    // Toggle collapse for business
    const toggleBusinessCollapse = (id) => {
        setBusinesses(businesses.map(business => business.id === id ? {
            ...business,
            isCollapsed: !business.isCollapsed
        } : business));
    };

    // Function to delete business
    const deleteBusiness = (id) => {
        setBusinesses(businesses.filter(business => business.id !== id));
    };

    const updateBusiness = (id) => {
        const updatedData = businessForm[id];
        setBusinesses((prev) =>
            prev.map((business) => (business.id === id ? {...business, ...updatedData} : business))
        );
        setBusinessForm((prev) => ({...prev, [id]: undefined})); // Clear temporary form data for this business
    };

    const businessLabelMap = {
        orgName: "Organization’s Name",
        firstName: "Individual’s First Name",
        middleInitial: "Individual’s Middle Initial",
        lastName: "Individual’s Last Name",
        taxId: "Tax ID",
        dba: "Doing Business As (DBA)",
        streetAddress: "Street Address",
        city: "City",
        state: "State",
        zip: "ZIP Code",
        country: "Country",
        alienIdentification: "Alien Identification (ID)",
        idIssuer: "ID Issuer",
        // Add more mappings as needed
    };

    return (
        <>
            <header
                className="sm:block py-1 lg:flex md:block z-50 items-center fixed w-full header bg-surface-1 px-2 border-b-2 border-highlight-2">
                <div className="sm:flex justify-between lg:block md:flex flex items-center"><a
                    className="flex items-center space-x-2" href="/">
                    <img src={logo} alt="Company Logo" className="h-10"/></a>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512"
                         className="text-text-1 lg:hidden sm:block md:block" height="1em" width="1em"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path>
                    </svg>
                </div>
            </header>
            <div className="flex items-center justify-center px-1 py-2 pt-14">
                <div className="flex justify-center">
                    <div className="bg-surface-2 w-full p-6 rounded-md">

                        {/* Businesses Form */}
                        <div className="col-span-12 mt-4 p-4 rounded-sm border border-surface-6 relative">
                            <label className="text-text-1 absolute bg-surface-2 -top-3.5 pr-1">Businesses</label>
                            {Object.keys(businessForm).filter((key) => !/^\d+$/.test(key)).map((key, index) => (
                                <InputField
                                    key={index}
                                    label={businessLabelMap[key] || key.replace(/([A-Z])/g, ' $1').replace(/^\w/, (c) => c.toUpperCase())}
                                    value={businessForm[key]}
                                    type={key === 'dob' ? 'date' : 'text'}
                                    onChange={(e : any) => setBusinessForm({...businessForm, [key]: e.target.value})}
                                    name={key}
                                    labelPosition={"left"}
                                    required={true}
                                />
                            ))}
                            <div className="grid grid-cols-12 gap-2 mt-2">
                                <div className="col-span-12 flex items-end justify-end">
                                    <button type="button"
                                            className={`bg-highlight-2 rounded-md text-text-1 text-xs px-2 py-1 mr-3 hover:bg-highlight-7 ${!businessForm["orgName"] ? 'cursor-not-allowed' : ''}`}
                                            disabled={!businessForm["orgName"]}
                                            onClick={addBusiness}>Add
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Render Added Businesses */}
                        <div>
                            {businesses.map((business) => (
                                <React.Fragment key={business.id}>
                                    {/* Business Header */}
                                    <div
                                        className="flex items-center justify-between bg-highlight-2 text-text-1 p-2 mt-2 cursor-pointer">
                                        <span>{business.orgName}</span>
                                        <div className="flex gap-3">
                                            <IoIosArrowForward
                                                className={`text-text-1 text-lg cursor-pointer ${!business.isCollapsed ? "rotate-90" : ""}`}
                                                onClick={() => toggleBusinessCollapse(business.id)}
                                            />
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent toggle on delete click
                                                    deleteBusiness(business.id);
                                                }}
                                                className="text-white"
                                            >
                                                <MdDelete className="text-text-1 text-xl cursor-pointer"/>
                                            </button>
                                        </div>
                                    </div>

                                    {/* Collapsible Business Form */}
                                    {!business.isCollapsed && (
                                        <div className="border p-3 mb-2 border-surface-6">
                                            <InputField
                                                label="Organization’s Name"
                                                value={businessForm[business.id]?.orgName || business.orgName}
                                                type="text"
                                                onChange={(e : any) =>
                                                    setBusinessForm({
                                                        ...businessForm,
                                                        [business.id]: {
                                                            ...businessForm[business.id],
                                                            orgName: e.target.value,
                                                        },
                                                    })
                                                }
                                                name="orgName"
                                                labelPosition="left"
                                                required={true}
                                            />

                                            <InputField
                                                label="Individual’s First Name"
                                                value={businessForm[business.id]?.firstName || business.firstName}
                                                type="text"
                                                onChange={(e : any) =>
                                                    setBusinessForm({
                                                        ...businessForm,
                                                        [business.id]: {
                                                            ...businessForm[business.id],
                                                            firstName: e.target.value,
                                                        },
                                                    })
                                                }
                                                name="firstName"
                                                labelPosition="left"
                                            />

                                            <InputField
                                                label="Individual’s Middle Initial"
                                                value={businessForm[business.id]?.middleInitial || business.middleInitial}
                                                type="text"
                                                onChange={(e : any) =>
                                                    setBusinessForm({
                                                        ...businessForm,
                                                        [business.id]: {
                                                            ...businessForm[business.id],
                                                            middleInitial: e.target.value,
                                                        },
                                                    })
                                                }
                                                name="middleInitial"
                                                labelPosition="left"
                                            />
                                            <InputField
                                                label="Individual’s Last Name"
                                                value={businessForm[business.id]?.lastName || business.lastName}
                                                type="text"
                                                onChange={(e : any) =>
                                                    setBusinessForm({
                                                        ...businessForm,
                                                        [business.id]: {
                                                            ...businessForm[business.id],
                                                            lastName: e.target.value,
                                                        },
                                                    })
                                                }
                                                name="lastName"
                                                labelPosition="left"
                                            />
                                            <InputField
                                                label="Tax ID"
                                                value={businessForm[business.id]?.taxId || business.taxId}
                                                type="text"
                                                onChange={(e : any) =>
                                                    setBusinessForm({
                                                        ...businessForm,
                                                        [business.id]: {
                                                            ...businessForm[business.id],
                                                            taxId: e.target.value,
                                                        },
                                                    })
                                                }
                                                name="taxId"
                                                labelPosition="left"
                                            />
                                            <InputField
                                                label="Street Address"
                                                value={businessForm[business.id]?.streetAddress || business.streetAddress}
                                                type="text"
                                                onChange={(e : any) =>
                                                    setBusinessForm({
                                                        ...businessForm,
                                                        [business.id]: {
                                                            ...businessForm[business.id],
                                                            streetAddress: e.target.value,
                                                        },
                                                    })
                                                }
                                                name="streetAddress"
                                                labelPosition="left"
                                            />
                                            <InputField
                                                label="City"
                                                value={businessForm[business.id]?.city || business.city}
                                                type="text"
                                                onChange={(e : any) =>
                                                    setBusinessForm({
                                                        ...businessForm,
                                                        [business.id]: {
                                                            ...businessForm[business.id],
                                                            city: e.target.value,
                                                        },
                                                    })
                                                }
                                                name="city"
                                                labelPosition="left"
                                            />
                                            <InputField
                                                label="State"
                                                value={businessForm[business.id]?.state || business.state}
                                                type="text"
                                                onChange={(e : any) =>
                                                    setBusinessForm({
                                                        ...businessForm,
                                                        [business.id]: {
                                                            ...businessForm[business.id],
                                                            state: e.target.value,
                                                        },
                                                    })
                                                }
                                                name="state"
                                                labelPosition="left"
                                            />
                                            <InputField
                                                label="Zip"
                                                value={businessForm[business.id]?.zip || business.zip}
                                                type="text"
                                                onChange={(e : any) =>
                                                    setBusinessForm({
                                                        ...businessForm,
                                                        [business.id]: {
                                                            ...businessForm[business.id],
                                                            zip: e.target.value,
                                                        },
                                                    })
                                                }
                                                name="zip"
                                                labelPosition="left"
                                            />
                                            <InputField
                                                label="Country"
                                                value={businessForm[business.id]?.country || business.country}
                                                type="text"
                                                onChange={(e : any) =>
                                                    setBusinessForm({
                                                        ...businessForm,
                                                        [business.id]: {
                                                            ...businessForm[business.id],
                                                            country: e.target.value,
                                                        },
                                                    })
                                                }
                                                name="country"
                                                labelPosition="left"
                                            />
                                            <InputField
                                                label="Business Type"
                                                value={businessForm[business.id]?.businessType || business.businessType}
                                                type="text"
                                                onChange={(e : any) =>
                                                    setBusinessForm({
                                                        ...businessForm,
                                                        [business.id]: {
                                                            ...businessForm[business.id],
                                                            businessType: e.target.value,
                                                        },
                                                    })
                                                }
                                                name="businessType"
                                                labelPosition="left"
                                            />
                                            <div className="grid grid-cols-12 gap-2 mt-2">
                                                <div className="col-span-12 flex items-end justify-end">
                                                    <button type="button"
                                                            className={`bg-highlight-2 rounded-md text-text-1 text-xs px-2 py-1 mr-3 hover:bg-highlight-7 ${!business.orgName ? 'cursor-not-allowed' : ''}`}
                                                            disabled={!business.orgName}
                                                            onClick={() => updateBusiness(business.id)}>Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>

                        <div className="flex justify-end mt-5">
                            <button type="button"
                                    className={`inline-flex px-2 py-1 items-center rounded bg-highlight-3 text-text-1 focus:outline-none focus:shadow-outline hover:transition-all hover:duration-200 hover:ease ${businesses.length === 0 ? 'cursor-not-allowed' : ''}`}
                                    onClick={() => {
                                        navigate(AppRoutes.IndividualsTransactionPage.path);
                                    }}
                                    disabled={businesses.length === 0}>Submit
                            </button>
                        </div>
                    </div>
                </div>

                <AttestModal isOpen={isModalOpen} onClose={handleCloseModal}
                             body={"This transaction requires reporting to FinCen. Please attest to the truth of your\n" +
                                 "                    statement by clicking the button below."}/>

            </div>
        </>
    );
};

export default BusinessTransactionFormPage;
