import {strApi} from "../../app/strApi";
import {
    getAuthorizationHeader,
    handleQueryError
} from "../../helpers/utils/RtkQueryUtils";

export const fincenFilingErrorApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getErrorsByFilingId: build.query({
            query: (filingId) => {
                return {
                    url: `/fincen-filing-errors/by-filing/${filingId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["fincenFilingErrorsByFilingId"]
        }),
        getErrorsByBatchId: build.query({
            query: (batchId) => {
                return {
                    url: `/fincen-filing-errors/by-batch/${batchId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["fincenFilingErrorsByBatchId"]
        }),
    }),
});

export const {
    useGetErrorsByFilingIdQuery,
    useGetErrorsByBatchIdQuery,
} = fincenFilingErrorApi;