import {useEffect, useState} from "react";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useLazyExportStateDataQuery, useSearchStatesQuery} from "./statesApi";
import IconButton from "../../../components/common/form/IconButton";
import {FaPlus} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";

const States = () => {

    const navigate = useNavigate();

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "States", url: ""},

    ])

    useEffect(() => {
        document.title = "States";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("State");
    const [triggerExportStateDataQuery] = useLazyExportStateDataQuery();

    const columns: DataTableColumn[] = [
        {
            label: "Name",
            accessor: "name",
            cell: (props: any) => {
                let path = `/states/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["STAT-R", "STAT-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Name..."}
                                                     accessor={"name"}/>,
            }]
        },
        {
            label: "Abbreviation", accessor: "abbreviation",
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Abbreviation..."}
                                                     accessor={"abbreviation"}/>,
            }]
        },
        {
            label: "Numeric Code", accessor: "numericCode",
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Numeric Code..."}
                                                     accessor={"numericCode"}/>,
            }]
        }
    ]

    return (
        <>
            <div className="user-container px-5 py-5">
                <div className="flex justify-between">
                    <Breadcrumb crumbs={crumbs}/>
                </div>

                <DataTable
                    columns={columns}
                    actions={{
                        fetchAction: useSearchStatesQuery,
                        exportAction: triggerExportStateDataQuery
                    }}
                    defaultSortOptions={{
                        accessor: "name",
                        direction: "asc"
                    }}
                    buttonOptions={{
                        addButton:
                            {
                                button: <IconButton
                                    icon={<FaPlus/>}
                                    hasBackground={true}
                                    onClick={() => {
                                        navigate("/states/create")
                                    }}
                                    title={"Create State"}
                                />,
                                permissions: ["STAT-C"]
                            }
                    }}
                    userSettingGroup={"State"}
                    basePermission={"STAT"}
                />
            </div>
        </>
    )
}
export default States;