import {Document, Image, Page, Path, Svg, Text, View, StyleSheet} from "@react-pdf/renderer";
// @ts-ignore
import logo from "../../images/logo.png";
import {buyerQuestions, partyFieldLabels, sellerQuestions} from "../../helpers/utils/Utils";
import React, {FC, useState} from "react";

// Define styles
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontSize: 12,
        backgroundColor: "#FFFFFF",
    },
    gridFlex: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row"
    },
    title: {
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 15,
        marginTop: 5,
        textAlign: "center"
    },
    section: {
        marginBottom: 10,
        width: "50%"
    },
    questionContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 8
    },
    checkbox: {
        width: 14,
        height: 14,
        border: "1px solid black",
        marginRight: 8
    },
    checkedBox: {
        width: 14,
        height: 14,
        border: "1px solid black",
        backgroundColor: "black",
        marginRight: 8
    },
    text: {
        fontSize: 12,
        flexShrink: 1
    },
    label: {
        fontWeight: "bold"
    },
    value: {
        color: "grey"
    },
    table: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#000",
        marginBottom: 15,
    },
    tableRow: {
        flexDirection: "row",
    },
    tableColHeader: {
        width: "33%",
        borderStyle: "solid",
        borderBottomWidth: 1,
        borderColor: "#000",
        backgroundColor: "#f0f0f0",
        padding: 5,
        fontWeight: "bold",
        textAlign: "center",
    },
    tableCol: {
        width: "33%",
        borderStyle: "solid",
        borderBottomWidth: 1,
        borderColor: "#000",
        padding: 5,
        textAlign: "center",
    },
    footer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    dateText: {
        fontSize: 12,
        textAlign: "left",
    },
    signatureContainer: {
        flexDirection: "column",
        alignItems: "flex-end",
    },
    signatureLabel: {
        fontSize: 12,
        marginBottom: 2, // Reduced margin
    },
    signatureImage: {
        width: 120,
        height: 40,
        color: "black"
    },
    footerSign: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: 50,
    },
    legalName: {
        fontWeight: "bold",
    },
    logo: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: 200, // Adjust size
        height: 25,
        marginBottom: 10, // Add spacing
    },
});

interface props {
    partyType: string,
    skippedTabs: [],
    orgDetailData: any,
    questionResponse: [],
    signature: any,
    allContactsByPartyId: any,
    isPrimaryContact: any,
    memberDetailData: any,
    legalName: string
}

const PdfDocument: FC<props> = ({
                                    partyType,
                                    skippedTabs,
                                    orgDetailData,
                                    questionResponse,
                                    signature,
                                    allContactsByPartyId,
                                    isPrimaryContact,
                                    memberDetailData,
                                    legalName
                                }) => {
    const questions = partyType === "SELLER" ? sellerQuestions : buyerQuestions;

    const buyerLabel = {
        legalFirstName: "First Name",
        legalMiddleInitial: "Middle Initial",
        legalLastName: "Last Name",
        taxId: "Tax Id",
        alienIdentification: "Alien Identification",
        idIssuer: "Id Issuer",
        organizationName: "Organization's Name",
        employerId: "Employer ID",
        doingBusinessAsName: "Doing Business As (DBA)",
        streetAddress: "Street Address",
        city: "City",
        zip: "Zip",
        country: "Country",
    };

    const sellerLabel = {
        organizationName: "Organization’s Name",
        natureOfBusiness: "Nature Of Business",
        taxId: "Tax ID",
        employerId: "Employer ID",
        contactName: "Contact Name",
        contactTitle: "Contact Title",
        contactPhoneNumber: "Phone Number",
        streetAddress: "Street Address",
        city: "City",
        zip: "Zip",
    };


    return (
        <Document>
            <Page size={[695, 700]} style={styles.page}> {/* Custom width & height */}

                <Image style={styles.logo} src={logo}/>
                <Text style={styles.title}>{"Certification of Accuracy"}</Text>
                {isPrimaryContact && questions.map((q: { id: number, text: string, name: string }) => (
                    <View key={q.id} style={styles.questionContainer}>
                        <View style={styles.checkbox}>
                            {/*@ts-ignore*/}
                            {questionResponse && questionResponse[q.name] === true &&
                                <Svg width="10" height="10" viewBox="0 0 24 24">
                                    <Path
                                        d="M4 12l6 6L20 6"
                                        stroke="black"
                                        strokeWidth="4"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fill="none"
                                    />
                                </Svg>}
                        </View>
                        <Text style={styles.text}>{q.text}</Text>
                    </View>
                ))}

                {/*@ts-ignore*/}
                {isPrimaryContact && !skippedTabs?.includes("organization-detail") &&
                    <>
                        <Text style={styles.title}>Organization Details</Text>
                        <View style={styles.gridFlex}>
                            {Object.entries(partyType === "SELLER" ? sellerLabel : buyerLabel).map(([key, value]) => (
                                <View key={key} style={styles.section}>
                                    <Text>
                                        <Text style={styles.label}>{value.trim()} : </Text>
                                        <Text style={styles.value}>{orgDetailData[key] || "-"}</Text>
                                    </Text>
                                </View>
                            ))}
                        </View>
                    </>
                }

                {!isPrimaryContact &&
                    <View style={styles.gridFlex}>
                        {Object.entries(partyFieldLabels).map(([key, value]) => (
                            <View key={key} style={styles.section}>
                                <Text>
                                    <Text style={styles.label}>{value.trim()} : </Text>
                                    <Text style={styles.value}>{memberDetailData[key] || "-"}</Text>
                                </Text>
                            </View>
                        ))}
                    </View>
                }

                {/*@ts-ignore*/}
                {isPrimaryContact && !skippedTabs?.includes("verify-contact") && partyType !== "SELLER" &&
                    <>
                        <Text style={styles.title}>Contact Details</Text>
                        <View style={styles.table}>
                            {/* Table Header */}
                            <View style={styles.tableRow}>
                                <Text style={styles.tableColHeader}>Name</Text>
                                <Text style={styles.tableColHeader}>Email</Text>
                                <Text style={styles.tableColHeader}>Phone</Text>
                                <Text style={styles.tableColHeader}>Is Member</Text>
                            </View>

                            {/* Dummy Data Rows */}
                            {allContactsByPartyId?.data?.map((obj: any, index: number) => (
                                <View key={index} style={styles.tableRow}>
                                    <Text style={styles.tableCol}>{obj.name}</Text>
                                    <Text style={styles.tableCol}>{obj.emailAddress}</Text>
                                    <Text style={styles.tableCol}>{obj.phoneNumber}</Text>
                                    <Text style={styles.tableCol}>
                                        {obj.type === "MEMBER" ? "true" : "false"}
                                    </Text>
                                </View>
                            ))}
                        </View>
                    </>
                }

                <View style={styles.footerSign}>
                    <View style={styles.signatureContainer}>
                        {signature && <Image src={signature} style={styles.signatureImage}/>}
                    </View>
                </View>
                <Text style={styles.legalName}>{legalName}</Text>
                <View style={styles.footer}>
                    {/* Left: Date */}
                    <Text>Date: {new Date().toLocaleDateString()}</Text>
                    <Text style={styles.signatureLabel}>Signature</Text>
                </View>
            </Page>

        </Document>
    )
}
export default PdfDocument;