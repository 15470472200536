import {strApi} from "../../app/strApi";
import {
    getAuthorizationHeader,
    getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../helpers/utils/RtkQueryUtils";
import {getFileName, serialize} from "../../helpers/utils/Utils";
import {downloadFile} from "../../helpers/utils/FileUtils";

export const fincenBatchApi = strApi.injectEndpoints({

    endpoints: (build) => ({
        // Define the endpoint for searching orders
        getBatchById: build.query({
            query: (id) => {
                return {
                    url: `/fincen-batches/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ["fincenBatchById"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        searchBatches: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/fincen-batches/search?${paramData}`,
                    method: 'POST',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["fincenBatches"]
        }),
    }),
});

export const {
    useGetBatchByIdQuery,
    useSearchBatchesQuery
} = fincenBatchApi;