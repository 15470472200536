import Modal from "../../../components/common/form/Modal";
import React, {ChangeEvent, FC, useEffect, useState} from "react";
import Button from "../../../components/common/form/Button";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import InputField from "../../../components/common/form/InputField";
import {
    useCreateContactMutation,
    useGetContactByIdQuery,
    useUpdateContactMutation
} from "../../fincenReporting/contactApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import Form from "../../../components/common/form/Form";
import {skipToken} from "@reduxjs/toolkit/query";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import SelectField from "../../../components/common/form/SelectField";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import {E164Number} from "libphonenumber-js/types";


interface props {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    contactId?: string,
    partyId?: string,
}

const AddEditContactForm: FC<props> = ({show, setShow, contactId, partyId}) => {

    const [contactData, setContactData] = useState<any>({});

    const [updateContactApi, updateContactApiResponse] = useUpdateContactMutation();
    const [createContactApi, createContactApiResponse] = useCreateContactMutation();
    const getContactByIdApi = useGetContactByIdQuery(contactId ?? skipToken);
    const {data: enums} = useGetEnumsQuery("");

    useEffect(() => {
        let obj = getContactByIdApi?.data;
        if (obj) {
            setContactData({
                ...obj,
                type: obj.type ? {
                    value: obj.type,
                    label: getEnumDisplayNameByConstant(
                        enums?.["MemberContactType"],
                        obj.type
                    ),
                } : null,
            });
        }
    }, [getContactByIdApi?.data]);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setContactData({
            ...contactData,
            [name]: value
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!contactId) {
            await handleRTKQuery(
                async () => {
                    return await createContactApi({
                        requestBody: {
                            ...contactData,
                            primaryContact: false,
                            partyId: partyId,
                            status: "NOT_INVITED",
                            type: contactData?.type?.value
                        },
                        isSendLink: false
                    }).unwrap();
                },
                (isSuccess: boolean) => {
                    if (isSuccess) {
                        setContactData({});
                        setShow(false);
                    }
                }
            );

        } else {
            await handleRTKQuery(
                async () => {
                    return await updateContactApi({
                        id: contactId,
                        requestBody: {...contactData, type: contactData?.type?.value}
                    }).unwrap();
                },
                (isSuccess: boolean) => {
                    if (isSuccess) {
                        setContactData({});
                        setShow(false);
                    }
                }
            );
        }
    }

    return (
        <>
            <Modal
                title={contactId ? "Update Contact" : "Add Contact"}
                show={show}
                setShow={setShow}
                className="w-3/5 mx-4"
                fields={
                    <Form onSubmit={handleSubmit} fields={
                        <>

                            <InputField
                                label="Name"
                                name={"name"}
                                value={contactData?.name || ""}
                                type="text"
                                onChange={handleOnChange}
                            />

                            <InputField
                                label="Email"
                                name={"emailAddress"}
                                value={contactData?.emailAddress || ""}
                                type="text"
                                onChange={handleOnChange}
                            />

                            <InputField
                                name="phoneNumber"
                                label="Phone"
                                value={contactData?.phoneNumber}
                                // @ts-ignore
                                onChange={function (value?: E164Number | undefined): void {
                                    setContactData({...contactData, ['phoneNumber']: value})
                                }}
                                type="phoneNumber"
                                required
                                placeholder="Enter Phone Number..."
                            />

                            <SelectField
                                placeholder='Select Contact Type...'
                                value={contactData?.type || null}
                                options={createOptionListForSelectTag(enums?.["MemberContactType"], "displayName", "constant")}
                                onChange={(selectedOption) => {
                                    handleOnChange({name: "type", value: selectedOption})
                                }}
                                required={true}
                                label={"Contact Type"}
                            />

                            <div className="flex justify-end gap-1 border-t border-surface-3 pt-4 mt-4">
                                <Button btnText="Close" isLoading={false} type="close" onClick={() => setShow(false)}/>
                                {
                                    (contactId ? hasPermission(["FINCON-U"]) : hasPermission(["FINCON-C"])) &&
                                    <Button btnText={contactId ? "Update" : "Submit"}
                                            isLoading={contactId ? updateContactApiResponse.isLoading : createContactApiResponse.isLoading}
                                            type="submit"
                                            isDisabled={false}/>
                                }
                            </div>
                        </>
                    }/>
                }
            />
        </>
    )
}
export default AddEditContactForm;