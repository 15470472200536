import React, {FC} from "react";
import {IoIosArrowDown} from "react-icons/io";
import {handleRTKQuery} from "../../helpers/utils/RtkQueryUtils";
import TailSpinLoader from "../../components/common/TailSpinLoader";
import {useParams} from "react-router-dom";
import {useRequestFincenMfaChallengeMutation} from "./fincenAuthenticateApi";

interface props {
    setIsSidebarVisible: any,
    phoneNumber: string,
    setChallengeId: any,
    setActiveTab: any
}

const CodeRequestSection: FC<props> = ({setIsSidebarVisible, phoneNumber, setChallengeId, setActiveTab}) => {

    const {contactId} = useParams();

    const [requestChallenge, sendRequestChallengeApiResponse] = useRequestFincenMfaChallengeMutation();

    const handleSubmit = async () => {
        if (phoneNumber) {
            await handleRTKQuery(
                async () => {
                    return requestChallenge(contactId).unwrap();
                },
                (isSuccess: any) => {
                    if (isSuccess) {
                        setActiveTab("verify-code");
                        setChallengeId(isSuccess?.id);
                        setIsSidebarVisible(true);
                    }
                }
            )
        }
    }

    return (

        <div className="rounded-xl border border-surface-2">
            <div className={"cursor-pointer flex justify-between p-3 border-b border-surface-2"}>
                <div className="flex gap-1 items-center">
                    <h3 className={"text-text-2"}>Request & Verify Code</h3>
                </div>
                <div className="flex gap-3">
                    <IoIosArrowDown
                        className={"text-text-1 text-lg cursor-pointer rotate-180"}
                    />
                </div>
            </div>

            <div className="p-3 text-text-1">
                <p className={"font-extralight"}>Click the button below to send a verification
                    code to the phone number ending in {phoneNumber?.slice(-4)} associated with your account, provided
                    by the title company conducting your
                    transaction.
                </p>
                <p className={"mt-5 font-extralight text-xs"}>
                    If this phone number is incorrect, please reach out using the contact information below.
                </p>
            </div>

            {/* Wrap the button in a flex container to center it */}
            <div className="flex justify-center mb-5 mt-3">
                <button
                    className="bg-highlight-3 focus:outline-none focus:shadow-outline hover:bg-highlight-7 hover:duration-200 hover:ease hover:text-text-1 hover:transition-all inline-flex items-center px-8 py-0.5 rounded text-sm text-text-1"
                    onClick={handleSubmit}>
                    {sendRequestChallengeApiResponse?.isLoading ? (
                        <>
                            Loading...
                            <TailSpinLoader/>
                        </>
                    ) : (
                        "Send Code"
                    )}
                </button>
            </div>

        </div>

    )
}
export default CodeRequestSection;