import React, {FC} from "react";
import {DetailRow} from "../../../helpers/utils/OrderDetailUtils";
import {useGetProductPricingByProductIdQuery} from "../productsApi";
import {FaPenToSquare,} from "react-icons/fa6";
import IconButton from "../../../components/common/form/IconButton";
import {getFirstNonNull} from "../../../helpers/utils/Utils";
import {formatAsCurrency} from "../../../helpers/utils/TextUtils";
import ModalButton from "../../../components/common/form/ModalButton";
import UpdatePricingModal from "../modal/UpdatePricingModal";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";

interface Props {
    productId?: string | null;
}

const ProductPricingDetail: FC<Props> = ({productId}) => {

    const {
        data: pricing,
        isLoading: isPricingLoading
    } = useGetProductPricingByProductIdQuery(productId, {skip: !productId, pollingInterval: 5000});

    return (
        <div className="bg-surface-2 rounded-md p-3 pt-1.5 mt-3">
            <div className="flex gap-2 items-center justify-between border-b border-surface-3 pb-2">
                <div className="text-text-1 font-semibold">Pricing Detail</div>

                {hasPermission(['PROPRI-U']) &&
                    <ModalButton modal={<UpdatePricingModal pricingId={pricing?.id}
                                                            show={true}
                                                            setShow={() => {
                                                            }}
                    />
                    }
                                 triggerButton={<IconButton icon={<FaPenToSquare/>}/>}
                    />
                }
            </div>

            <DetailRow label={'Price'}
                       className={pricing?.overridePrice && 'italic'}
                       isLoading={isPricingLoading}
                       value={formatAsCurrency(getFirstNonNull([pricing?.overridePrice,
                           pricing?.calculatedPrice,
                           pricing?.basePrice]))}
            />

            <DetailRow label={'Vendor Fee'}
                       isLoading={isPricingLoading}
                       value={formatAsCurrency(getFirstNonNull([pricing?.overrideVendorFee,
                           pricing?.calculatedVendorFee,
                           pricing?.baseVendorFee]))}
            />

            <DetailRow label={'Platform Fee'}
                       className={pricing?.overridePlatformFee && 'italic'}
                       isLoading={isPricingLoading}
                       value={formatAsCurrency(getFirstNonNull([pricing?.overridePlatformFee,
                           pricing?.calculatedPlatformFee,
                           pricing?.basePlatformFee]))}
            />

            <DetailRow label={'Partner Fee'}
                       className={pricing?.overridePartnerFee && 'italic'}
                       isLoading={isPricingLoading}
                       value={formatAsCurrency(getFirstNonNull([pricing?.overridePartnerFee,
                           pricing?.calculatedPartnerFee,
                           pricing?.basePartnerFee]))}
            />

        </div>
    );
};

export default ProductPricingDetail;
