import React, {FC, useEffect, useState} from "react";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";
import {useGetFilingsByBatchIdQuery, useGetFilingsByProductIdQuery} from "../../fincenBatch/fincenFilingApi";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import {useGetEnumsQuery} from "../../../app/globalApi";

interface props {
    productId?: string,
}

const FincenBatchFilingContainer: FC<props> = ({productId}) => {
    const {data: enums} = useGetEnumsQuery("");

    if (!productId) {
        return <></>;
    }

    const columns: DataTableColumn[] = [
        {
            label: "Batch",
            accessor: "batch.batchName",
            cell: (props: any) => {
                let path = `/fincen-batches/${props.row.original.batchId}`;
                return createClickableLink(path, props.value, [{
                    key: "linkOpeningStyle",
                    value: "NEW TAB"
                }], false, "", ["ORDR-R", "ORDR-V"]);
            }
        },
        {
            label: "Status",
            accessor: "status",
            cell: (props: any) => getEnumDisplayNameByConstant(enums?.["FincenFilingStatus"], props.value),
        },
        {
            label: "Seller",
            accessor: "sellerParty.name"
        },
        {
            label: "BSA ID",
            accessor: "bsaId",
            cell: (props: any) => props.value || 'N/A',
        }
    ]

    return (
        <>
            <div className="">
                <DataTable
                    columns={columns}
                    actions={{
                        fetchAction: useGetFilingsByProductIdQuery
                    }}
                    defaultFilter={{
                        productId: productId
                    }}
                    appendFilterToUrl={false}
                    renderOptions={{
                        skipUserSettingRender: true,
                        skipPaginationRender: true
                    }}
                />
            </div>
        </>
    )
}
export default FincenBatchFilingContainer