import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleRTKQuery} from "../../helpers/utils/RtkQueryUtils";

export const partyApi = strApi.injectEndpoints({

    endpoints: (build) => ({

        findPartyById: build.query({
            query: (id) => {
                return {
                    url: `/fincen-party/${id}`,
                    method: 'GET'
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleRTKQuery(queryFulfilled);
            },
        }),

        findAllDisplayPartyByProductId: build.query({
            query: (productId) => {
                return {
                    url: `/fincen-party/display/productId/${productId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ["partiesByProductId"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleRTKQuery(queryFulfilled);
            },
        }),

    })
})

export const {
    useLazyFindPartyByIdQuery,
    useFindAllDisplayPartyByProductIdQuery
} = partyApi