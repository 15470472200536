import React, {FC} from "react";
import TailSpinLoader from "../TailSpinLoader";

export type ButtonProps = {
    isLoading?: boolean;
    isDisabled?: boolean;
    btnText: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; // Optional onClick handler
    type: 'submit' | 'confirm' | 'cancel' | 'close' | 'button'; // Type for different button functionalities
    className?: string;
    title?: string;
    isVisible?: boolean
};

const Button: FC<ButtonProps> = ({
                                     className = "",
                                     isLoading = false,
                                     isDisabled = false,
                                     btnText,
                                     onClick,
                                     type,
                                     title = "",
                                     isVisible = true
                                 }) => {

    // Define button styles based on the type
    const getButtonClasses = () => {
        const commonClasses = isDisabled ? "" : "hover:bg-highlight-7 hover:text-text-1";
        switch (type) {
            case "close":
                return `${commonClasses} bg-surface-4 text-text-3`;
            case "submit":
            case "confirm":
            case "button":
                return `${commonClasses} bg-highlight-3 text-text-1`;
            case "cancel":
                return `${commonClasses} bg-surface-3 text-text-2`;
            default:
                return `${commonClasses} bg-surface-3 text-text-2`;
        }
    };

    // Prevent onClick if button is disabled
    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!isDisabled && onClick) {
            onClick(e);
        }
    };

    // Conditionally render the button based on permission
    return (

        <>
            {isVisible &&
                <button
                    type={type === "submit" ? "submit" : "button"} // Handle submit button type
                    onClick={handleClick}
                    title={title}
                    className={`inline-flex px-2 py-1 items-center rounded ${getButtonClasses()} focus:outline-none focus:shadow-outline hover:transition-all hover:duration-200 hover:ease ${className} ${isDisabled ? "cursor-not-allowed" : ""}`}
                    disabled={isLoading || isDisabled} // Disable button while loading or if disabled
                >
                    {isLoading ? (
                        <>
                            Loading...
                            <TailSpinLoader/>
                        </>
                    ) : (
                        btnText
                    )}
                </button>
            }
        </>

    );
};

export default Button;

