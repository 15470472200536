import {strApi} from "../../app/strApi";
import {
    getAuthorizationHeader,
    getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../helpers/utils/RtkQueryUtils";
import {getFileName, serialize} from "../../helpers/utils/Utils";
import {downloadFile} from "../../helpers/utils/FileUtils";

export const fincenBatchApi = strApi.injectEndpoints({

    endpoints: (build) => ({
        // Define the endpoint for searching orders
        getBatchFileById: build.query({
            query: (id) => {
                return {
                    url: `/batch-files/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ["batchFileById"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        searchBatchFiles: build.query({
            query: (data) => {
                let paramData = serialize(data);

                return {
                    url: `/batch-files/search?${paramData}`,
                    method: 'POST',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["batchFiles"]
        }),
    }),
});

export const {
    useSearchBatchFilesQuery,
} = fincenBatchApi;