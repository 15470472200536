import React, {FC, useState} from "react";
import InputField from "../../../components/common/form/InputField";
import ButtonTray from "../../../components/common/form/ButtonTray";
import {createOptionListForSelectTag, isNullOrEmpty} from "../../../helpers/utils/Utils";
import SelectField from "../../../components/common/form/SelectField";
import {useGetAllStatesByCountryIdQuery} from "../../data/states/statesApi";
import {useCreateBuyerMutation} from "../buyerApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useGetAllCountriesQuery} from "../../data/countries/countriesApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {validateTaxIdOrEmpId, validateZipCode} from "../../../helpers/utils/FincenValidation";

interface Props {
    activeTab: string
    setActiveTab: any;
    tabsWithUnsavedChanges: any;
    setTabsWithUnsavedChanges: any;
    partyId: string;
    setOrgDetailData: any
}

const BuyerForm: FC<Props> = ({
                                  activeTab,
                                  setActiveTab,
                                  tabsWithUnsavedChanges,
                                  setTabsWithUnsavedChanges,
                                  partyId,
                                  setOrgDetailData
                              }) => {

    const [currentTab, setCurrentTab] = useState("1");
    const [stateId, setStateId] = useState<any>(null);
    const [country, setCountry] = useState<any>({
        value: "236b617cc-105e-4f73-a91c-e2162beac864",
        label: "United States of America"
    });

    const allStatesApiResult = useGetAllStatesByCountryIdQuery(country?.value ?? skipToken);
    const allCountriesApiResult = useGetAllCountriesQuery("");
    const [createBuyerApi, createBuyerApiResponse] = useCreateBuyerMutation();
    const [entityType, setEntityType] = useState("");
    const [soleProprietorship, setSoleProprietorship] = useState<boolean>(false);
    const [alienIdentification, setAlienIdentification] = useState("");
    const [idIssuer, setIdIssuer] = useState("");
    const [taxId, setTaxId] = useState("");

    // State for different sections of the form
    const [personalInfo, setPersonalInfo] = useState({
        legalFirstName: "",
        legalMiddleInitial: "",
        legalLastName: ""
    });

    const [businessInfo, setBusinessInfo] = useState({
        organizationName: "",
        employerId: "",
        doingBusinessAsName: ""
    });

    const [addressInfo, setAddressInfo] = useState({
        streetAddress: "",
        city: "",
        zip: ""
    });

    // Handle change for inputs
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, section: string) => {
        const {name, value} = e.target;
        if (section === "personal") {
            let updatedValue = value;
            if (name === "legalMiddleInitial") {
                // Allow only a single alphabet (A-Z or a-z)
                updatedValue = value.slice(0, 1).replace(/[^A-Za-z]/g, "");
            }
            setPersonalInfo({...personalInfo, [name]: updatedValue});
        } else if (section === "business") {
            setBusinessInfo({...businessInfo, [name]: value});
        } else if (section === "address") {
            setAddressInfo({...addressInfo, [name]: value});
        }
    };

    const isNextButtonEnabled = (obj: {}) => {
        let list = Object.keys(obj) as Array<keyof typeof obj>;
        // @ts-ignore
        return list?.every(cur => !isNullOrEmpty(obj?.[cur]))
    }

    // Button navigation for form steps
    const handleButton = (backAction: any, continueAction: any, buttonLabel: string, disable: boolean) => {
        return (
            <>
                <ButtonTray
                    buttons={[
                        {
                            buttonProps: {
                                btnText: "Back",
                                onClick: backAction,
                                type: "close",
                            },
                            buttonType: "button"
                        },
                        {
                            buttonProps: {
                                btnText: buttonLabel,
                                type: "submit",
                                isLoading: createBuyerApiResponse.isLoading,
                                isVisible: true,
                                onClick: continueAction,
                                isDisabled: disable
                            },
                            buttonType: "button",
                        }
                    ]}
                    align="end"
                    gap={2}
                    addTopLine={false}
                />
            </>
        );
    };

    // Field labels for different sections as lists of objects
    const personalFieldLabels = [
        {name: "legalFirstName", label: "First Name", required: true},
        {name: "legalMiddleInitial", label: "Middle Initial", required: false},
        {name: "legalLastName", label: "Last Name", required: true}
    ];

    const businessFieldLabels = [
        {name: "organizationName", label: "Organization’s Name", required: true},
        {name: "employerId", label: "Employer ID", required: true},
        {name: "doingBusinessAsName", label: "Doing Business As (DBA)", required: false}
    ];

    const addressFieldLabels = [
        {name: "streetAddress", label: "Street Address", required: true},
        {name: "city", label: "City", required: true},
        {name: "zip", label: "ZIP", required: true}
    ];


    // Function to handle form submission
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let list = tabsWithUnsavedChanges.filter((el: any) => el !== activeTab);
        setTabsWithUnsavedChanges(list);
        let obj = {
            requestBody: {
                partyId: partyId,
                stateId: stateId?.value,
                soleProprietorship: soleProprietorship,
                taxId: taxId,
                entityType: entityType.toUpperCase(),
                country: country?.value,
                ...personalInfo,
                ...businessInfo,
                ...addressInfo,
            }
        };
        await handleRTKQuery(
            async () => {
                return await createBuyerApi(obj).unwrap();
            },
            (isSuccess: boolean) => {
                if (isSuccess) {
                    setOrgDetailData(obj?.requestBody);
                    setActiveTab(`verify-contact`);
                }
            }
        );
    };

    return (

        <form onSubmit={(e) => e.preventDefault()}>

            {currentTab === "1" &&
                <div className={"ml-auto w-3/4"}>
                    <h2 className="text-2xl font-semibold">Is your entity an individual or an organization?</h2>

                    <div className={"mt-3 text-md"}>
                        <label>
                            <input
                                type="radio"
                                name="entityType"
                                value="individual"
                                className="mr-2"
                                checked={entityType === "individual"}
                                onChange={(e: any) => {
                                    setEntityType(e.target.value);
                                }}
                            />
                            Individual
                        </label>
                    </div>
                    <div className={"mt-3 text-md"}>
                        <label>
                            <input
                                type="radio"
                                name="entityType"
                                value="organization"
                                className="mr-2"
                                checked={entityType === "organization"}
                                onChange={(e: any) => {
                                    setEntityType(e.target.value);
                                }}
                            />Organization
                        </label>
                    </div>

                    {handleButton(() => setActiveTab("questions"), () => setCurrentTab(entityType === "organization" ? "2" : "3"), "Next", entityType === "")}

                </div>
            }

            {currentTab === "2" && entityType === "organization" &&
                <div className={"ml-auto w-3/4"}>
                    <h2 className="text-2xl font-semibold">Is your entity a sole proprietorship?</h2>

                    <div className={"mt-3 text-md"}>
                        <label>
                            <input
                                type="radio"
                                name="soleProprietorship"
                                value="true"
                                className="mr-2"
                                checked={soleProprietorship}
                                onChange={(e: any) => {
                                    setSoleProprietorship(!soleProprietorship);
                                }}
                            />
                            Yes
                        </label>
                    </div>
                    <div className={"mt-3 text-md"}>
                        <label>
                            <input
                                type="radio"
                                name="soleProprietorship"
                                value="false"
                                className="mr-2"
                                checked={!soleProprietorship}
                                onChange={(e: any) => {
                                    setSoleProprietorship(!soleProprietorship);
                                }}
                            />No
                        </label>
                    </div>

                    {handleButton(() => setCurrentTab("1"), () => setCurrentTab("4"), "Next", false)}

                </div>
            }

            {/* Personal Info Section */}

            {currentTab === "3" && entityType === "individual" &&
                <>
                    <p className="text-2xl text-center text-text-1 mb-4">Individual Information</p>

                    <div className={"min-h-[315px]"}>
                        {personalFieldLabels.map(({name, label, required}, index) => (
                            <InputField
                                key={index}
                                label={label}
                                value={personalInfo[name as keyof typeof personalInfo] || ""}
                                type="text"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, "personal")}
                                name={name}
                                labelPosition="left"
                                required={required}
                            />
                        ))}

                        <InputField label="Tax ID" labelPosition="left" name={"taxId"}
                                    onChange={(e: any) => {
                                        setTaxId(e.target.value);
                                    }} value={taxId || ""} required={true}/>

                        <InputField label="Alien Identification" labelPosition="left" name={"alienIdentification"}
                                    onChange={(e: any) => {
                                        setAlienIdentification(e.target.value);
                                    }} value={alienIdentification || ""}
                                    required={idIssuer !== "" && idIssuer !== null}/>


                        <InputField label="ID Issuer" labelPosition="left" name={"idIssuer"} onChange={(e: any) => {
                            setIdIssuer(e.target.value);
                        }} value={idIssuer || ""}
                                    required={alienIdentification !== null && alienIdentification !== ""}/>
                    </div>

                    {handleButton(() => setCurrentTab("1"), (e: any) => {
                        e.preventDefault();
                        if (validateTaxIdOrEmpId(taxId, "Tax ID")) {
                            setCurrentTab("5");
                        }
                    }, "Continue", !isNextButtonEnabled(alienIdentification !== null && alienIdentification !== "" ? {
                        ...personalInfo,
                        idIssuer: idIssuer,
                        taxId: taxId
                    } : {...personalInfo, taxId: taxId}))}
                </>
            }

            {/* Business Info Section */}
            {currentTab === "4" && entityType === "organization" &&
                <>
                    <p className="text-2xl text-center text-text-1 mb-4">Business Information</p>
                    <div className={"min-h-[315px]"}>
                        {businessFieldLabels.map(({name, label, required}, index) => (
                            <InputField
                                key={index}
                                label={label}
                                value={businessInfo[name as keyof typeof businessInfo] || ""}
                                type="text"
                                onChange={(e : any) => handleInputChange(e, "business")}
                                name={name}
                                labelPosition="left"
                                required={required}
                            />
                        ))}
                        {soleProprietorship && <InputField label="Tax ID" labelPosition="left" name={"taxId"}
                                                           onChange={(e: any) => {
                                                               setTaxId(e.target.value);
                                                           }} value={taxId || ""} required={true}/>
                        }
                    </div>
                    {handleButton(() => setCurrentTab("2"), (e: any) => {
                        e.preventDefault();
                        if (validateTaxIdOrEmpId(businessInfo["employerId"], "Employer ID")) {
                            setCurrentTab("5");
                        }
                    }, "Continue", !isNextButtonEnabled({
                        organizationName: businessInfo.organizationName,
                        employerId: businessInfo.employerId
                    }))}
                </>
            }
            {/* Address Info Section */}
            {currentTab === "5" &&
                <>
                    <p className="text-2xl text-center text-text-1 mb-4">Organization Address Information</p>
                    <div className={"min-h-[315px]"}>
                        <SelectField
                            label={"Country"}
                            value={country}
                            placeholder="Select Country..."
                            options={createOptionListForSelectTag(allCountriesApiResult?.data, "name", "id")}
                            onChange={(selectedOption) => {
                                setCountry(selectedOption)
                            }}
                            required={true}
                        />

                        <SelectField
                            label={"State"}
                            value={stateId}
                            placeholder="Select State..."
                            options={createOptionListForSelectTag(allStatesApiResult?.data, "name", "id")}
                            onChange={(selectedOption) => {
                                setStateId(selectedOption)
                            }}
                            required={true}
                        />

                        {addressFieldLabels.map(({name, label, required}, index) => (
                            <InputField
                                key={index}
                                label={label}
                                value={addressInfo[name as keyof typeof addressInfo] || ""}
                                type="text"
                                onChange={(e : any) => handleInputChange(e, "address")}
                                name={name}
                                labelPosition="left"
                                required={required}
                            />
                        ))}
                    </div>
                    {handleButton(() => setCurrentTab(entityType === "organization" ? "4" : "3"), (e: any) => {
                            if (validateZipCode(addressInfo["zip"])) {
                                handleSubmit(e).then();
                            }
                        }, "Submit", !isNextButtonEnabled({
                            ...addressInfo,
                            stateId: stateId?.value || null
                        })
                    )}
                </>
            }
        </form>
    );
};

export default BuyerForm;
