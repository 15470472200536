import React, {FC, useEffect, useState} from "react";
import {useFindUserSettingsByGroupNameQuery} from "../../components/common/userSetting/userSettingsApi";
import {useGetEnumsQuery} from "../../app/globalApi";
import {useLazyGetAllTenantsQuery} from "../user/customer/tenantApi";
import {jwtDecode} from "../../helpers/utils/AccessControlUtils";
import {DataTableColumn} from "../../components/common/datatable/DataTableTypes";
import {createClickableLink} from "../../components/common/datatable/DataTableUtils";
import {SearchInputWrapper} from "../../components/common/datatable/filter/SearchInputWrapper";
import {formatValueAsDate} from "../../helpers/utils/DateUtils";
import {getEnumDisplayNameByConstant} from "../../helpers/utils/EnumUtils";
import {SearchSelectWithEnum} from "../../components/common/datatable/filter/SearchSelectWrapper";
import {SearchDatePickerWrapper} from "../../components/common/datatable/filter/SearchDatePickerWrapper";
import Breadcrumb from "../../components/common/Breadcrumbs";
import {DataTable} from "../../components/common/datatable/DataTable";
import {useSearchBatchesQuery} from "./fincenBatchApi";

const FincenBatches: FC = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "FinCen Batches", url: ""},
    ])

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("FincenBatches");
    const {data: enums} = useGetEnumsQuery("");
    // const [triggerExportProductDataQuery] = useLazyExportProductDataQuery();
    // const [tenantApi, tenantList] = useLazyGetAllTenantsQuery();

    useEffect(() => {
        document.title = "FinCen Batches";
    });

    // useEffect(() => {
    //     if (jwtDecode()?.customerLevel === "SYS") {
    //         tenantApi("");
    //     }
    // }, [tenantApi]);

    const columns: DataTableColumn[] = [
        {
            label: "Batch Name",
            accessor: "batchName",
            cell: (props: any) => {
                let path = `/fincen-batches/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["FINBAT-R", "FINBAT-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper
                    accessor={'batchName'}
                    placeholder={'Enter Batch Name...'}
                    name={'batchName'}/>
            }]
        },
        {
            label: "Tracking ID",
            accessor: "trackingId",
            cell: (props: any) => props.value || 'N/A',
            filterOptions: [{
                filterComponent: <SearchInputWrapper
                    accessor={'trackingId'}
                    placeholder={'Enter Tracking ID...'}
                    name={'trackingId'}/>
            }]
        },
        {
            label: "Status",
            accessor: "status",
            cell: (props: any) => getEnumDisplayNameByConstant(enums?.["FincenBatchStatus"], props.value),
            filterOptions: [{
                filterComponent: SearchSelectWithEnum(enums?.["FincenBatchStatus"],
                    "Select Batch Statuses...",
                    true,
                    "statuses",
                    null),
                overrideFilterAccessor: "statuses",
            }]
        },
        {
            label: "Activity Count",
            accessor: "size",
        },
        {
            label: "Batch Date", accessor: "creationDate", cell: (props: any) => {
                return formatValueAsDate(props.row.original.creationDate);
            }, filterOptions: [{
                filterComponent: <SearchDatePickerWrapper accessor={"createdDateFrom"}/>,
                overrideFilterAccessor: "createdDateFrom",
                overrideFilterLabel: "Created Date - From",
            }, {
                filterComponent: <SearchDatePickerWrapper accessor={"createdDateTo"}/>,
                overrideFilterAccessor: "createdDateTo",
                overrideFilterLabel: "Created Date - To",
            }]
        },
        // {
        //     label: "Tenant", accessor: "tenantName",
        //     visibility: {disallowedCustomerLevels: ["CUSTOMER", "TENANT"]},
        //     canSort: false,
        //     filterOptions: [{
        //         filterComponent: tenantList?.data ?
        //             SearchSelectWithList(tenantList?.data, (tenant: any) => `${tenant.name} `, "id", "Select Tenant...", false, "tenantId") :
        //             [],
        //         overrideFilterAccessor: "tenantId"
        //     }],
        // },
    ];

    return (
        <div className="px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
                <div>
                </div>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchBatchesQuery,
                    // exportAction: triggerExportProductDataQuery
                }}
                defaultFilter={{
                    sort: "creationDate",
                    sortOrder: "DESCENDING",
                    statuses: ['UPLOADED']
                }}
                userSettingGroup='Batch'
                defaultSortOptions={{
                    accessor: 'creationDate',
                    direction: 'desc'
                }}
                basePermission={"FINBAT"}
            />
        </div>

    )
}
export default FincenBatches